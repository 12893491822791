import {writable} from "svelte/store";
import {apiUrl} from "./environment";

export const projects = writable({});
export const mainProjects = writable({})
export const subProjects = writable({})
export const visibleProjects = writable({})
export const storeSingleProject = writable();

let baseUrl = apiUrl.subscribe((url) => {
        baseUrl = url
})

projects.subscribe(($projects) => {
        const filteredProjects = Object.values($projects).filter(project => project.hasSubProjects === true);
        mainProjects.set(filteredProjects)
})

projects.subscribe(($projects) => {
        const filteredProjects = Object.values($projects).filter(project => project.hasSubProjects === false);
        subProjects.set(filteredProjects)
})

projects.subscribe(($projects) => {
        const filteredProjects = Object.values($projects).filter(project => project.visible === true)
        visibleProjects.set(filteredProjects)
})

export async function fetchProjects() {
        try {
                const response = await fetch(`${baseUrl}/api/projects`);
                const data = await response.json();
                projects.set(data['hydra:member']);
        } catch(error) {
                throw new Error(error);
        }
}

export async function fetchSingleProject(projectId) {
        try {
                const response = await fetch(`${baseUrl}/api/projects/${projectId}`);
                const project = await response.json();
                storeSingleProject.set(project)
        } catch (error) {
                throw error;
        }
}
