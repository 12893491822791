<script>
    import {createEventDispatcher} from "svelte";

    export let visibility = 'hidden';
    let mouseX = 0;
    let mouseY = 0;
    let scrollY = 0;
    const dispatch = createEventDispatcher()
    export let selector


    function handleContextMenu(event) {
        const isOnSpecialElement = event.target.classList.value.includes(selector);
        if (!isOnSpecialElement) {
            return
        }

        const selectedElement = event.target.closest(`[id="${selector}"]`);

        const contextMenu = document.querySelector('.options-menu')
        visibility = 'visible'

        mouseX = event.clientX;
        mouseY = event.clientY;
        scrollY = window.scrollY;

        const menuWidth = contextMenu.offsetWidth;
        const menuHeight = contextMenu.offsetHeight;

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        if (mouseX + menuWidth > viewportWidth) {
            mouseX = viewportWidth - menuWidth - 70;
        }

        if (mouseY + menuHeight > viewportHeight) {
            mouseY = viewportHeight - menuHeight;
        }


        window.addEventListener('click', handleClickOutside)
        dispatch('open', {selectedElement})

    }

    function handleClickOutside(event) {
        const clickedElement = event.target.classList
        if (!clickedElement.contains('options-menu')) closeContextMenu()
    }

    function closeContextMenu() {
        visibility = 'hidden';

        window.removeEventListener('click', handleClickOutside)
    }
</script>

<style>
    .options-menu {
        width: 13rem;
        position: absolute;
        background-color: var(--primary-color-2);
        padding: 0.7rem 0.7rem 0 0.7rem;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
</style>


<svelte:window on:contextmenu|preventDefault={handleContextMenu}/>
<div class="options-menu rounded-border-1" style="left:{mouseX}px; top:{mouseY + scrollY}px; visibility:{visibility}">
    <slot/>
</div>