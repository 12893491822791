<script>
    import {onMount} from "svelte";
    import {selectedDate} from "../stores/selectedDate";
    import {
        fetchOpenWorklogs,
        openWorklogs,
        weeklyWorklogs,
        weeklySubtasks,
        getTasks
    } from "../stores/worklogs";
    import {formatWeekdayDate, needsUpdate} from "../util/date-time";
    import Calendar from "./Calendar.svelte";
    import {costCenters} from "../stores/costCenters.js";
    import {getWorkingTime} from "../util/date-time";
    import {roles, fetchUserRoles} from "../stores/roles.js";
    import {
        getWeekdays,
        highlightReviewIcon,
        resetClassName,
        getWeeklyWorklogs,
        getMyReviews
    } from "../util/highlightReviewStatus.js";
    import {reviews, fetchReviews, showReviewsCurrentUser} from "../stores/reviews.js";
    import {user} from "../stores/user.js";

    export let page;
    export let selectedUser
    const options = {weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'};
    const optionsWithoutDay = { year: 'numeric', month: '2-digit', day: '2-digit' }
    let weekdays = [];
    let current = new Date($selectedDate);
    let currentDate = $selectedDate;
    const weekdayComponent = true
    let firstDate;
    let workingTime;
    let currentDayReviews = 0
    let loading = true


    needsUpdate.subscribe(async val => {
        if (val) {
            await getDays(current)
            needsUpdate.set(false)
        }
    })

    async function getDays(currentDate) {
        loading = true
        weekdays = await getWeekdays(currentDate, options, $roles)

        if (page !== 'archive') {
            await getWeeklyWorklogs(weekdays, $user)
        }

        if (page === 'worklogs') {
            await highlightReviewIcon($weeklyWorklogs, weekdays);
            await getTasks($weeklyWorklogs, weekdayComponent)
        }
        loading = false
    }

    function getUnsubmittedWorklogs(weekday, worklogs) {
        let unsubmittedWorklogs = worklogs.filter(worklog => worklog.reviewStatus === "private")

        let parts
        if (!weekday.date) {
            parts = weekday.split('.')
        } else {
            parts = weekday.date.split('.')
        }
        const startDate = parts[2] + '-' + parts[1] + '-' + parts[0]

        return unsubmittedWorklogs.filter(worklog => worklog.startTime.includes(startDate)).length;
    }

    function resetCurrentWeek() {
        current = new Date();

        let formattedDate = current.toLocaleDateString('de-DE', optionsWithoutDay);

        redirect(formattedDate, page, selectedUser);
    }

    function shiftWeeks(shift) {
        let shiftedWeeks = current.getDate() - current.getDay() + shift;
        let newDate = new Date(current.setDate(shiftedWeeks));

        resetClassName();

        getDays(newDate);
    }

    function redirect(selectedDate, page, selectedUser) {
        const formattedDate = formatWeekdayDate(selectedDate);

        const domain = window.location.origin;
        let baseUrl = `${domain}/admin?routeName=${page}_date&routeParams%5Bdate%5D=${formattedDate}`

        if (page === 'reviews') {
            baseUrl = baseUrl.concat(`&routeParams%5BshowMyReviews%5D=${$showReviewsCurrentUser}`)
        }

        if (selectedUser) {
            baseUrl = baseUrl.concat(`&routeParams%5BuserId%5D=${selectedUser.id}`)
        }
        window.location.href = baseUrl;
    }

    function isSelected(weekDate) {
        const formattedDate = formatWeekdayDate(weekDate);

        const selected = currentDate.toLocaleDateString('de-DE', optionsWithoutDay);
        const selectedDate = formatWeekdayDate(selected);

        return formattedDate === selectedDate;
    }

    document.addEventListener('keydown', shiftDaysShortcut)

    function shiftDaysShortcut(event) {
        const openModal = document.getElementsByTagName('dialog')

        if (openModal.length) return

        const day = currentDate.getDay()

        if (event.altKey && event.key === 'ArrowLeft') {
            if (day === 1) {
                currentDate.setDate(currentDate.getDate() - 3)
            } else {
                currentDate.setDate(currentDate.getDate() - 1)
            }

            const formattedDate = currentDate.toLocaleDateString('de-DE', optionsWithoutDay)
            redirect(formattedDate, page, selectedUser)
        } else if (event.altKey && event.key === 'ArrowRight') {
            if (day === 5) {
                currentDate.setDate(currentDate.getDate() + 3)
            } else {
                currentDate.setDate(currentDate.getDate() + 1)
            }

            const formattedDate = currentDate.toLocaleDateString('de-DE', optionsWithoutDay)
            redirect(formattedDate, page, selectedUser)
        }
    }

    onMount(async () => {
        await getDays(current)
        if (page === 'archive') return
        await fetchReviews()
        await fetchUserRoles()
        await fetchOpenWorklogs();
    })
</script>

<style>
    .weekdaysWorklogPageStyle {
        display: flex;
        position: relative;
        left: 0.6vw;
        bottom: 80px;
        width: 100%;
        margin-bottom: 10rem;
    }

    .weekdaysReviewPageStyle {
        display: flex;
        position: relative;
        left: 0.6vw;
        bottom: 3rem;
        width: 100%;
        margin-bottom: 10rem;
    }

    .weekdays-left-button, .weekdays-right-button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color-5);
    }

    .day {
        background-color: var(--primary-color-1);
        height: 150px;
        width: 12vw;
        margin-right: 1.5vw;
        border-radius: 2rem;
        border: 0.05rem solid var(--primary-color-3);
        padding-inline: 1.5rem;
        position: relative;
        left: 1.2vw;
    }

    .day.selected {
        background-color: var(--primary-color-2);
    }

    .day:hover {
        background-color: var(--primary-color-2);
    }

    .weekdays-today-button {
        background-color: var(--primary-color-2);
        width: 7vw;
        height: 70px;
        border-radius: 1rem 0 0 1rem;
        border: 0;
        color: var(--primary-color-5);
        margin: 0 0 0 auto;
    }

    .weekdays-today-button:hover {
        background-color: var(--accent-colors-blue);
    }

    .weekdays-today-icon {
        height: 1.3rem;
        width: 1.3rem;
        margin: 0 0.2rem 0.2rem 0;
        fill: var(--signal-green);
    }

    .weekdays-left-button, .weekdays-right-button {
        position: relative;
        background-color: var(--primary-color-2);
        height: 2rem;
        width: 2rem;
        margin-right: 1vw;
        border-radius: 0.2rem;
        border: 0;
        align-self: center;
        left: 1vw;
    }

    .weekday-day {
        text-align: left;
        color: var(--primary-color-5);
        margin-bottom: 0;
        margin-top: -1rem;
    }

    .weekday-date {
        text-align: left;
        color: var(--primary-color-4);
    }

    .weekday-worktime {
        text-align: left;
        margin-bottom: -1rem;
        color: var(--primary-color-5);
    }

    .weekday-open-reviews {
        color: var(--signal-red);
        text-align: left;
        margin-bottom: -1rem;
    }

    .weekday-open-reviews.no-reviews {
        color: var(--signal-green);
    }

    .weekday-review-icon {
        height: 0.8rem;
        width: 0.8rem;
        margin: 0.1rem 0.3rem 0.3rem 0.3rem;
        fill: var(--primary-color-4);
    }

    .weekday-review-icon.open {
        fill: var(--signal-yellow);
    }

    .weekday-review-icon.success {
        fill: var(--signal-green);
    }

    .weekday-review-icon.failure {
        fill: var(--signal-red);
    }

    .today-calendar-button {
        display: flex;
        flex-direction: column;
        width: 11%;
    }

    #loader {
        position: absolute;
        z-index: 1;
        width: 1.5rem;
        height: 1.5rem;
        border: 0.3rem solid var(--primary-color-4);
        border-radius: 50%;
        border-top: 0.3rem solid var(--accent-colors-orange);
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>

<div class:weekdaysWorklogPageStyle={page === 'worklogs'} class:weekdaysReviewPageStyle={page !== 'worklogs'}>
    <button class="weekdays-left-button button-effect-1" on:click={() => shiftWeeks(-7)}>
        <i class="fa-solid fa-chevron-left fa-lg"></i>
    </button>
    {#each weekdays as weekday}
        <button class={`day ${isSelected(weekday.date)? "selected" : ''} button-effect-1`}
                on:click={() => redirect(weekday.date, page, selectedUser)}>
            <p class="weekday-day font-bold font-size-large">{weekday.day}
                {#if page === 'worklogs'}
                    <svg class="weekday-review-icon">
                        <use xlink:href="/images/icons/review_status.svg#Ebene_1"></use>
                    </svg>
                {/if}
            </p>
            <p class="weekday-date font-regular font-size-medium">{weekday.date}</p>
            {#if page === 'worklogs'}
                {#if loading}
                    <div id="loader"></div>
                {:else}
                    <p class="weekday-worktime font-medium font-size-large">{getWorkingTime(weekday.date, $weeklyWorklogs, $weeklySubtasks, $costCenters)}</p>
                {/if}
            {:else if page === 'archive'}
                <div></div>
            {:else if page === 'reviews'}
                {#if getMyReviews(weekday, $reviews, $user, $openWorklogs) > 0}
                    <p class="weekday-open-reviews font-regular font-size-default">Ausstehende
                        Reviews: {getMyReviews(weekday, $reviews, $user, $openWorklogs)}</p>
                {:else}
                    <p class="weekday-open-reviews no-reviews font-regular font-size-default">Keine ausstehenden Reviews</p>
                {/if}
            {/if}
        </button>
    {/each}
    <button class="weekdays-right-button button-effect-1 " on:click={() => shiftWeeks(7)}>
        <i class="fa-solid fa-chevron-right fa-lg"></i>
    </button>

    <div class="today-calendar-button font-size-default">
        <button class="weekdays-today-button button-effect-2 font-semi-bold" on:click={resetCurrentWeek}>
            <svg class="weekdays-today-icon">
                <use xlink:href="/images/icons/today.svg#Ebene_1"></use>
            </svg>
            Heute
        </button>

        <Calendar {selectedUser}/>
    </div>
</div>
