<script>
    import Modal from "../util/Modal.svelte";
    import {visibleProjects, projects} from "../stores/projects"
    import {costCenters} from "../stores/costCenters";
    import {userId, getWorklogById, worklogs as worklogStore, getTasks} from "../stores/worklogs.js";
    import {createSubtask, updateSubtask} from "../stores/subTasks.js";
    import {fetchReviews, reviews} from "../stores/reviews.js";
    import TicketNrInput from "../util/TicketNrInput.svelte";
    import CoWorkerInput from "../util/CoWorkerInput.svelte";
    import {needsUpdate} from "../util/date-time";
    import TimeInput from "../util/TimeInput.svelte";
    import {user} from "../stores/user.js";
    import DropdownMenu from "../util/DropdownMenu.svelte";
    import {checkForInternalProject, disableInputFields} from "../util/inputfieldDisabler";
    import {updateHighlightStatus} from "../util/highlightReviewStatus.js";
    import {selectedDate} from "../stores/selectedDate.js";
    import {divisions, fetchDivisions} from "../stores/divisions";
    import {trapFocus} from "../util/Shortcut";

    export let reviewPage
    export let worklogId;
    export let selectedUser
    export let worklog
    export let showModal = false;
    export let formattedTime
    export let worklogs;
    let current = new Date($selectedDate)
    let coWorkers = [];
    let ticketNumbers = [];
    let errorMessage;
    let selectedProject;
    let selectedCostCenter;
    let disableFields;
    let submitDisabled = false;
    let hasAcceptedTasks = false;
    let showConfirmationModal = false;
    let filteredCostCenters = [];
    const options = {weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'}

    $: {
        async function prepare() {
            await fetchDivisions();
            checkDisabledFields();
            filterCostCenters();
        }

        if (showModal) {
            prepare()
            worklog = getWorklogById(worklogId)
            document.addEventListener('keydown', trapFocus);
            getSelection();
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()
        submitDisabled = true;

        try {
            const filledSubtask = {
                parent: worklogId,
                ...subTask
            }

            if (!subTask.id) {
                await createSubtask(filledSubtask, getWorklogById(worklogId), $reviews, formattedTime)
                await getTasks($worklogStore)
            } else {
                await updateSubtask(subTask, subTask.id, formattedTime)
                await getTasks(worklogs)
            }
            if (!reviewPage) {
                await updateHighlightStatus(current, $worklogStore, options)
            }
            handleModalClose()
        } catch (error) {
            submitDisabled = false;
            errorMessage = error;
        }
    }

    export let subTask = {
        title: '',
        description: null,
        project: null,
        costCenter: null,
        coWorkers: [],
        tickets: [],
        issueTitles: [],
        user: `/api/users/${$user.id}`,
        durationMills: '',
        exported: false,
        missingTicketNr: false,
        billable: $user.billable
    }

    function resetModal() {
        subTask = {
            title: '',
            description: null,
            project: null,
            costCenter: null,
            coWorkers: [],
            tickets: [],
            issueTitle: [],
            user: `/api/users/${$user.id}`,
            durationMills: '',
            exported: false,
            missingTicketNr: false,
            billable: $user.billable
        }
        coWorkers = [];
        errorMessage = null;
        selectedProject = null;
        selectedCostCenter = null;
        disableFields = false;
    }

    function handleModalClose() {
        submitDisabled = false;
        showModal = false
        needsUpdate.set(true)
        document.removeEventListener('keydown', trapFocus);
        if (!subTask.id) resetModal();
    }

    function handleTaskCreateKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            checkReviewStatus(event);
        } else {
            event.preventDefault();
        }
    }

    function checkReviewStatus(event) {
        $worklogStore.forEach(worklog => {
            if (worklog.reviewStatus === 'success') hasAcceptedTasks = true
        })

        if (hasAcceptedTasks && !subtask.id) {
            showConfirmationModal = true
        } else {
            handleSubmit(event)
        }
    }

    function getSelection() {
        selectedProject = $visibleProjects.find(project => project['@id'] === subTask.project);
        selectedCostCenter = $costCenters.find(costCenter => costCenter['@id'] === subTask.costCenter);
    }

    function handleCostCenterChange() {
        subTask.costCenter = selectedCostCenter['@id']
        const internalProject = checkForInternalProject($projects, selectedCostCenter);

        if (internalProject) {
            selectedProject = internalProject
        }

        disableFields = disableInputFields(subTask, internalProject, selectedCostCenter)
    }

    function handleCostCenterReset() {
        if (disableFields) subTask.missingTicketNr = false
        subTask.costCenter = null
        subTask.title = null
        disableFields = false
    }

    function checkDisabledFields() {
        if (!subTask.id) return
        const internalProject = checkForInternalProject($projects, selectedCostCenter)
        if (internalProject) disableFields = true
    }

    function filterCostCenters() {
        const divisionIds = $divisions.map(division => division['@id']);
        filteredCostCenters = $costCenters.filter(costCenter => divisionIds.includes(costCenter.division));
    }

    function createBreak(event) {
        showModal = true
        const foundProject = $projects.find(project => project.name === 'Intern')
        const foundCostCenter = $costCenters.find(costCenter => costCenter.name === 'Pause')
        disableFields = true

        subTask = {
            ...subTask,
            title: 'Pause',
            project: foundProject['@id'],
            costCenter: foundCostCenter['@id'],
            durationMills: '30m',
            missingTicketNr: true,
        }
        handleSubmit(event)
    }

</script>

<style>
    .subtaskmodal-div {
        margin-left: 1.5rem;
        text-align: left;
    }

    .subtaskmodal-button {
        background-color: var(--accent-colors-blue);
        font-size: 0.75rem;
        color: #fff;
        width: 27rem;
        padding: 0.625rem;
        margin-bottom: 0.938rem;
        margin-top: 1.5rem;
        border: 1px solid var(--accent-colors-blue);
        border-radius: 3px;
    }

    .subtaskmodal-input {
        width: 27rem;
        background-color: var(--primary-color-2);
        border: 1px solid var(--primary-color-2);
        border-radius: 3px;
        height: 2rem;
        margin-top: 0.2rem;
    }

    .subtaskmodal-text {
        width: 27rem;
        background-color: var(--primary-color-2);
        border: 1px solid var(--primary-color-2);
        height: 6rem;
        border-radius: 3px;
        margin-top: 0.2rem;
    }

    .subtask-half, .subtask-half > div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .subtask-modal-label {
        margin-top: 0.8rem;
    }

    .modal-header, .subtask-modal-label, .subtaskmodal-text, .modal-error-message {
        color: var(--main-text-color)
    }
</style>

<slot name="subtask-button">
    <button id="add-subtask" hidden on:click={() => showModal = true}></button>
    <button id="add-break" hidden on:click={createBreak}></button>
</slot>

{#if showModal}
    <Modal class="subtaskmodal" bind:showModal on:close={handleModalClose}>
        <div class="subtaskmodal-div" id="modal-div">
            <slot name="subtask-modal-header">
                <h1 class="modal-header font-semi-bold">Subtask hinzufügen</h1>
            </slot>

            <div class="modal-error-message mb-2 rounded-border-1 font-regular font-size-default" data-error={errorMessage}>
                <svg class="modal-error-message-icon mr-1">
                    <use xlink:href="/images/icons/info.svg#Ebene_1"/>
                </svg>
                {errorMessage}
            </div>

            <slot name="subtask-duration-slot">
                <TimeInput bind:time={subTask.durationMills} inputMode="duration" selectedUser={selectedUser}>
                    <div slot="input-label">
                        <label class="subtask-modal-label font-regular font-size-small" for="time-input-field">Dauer</label>
                    </div>
                </TimeInput>
            </slot>

            <DropdownMenu bind:selectedItem={selectedProject}
                          bind:dropdownItems={$visibleProjects}
                          on:select={() => subTask.project = selectedProject['@id']}
                          on:delete={() => subTask.project = null}
                          mode="task" collection="Projekt" disabled={disableFields}/>

            <DropdownMenu bind:selectedItem={selectedCostCenter}
                          bind:dropdownItems={filteredCostCenters}
                          on:select={() => handleCostCenterChange()}
                          on:delete={handleCostCenterReset}
                          mode="task" collection="Kostenstelle">
                <div slot="dropdown-item-code"></div>
            </DropdownMenu>

            <div class="subtask-half">
                <div>
                    <TicketNrInput bind:ticketNumbers={subTask.tickets}
                                   bind:errorMessage={errorMessage}
                                   bind:missingTicketNr={subTask.missingTicketNr}
                                   disabled={disableFields}
                    />
                </div>
                <CoWorkerInput bind:coWorkers={subTask.coWorkers} disabled={disableFields}/>
            </div>
            <label class="subtask-modal-label font-regular font-size-small" for="title">Zusammenfassung</label>
            <input class="subtaskmodal-input font-regular font-size-default" type="text" id="title" bind:value={subTask.title} disabled={disableFields}/>

            <label class="subtask-modal-label font-regular font-size-small" for="description">Beschreibung</label>
            <textarea class="subtaskmodal-text font-regular font-size-default" id="description" bind:value={subTask.description}></textarea>

            <button class="subtaskmodal-button font-bold button-effect-2 font-size-medium" id="modal-button" on:click={handleSubmit} disabled={submitDisabled} on:keydown={handleTaskCreateKeyDown}>
                Subtask {subTask.id ? 'bearbeiten' : 'erstellen'}
            </button>
        </div>
    </Modal>
{/if}