<script>
    export let task
    export let projects
    let projectCode

    $: projectCode = projects.find(project => project['@id'] === task.project).code

    function formatDescription(description) {
        return description.replace(/(#.*?)(\n|$)/g, (match, comment) => {
            return `<i style="display: block; border-bottom: 1px dashed">${comment}</i>`;
        });
    }

    function redirectToJira(ticket) {
        const url = `https://ambimax.atlassian.net/browse/${projectCode}-${ticket}`
        window.open(url, '_blank')
    }
</script>

{#if task.description}
    <div class="task-description mt-2 mr-2 font-medium font-size-default">
        {@html formatDescription(task.description)}
    </div>
{/if}

{#if task.tickets.length}
    <div class="task-table-row-ticket mt-3">
        {#each task.tickets as ticket}
            <div class="ticket-number rounded-border-2 mr-1 font-medium font-size-default" on:mousedown={() => redirectToJira(ticket)}>
                {projectCode}-{ticket}
            </div>
        {/each}
    </div>
{/if}

<style>
    .task-description {
        color: var(--primary-color-5);
        white-space: pre-wrap;
        font-family: "Poppins Regular";
    }

    .task-table-row-ticket {
        display: flex;
    }

    .ticket-number {
        background-color: var(--primary-color-2);
        padding-inline: 0.5rem;
        text-align: center;
    }

    .ticket-number:hover {
        cursor: pointer;
        background-color: var(--primary-color-3);
    }
</style>