<script>
    import Modal from "../util/Modal.svelte";
    import {createEventDispatcher} from 'svelte'


    let showModal = false
    let errorMessage
    let startDate
    let endDate
    const dispatch = createEventDispatcher()

    function handleClick() {
        if (!startDate) {
            return errorMessage = "Bitte Startzeit auswählen"
        } else if (!endDate) {
            return errorMessage = "Bitte Endzeit auswählen"
        }
        dispatch("selected", {startDate, endDate})
        showModal = false
        errorMessage = null

    }


</script>

<style>
    .time-range-accept-button, .statistic-time-range-button {
        border: none;
        background: var(--primary-color-3);
        height: 4vh;
        width: 9vw;
    }

    .time-range-button-div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-error-message {
        color: var(--main-text-color);
    }
</style>

<button class="statistic-time-range-button button-effect-1 mt-3" on:click={() => showModal = true}>Zeitspanne</button>


{#if showModal}
    <Modal bind:showModal>
        {#if errorMessage}
            <div class="modal-error-message mb-2 rounded-border-1 font-regular font-size-default" data-error={errorMessage}>
                <svg class="modal-error-message-icon mr-1">
                    <use xlink:href="/images/icons/info.svg#Ebene_1"/>
                </svg>
                {errorMessage}
            </div>
        {/if}
        <div class="archive-modal-input font-size-default">
            <label class="archive-modal-label font-regular" for="start-date">von</label>
            <input type="date" id="start-date" placeholder="12-12-2023" bind:value={startDate}
                   class="archive-modal-date-picker rounded-border-1">
            <label class="archive-modal-label font-regular" for="start-date">bis</label>
            <input type="date" id="end-date" placeholder="12-12-2023" bind:value={endDate}
                   class="archive-modal-date-picker rounded-border-1">

        </div>
        <div class="time-range-button-div">
            <button on:click={handleClick} class="button-effect-1 time-range-accept-button mt-3">Bestätigen</button>
        </div>
    </Modal>
{/if}
