<script>
    import SubtaskActions from "./SubtaskActions.svelte";
    import {getContext} from "svelte";
    import {millisecondsToHours} from "../util/date-time";
    import TaskDescription from "../util/TaskDescription.svelte";
    import {user} from "../stores/user.js";

    export let highlightRow = true;
    export let subTask;
    export let worklog;
    export let selectedUser;
    export let worklogs;


    const {getProjects, getColors, getUsers, getCostCenters} = getContext('worklog-table');
    const users = getUsers();
    const colors = getColors();
    const costCenters = getCostCenters();
    const projects = getProjects();

</script>


{#if subTask.parent === worklog["@id"]}
    <tr class={`subtask-table-row worklog-status-${ highlightRow ? (worklog.reviewStatus ?? "private") : ''}`}
        id="table-row"
        worklogs="{subTask.parent}" task={subTask['@id']}>
        <td class="subtask-table-row-start-time align-cell-items">
        </td>
        <td class="subtask-table-row-end-time font-bold align-cell-items font-size-default">
            <div class="subtask-table-row-end-time-border center-content" id="durationDisplay">
                {millisecondsToHours(subTask.durationMills)}
            </div>
        </td>
        <td class="subtask-table-row-project-data font-bold align-cell-items font-size-small">
            <div class="subtask-table-row-project-costCenter-border center-content">
                {#each projects as project}
                    {#if subTask.project === project['@id']}
                        {#each colors as color}
                            {#if project.color === color['@id']}
                                <svg class="color-dot-worklog"
                                     style="fill: {color.code};">
                                    <use xlink:href="/images/icons/color_dot.svg#Ebene_1">
                                    </use>
                                </svg>
                            {/if}
                        {/each}
                        <div class="ml-1">
                            {project.code}
                        </div>
                    {/if}
                {/each}
            </div>
        </td>
        <td class="subtask-table-row-cost-center-data font-bold align-cell-items font-size-small">
            <div class="subtask-table-row-project-costCenter-border center-content">
                {#each costCenters as costCenter }
                    {#if subTask.costCenter === costCenter['@id']}
                        {#each colors as color}
                            {#if costCenter.color === color['@id']}
                                <svg class="color-dot-worklog"
                                     style="fill: {color.code}">
                                    <use xlink:href="/images/icons/color_dot.svg#Ebene_1">
                                    </use>
                                </svg>
                            {/if}
                        {/each}
                        <div class="ml-1">
                            {costCenter.name}
                        </div>
                    {/if}
                {/each}
            </div>
        </td>
        <td class="subtask-table-row-data-description pl-4 align-cell-items font-size-default">
            <div class="subtask-table-row-task-title font-bold">
                {subTask.title}
            </div>
            <TaskDescription task={subTask} projects={projects}/>
        </td>
        <td class="subtask-table-row-data-processor font-bold align-cell-items font-size-small">
            <div class="co-workers-div">
                <div class="subtask-table-row-processor-border center-content">
                    {#each users as user }
                        {#if worklog.user === user['@id']}
                            {user.code.toUpperCase()}
                        {/if}
                    {/each}
                </div>
                {#if subTask.coWorkers}
                    <div class="co-workers">
                    {#each subTask.coWorkers as coWorkerId }
                        <div class="subtask-table-row-processor-border center-content">
                            {#each users as coWorker }
                                {#if coWorkerId === coWorker['@id']}
                                    {coWorker.code.toUpperCase()}
                                {/if}
                            {/each}
                        </div>
                    {/each}
                    </div>
                {/if}
            </div>
        </td>
        <td class="subtask-table-row-slot align-cell-items font-size-default">
            <slot name="billable">
            </slot>
        </td>
        <td class="subtask-table-row-edit-delete align-cell-items">
            <div class="subtask-actions-div">
                <slot name="actions">
                    {#if selectedUser.id === $user.id}
                        <SubtaskActions {subTask} {worklog} selectedUser={selectedUser} {worklogs}/>
                    {/if}
                </slot>
            </div>
        </td>
    </tr>
{/if}

<style>
    td.subtask-table-row-start-time {
        width: 5rem;
        padding: 0.5rem;
    }

    .subtask-table-row-end-time, .subtask-table-row-project-data, .subtask-table-row-cost-center-data, .subtask-table-row-data-processor {
        width: 7rem;
    }

    .subtask-table-row-data-description {
        text-align: unset;
    }

    .subtask-table-row-project-costCenter-border, .subtask-table-row-processor-border, .subtask-table-row-end-time-border {
        width: fit-content;
        padding: 0.5rem;
        background-color: var(--primary-color-2);
        height: 1.5rem;
        text-wrap: nowrap;
    }

    .subtask-table-row-project-costCenter-border, .subtask-table-row-end-time-border {
        border-radius: 1.375rem;
    }

    .subtask-table-row-processor-border {
        border-radius: 1rem;
        width: 3rem;
        height: 1.5rem;
    }

    .co-workers-div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.1rem;
    }

    .co-workers {
        display: inline-flex;
        gap: 5px;
    }

    .subtask-table-row-task-title {
        font-size: 0.8rem;
        color: var(--primary-color-5);
    }

    .subtask-table-row-end-time {
        color: var(--accent-colors-blue);
        font-size: 0.7rem;
    }

    .subtask-table-row-project-data, .subtask-table-row-cost-center-data, .subtask-table-row-data-processor {
        width: 7rem;
        font-size: 0.7rem;
        color: var(--primary-color-5);
    }

    .color-dot-worklog {
        height: 0.625rem;
        width: 1rem;
    }

    .subtask-table-row td {
        background-color: var(--primary-color-1);
        color: var(--primary-color-5);
    }

    .worklog-status-private td {
        background-color: var(--primary-color-1);
    }

    .worklog-status-open td {
        background-color: var(--signal-light-yellow)
    }

    .worklog-status-success td {
        background-color: var(--signal-light-green);
    }

    .worklog-status-failure td {
        background-color: var(--signal-light-red);
    }

    .subtask-actions-div {
        display: inline-grid;
        grid-auto-flow: column;
        float: right;
        padding-right: 0.25rem;
    }

    .subtask-table-row td {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
</style>