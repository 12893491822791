<script>
    import Modal from '../util/Modal.svelte';
    import {showReviewsCurrentUser, reviews} from "../stores/reviews.js";
    import {
        getMonthName,
        monthNames,
        getMonthDays,
        dayNames,
        previousMonthDays,
        nextMonthDays,
    } from "../util/date-time.js";
    import {calendarWeek} from "../util/date-time.js";
    import CalendarDropdown from "../util/CalendarDropdown.svelte";
    import {createEventDispatcher} from "svelte";
    import {user} from "../stores/user";
    import {fetchOpenWorklogs, openWorklogs} from "../stores/worklogs";
    import {fetchUserRoles, roles} from "../stores/roles.js";


    let currentDate = new Date();
    let monthIndex = currentDate.getMonth();
    let month = getMonthName(monthIndex);
    export let showModal = false;
    export let mode = "default"

    let currentYear = currentDate.getFullYear();
    let years = Array.from({length: 7}, (_, index) => currentYear - index);
    let year = currentYear;
    let reversedYears = years.reverse();
    export let projectId

    let urlDate = getUrlDate();
    month = urlDate.month;
    monthIndex = urlDate.monthIndex;
    year = urlDate.year;

    let weekdaysOfMonth = getMonthWeekdays(monthIndex, year);

    export let selectedUser
    export let tabName
    const pageUrl = window.location.href;

    let createdAtArray
    let filteredWorklogs = []
    let reviewsPreviousMonth
    let reviewsNextMonth

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    function checkReviewsDays(dayCount, buttonClass) {
        if (!filteredWorklogs.length) return

        const prevMonth = monthIndex === 0 ? getMonthName(11) : getMonthName(monthIndex - 1)
        const nextMonth = monthIndex === 11 ? getMonthName(0) : getMonthName(monthIndex + 1)
        let proofDate

        if (pageUrl.indexOf("routeName=review") === -1) return

        switch (buttonClass) {
            case 'current-month-button':
                proofDate = `${dayCount}. ${month} ${year}`
                break
            case 'previous-month-button':
                proofDate = `${dayCount}. ${prevMonth} ${year}`
                break
            case 'next-month-button':
                proofDate = `${dayCount}. ${nextMonth} ${year}`
                break
        }

        if (createdAtArray.includes(proofDate)) {
            return true
        }
    }

    async function getReviews() {
        await fetchUserRoles()
        if (!$roles.some(userRole => userRole.code.includes("ROLE_REVIEWER"))) return

        await fetchOpenWorklogs()

        if ($showReviewsCurrentUser) {
            filteredWorklogs = $openWorklogs.filter(review => review.user === `/api/users/${$user.id}`)
        } else {
            filteredWorklogs = $openWorklogs
        }

        createdAtArray = [...new Set(filteredWorklogs.map(worklog => new Date(worklog.startTime).toLocaleDateString('de-DE', options)))]
    }

    $: {
        if (pageUrl.indexOf("routeName=review") !== -1) {
            getReviews()
            if (showModal) checkReviewsMonths()
        }
    }

    function checkReviewsMonths() {
        if (!filteredWorklogs.length) return

        reviewsNextMonth = false
        reviewsPreviousMonth = false

        const oldestReview = new Date(filteredWorklogs[0].startTime)
        const latestReview = new Date(filteredWorklogs.at(-1).startTime)

        const currentDate = new Date(`${year}-${monthIndex + 1}`)

        if (currentDate.getFullYear() < latestReview.getFullYear() || (currentDate.getFullYear() === latestReview.getFullYear() && currentDate.getMonth() < latestReview.getMonth())) {
            reviewsNextMonth = true
        }

        if (currentDate.getFullYear() > oldestReview.getFullYear() || (currentDate.getFullYear() === oldestReview.getFullYear() && currentDate.getMonth() > oldestReview.getMonth())) {
            reviewsPreviousMonth = true
        }
    }

    function updateMonthAndWeekdays() {
        month = getMonthName(monthIndex);
        weekdaysOfMonth = getMonthWeekdays(monthIndex, year);
    }

    function previousMonth() {
        if (year > currentYear - 6 || (year === currentYear - 6 && monthIndex > 0)) {
            monthIndex--;
            if (monthIndex === -1) {
                year--;
                monthIndex = 11;
            }
            updateMonthAndWeekdays();
            checkReviewsMonths(monthIndex)
        }
    }

    function nextMonth() {
        if (year < currentYear || (year === currentYear && monthIndex < 11)) {
            monthIndex++;
            if (monthIndex === 12) {
                year++;
                monthIndex = 0;
            }
            updateMonthAndWeekdays();
            checkReviewsMonths(monthIndex)
        }
    }

    function handleMonthChange(event) {
        monthIndex = monthNames.indexOf(month)
        updateMonthAndWeekdays();
    }

    function handleYearChange(event) {
        const selectedIndex = reversedYears.indexOf(year);
        year = reversedYears[selectedIndex];
        updateMonthAndWeekdays();
    }

    function getMonthWeekdays(monthIndex, Year) {
        let weekdaysOfMonth = {};
        let firstDayOfMonth = new Date(Year, monthIndex, 1);
        let firstDayWeekday = dayNames[firstDayOfMonth.getDay()];

        let previousMonthIndex = (monthIndex === 0) ? 11 : monthIndex - 1;
        let previousMonthDaysToAdd = previousMonthDays[firstDayWeekday];
        let daysInPreviousMonth = getMonthDays(previousMonthIndex, (monthIndex === 0) ? Year - 1 : Year);

        for (let addPreviousDays = 0; addPreviousDays < previousMonthDaysToAdd + 1; addPreviousDays++) {
            let previousDate = daysInPreviousMonth - (previousMonthDaysToAdd - addPreviousDays);
            let date = new Date(Year, monthIndex - 1, previousDate);
            let weekdayIndex = date.getDay();
            let weekday = dayNames[weekdayIndex];


            if (!weekdaysOfMonth[weekday]) {
                weekdaysOfMonth[weekday] = [];
            }

            weekdaysOfMonth[weekday].push({dayCount: previousDate, buttonClass: 'previous-month-button'});
        }


        let daysInMonth = getMonthDays(monthIndex, Year);
        for (let dayCount = 1; dayCount <= daysInMonth; dayCount++) {
            let date = new Date(Year, monthIndex, dayCount);
            let weekdayIndex = date.getDay();
            let weekday = dayNames[weekdayIndex];


            if (!weekdaysOfMonth[weekday]) {
                weekdaysOfMonth[weekday] = [];
            }

            weekdaysOfMonth[weekday].push({dayCount, buttonClass: 'current-month-button'});
        }


        let lastDayOfMonth = new Date(Year, monthIndex + 1, 0);
        let lastDayWeekday = dayNames[lastDayOfMonth.getDay()];
        let nextMonthDaysToAdd = nextMonthDays[lastDayWeekday] || 0;

        for (let addNextDays = 1; addNextDays <= nextMonthDaysToAdd; addNextDays++) {
            let date = new Date(Year, monthIndex + 1, addNextDays);
            let weekdayIndex = date.getDay();
            let weekday = dayNames[weekdayIndex];

            if (!weekdaysOfMonth[weekday]) {
                weekdaysOfMonth[weekday] = [];
            }

            weekdaysOfMonth[weekday].push({dayCount: addNextDays, buttonClass: 'next-month-button'});
        }
        return weekdaysOfMonth;
    }

    function isSelected(dayCount) {
        const newDate = new Date(year, monthIndex, dayCount);
        const formattedDate = `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, '0')}-${newDate.getDate().toString().padStart(2, '0')}`;
        const pageUrl = window.location.href;
        const urlParams = new URLSearchParams(pageUrl);
        const urlDate = urlParams.get("routeParams[date]");

        return formattedDate === urlDate;
    }

    function getUrlDate() {
        const pageUrl = window.location.href;
        const url = new URL(pageUrl);
        const dateParam = url.searchParams.get("routeParams[date]");
        const dateObject = new Date(dateParam);

        const year = dateObject.getFullYear();
        const monthIndex = dateObject.getMonth();
        const month = monthNames[dateObject.getMonth()];

        return {
            year: year,
            monthIndex: monthIndex,
            month: month
        };
    }

    function updateURL(dayCount, buttonClass) {
        let newDate

        switch (buttonClass) {
            case "previous-month-button":
                newDate = new Date(year, monthIndex - 1, dayCount)
                break

            case "next-month-button":
                newDate = new Date(year, monthIndex + 1, dayCount)
                break

            case "current-month-button":
                newDate = new Date(year, monthIndex, dayCount);
                break
        }
        const formattedDate = `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, '0')}-${newDate.getDate().toString().padStart(2, '0')}`;

        if (mode === "default") {
            const pageUrl = window.location.href;
            const domain = window.location.origin;
            let selectedUserId
            if (selectedUser) {
                selectedUserId = selectedUser.id
            }
            let newURL = "";

            if (pageUrl.indexOf("routeName=worklogs") !== -1) {
                newURL = `${domain}/admin?routeName=worklogs_date&routeParams%5Bdate%5D=${formattedDate}&routeParams%5BuserId%5D=${selectedUserId}`;
            } else if (pageUrl.indexOf("routeName=review") !== -1) {
                newURL = `${domain}/admin?routeName=reviews_date&routeParams%5Bdate%5D=${formattedDate}&routeParams%5BshowMyReviews%5D=${$showReviewsCurrentUser}`;
                if (selectedUserId) {
                    newURL += `&routeParams%5BuserId%5D=${selectedUserId}`;
                }
            } else if (pageUrl.indexOf("routeName=statistic") !== -1) {
                newURL = `${domain}/admin?routeName=statistic_date&routeParams%5Bdate%5D=${formattedDate}&routeParams%5Btab%5D=${tabName}`;
                if (tabName === "Projekte" && projectId) {
                    newURL += `&routeParams%5BprojectId%5D=${projectId}`;
                }
            }

            window.location.href = newURL;
        } else {
            selectedDate = new Date(formattedDate)
            dispatch('select')
        }
    }

    const dispatch = createEventDispatcher()
    export let selectedDate


    getMonthWeekdays(monthIndex, year);

</script>

<style>
    .switch-prev-month, .switch-next-month {
        color: var(--accent-colors-orange);
    }

    .current-month-button.open-reviews {
        border: 2px solid var(--accent-colors-orange);
    }

    .previous-month-button.open-reviews, .next-month-button.open-reviews {
        border: 2px solid rgba(255, 68, 0, 0.2);
    }

    .calendar-title {
        font-size: 22px;
        color: var(--primary-color-5);
    }

    .calendar-title-text {
        color: var(--primary-color-4);
    }

    .calendar {
        width: 100%;
        background: var(--primary-color-1);
    }

    .day-label {
        position: relative;
        text-align: center;
    }

    .row {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .col-md-1 {
        flex: 1;
        margin-bottom: 1rem;
    }

    .day-label {
        position: relative;
        text-align: center;
        margin: 5px 0 10px 12px;
    }

    .day-button {
        flex: 1;
        background: var(--primary-color-2);
        color: var(--primary-color-4);
        border: none;
        border-radius: 3px;
        width: 125%;
        height: 44px;
        margin-top: 10px;
    }

    .day-button.selected {
        background: var(--accent-colors-blue);
        color: #FFFFFF;
    }

    .day-button:hover {
        background: var(--accent-colors-blue);
        color: #FFFFFF;
    }

    .next-month-button {
        background: var(--primary-color-1);
        color: var(--primary-color-3);
    }

    .previous-month-button {
        background: var(--primary-color-1);
        color: var(--primary-color-3);
    }

    .open-calendar-button {
        background-color: var(--primary-color-2);
        width: 7vw;
        height: 70px;
        border-radius: 1rem 0 0 1rem;
        border: 0;
        color: var(--primary-color-5);
        margin: 10px 0 0 auto;
    }

    .open-calendar-button:hover {
        background: var(--accent-colors-blue);
    }

    .calendar-navigation-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .calendar-navigation-container-item {
        flex: 0 1 auto;
        color: var(--primary-color-5);
        font-size: 1rem;
        font-family: "Poppins Bold";
    }

    .calendar-navigation-container-item.open-reviews {
        color: var(--accent-colors-orange);
    }

</style>

<slot name="calendar-button">

    <button class="open-calendar-button button-effect-2 font-size-default font-semi-bold"
            on:click={() => showModal = true}> Kalender <br>{$calendarWeek}. KW
    </button>
</slot>


{#if showModal}
    <Modal bind:showModal>

        <h1 class="calendar-title font-extra-bold"> Kalender </h1>
        <p class="calendar-title-text font-regular font-size-default"> Wählen Sie einen Kalendertag. </p>

        <div class="calendar-navigation-container mb-4">

            <button class="calendar-navigation-container-item {reviewsPreviousMonth ? 'open-reviews' : ''} font-regular bg-primary-2 border-none rounded-border-1 button-effect-1"
                    on:click={previousMonth}>
                &lt;
            </button>

            <CalendarDropdown collection="months" dropdownItems={monthNames} bind:selectedItem={month} currentYear={year} {createdAtArray}
                              on:select={handleMonthChange}/>


            <CalendarDropdown collection="years" dropdownItems={years} bind:selectedItem={year} {createdAtArray}
                              on:select={handleYearChange}/>


            <button class="calendar-navigation-container-item {reviewsNextMonth ? 'open-reviews' : ''} font-regular bg-primary-2 border-none rounded-border-1 button-effect-1"
                    on:click={nextMonth}> &gt;
            </button>
        </div>

        <div class="calendar">
            <div class="row">

                <div class="col-md-1 font-size-large">
                    <p class="day-label font-regular">Mo.</p>
                    {#each weekdaysOfMonth["Mo"] as {dayCount, buttonClass}}
                        <button on:click={() => updateURL(dayCount, buttonClass)}
                                class={`day-button ${buttonClass === 'current-month-button' ? isSelected(dayCount)? 'selected' : '' : ''} ${checkReviewsDays(dayCount, buttonClass) ? 'open-reviews' : ''} ${buttonClass} button-effect-2 font-semi-bold`}>
                            {dayCount}
                        </button>
                    {/each}
                </div>


                <div class="col-md-1 font-size-large">
                    <p class="day-label font-regular">Di.</p>
                    {#each weekdaysOfMonth["Di"] as {dayCount, buttonClass}}
                        <button on:click={() => updateURL(dayCount, buttonClass)}
                                class={`day-button ${buttonClass === 'current-month-button' ? isSelected(dayCount)? 'selected' : '' : ''} ${checkReviewsDays(dayCount, buttonClass) ? 'open-reviews' : ''} ${buttonClass} button-effect-2 font-semi-bold`}>
                            {dayCount}
                        </button>
                    {/each}
                </div>


                <div class="col-md-1 font-size-large">
                    <p class="day-label font-regular">Mi.</p>
                    {#each weekdaysOfMonth["Mi"] as {dayCount, buttonClass}}
                        <button on:click={() =>updateURL(dayCount, buttonClass)}
                                class={`day-button ${buttonClass === 'current-month-button' ? isSelected(dayCount)? 'selected' : '' : ''} ${checkReviewsDays(dayCount, buttonClass) ? 'open-reviews' : ''} ${buttonClass} button-effect-2 font-semi-bold`}>
                            {dayCount}
                        </button>
                    {/each}
                </div>
                <div class="col-md-1 font-size-large">
                    <p class="day-label font-regular ">Do.</p>
                    {#each weekdaysOfMonth["Do"] as {dayCount, buttonClass}}
                        <button on:click={() => updateURL(dayCount, buttonClass)}
                                class={`day-button ${buttonClass === 'current-month-button' ? isSelected(dayCount)? 'selected' : '' : ''} ${checkReviewsDays(dayCount, buttonClass) ? 'open-reviews' : ''} ${buttonClass} button-effect-2 font-semi-bold`}>
                            {dayCount}
                        </button>
                    {/each}
                </div>


                <div class="col-md-1 font-size-large">
                    <p class="day-label font-regular">Fr.</p>
                    {#each weekdaysOfMonth["Fr"] as {dayCount, buttonClass}}
                        <button on:click={() => updateURL(dayCount, buttonClass)}
                                class={`day-button ${buttonClass === 'current-month-button' ? isSelected(dayCount)? 'selected' : '' : ''} ${checkReviewsDays(dayCount, buttonClass) ? 'open-reviews' : ''} ${buttonClass} button-effect-2 font-semi-bold`}>
                            {dayCount}
                        </button>
                    {/each}
                </div>


                <div class="col-md-1 font-size-large">
                    <p class="day-label font-regular">Sa.</p>
                    {#each weekdaysOfMonth["Sa"] as {dayCount, buttonClass}}
                        <button on:click={() => updateURL(dayCount, buttonClass)}
                                class={`day-button ${buttonClass === 'current-month-button' ? isSelected(dayCount)? 'selected' : '' : ''} ${checkReviewsDays(dayCount, buttonClass) ? 'open-reviews' : ''} ${buttonClass} button-effect-2 font-semi-bold`}>
                            {dayCount}
                        </button>
                    {/each}
                </div>


                <div class="col-md-1 font-size-large">
                    <p class="day-label font-regular">So.</p>
                    {#each weekdaysOfMonth["So"] as {dayCount, buttonClass}}
                        <button on:click={() => updateURL(dayCount, buttonClass)}
                                class={`day-button ${buttonClass === 'current-month-button' ? isSelected(dayCount)? 'selected' : '' : ''} ${checkReviewsDays(dayCount, buttonClass) ? 'open-reviews' : ''} ${buttonClass} button-effect-2 font-semi-bold`}>
                            {dayCount}
                        </button>
                    {/each}
                </div>

            </div>
        </div>
    </Modal>
{/if}




