<script>
    import {checkTimeInputLength, formatDurationInput, formatTimeInput} from "./TimeInputFormatter";
    import {formatTimeInTimeZone} from "./date-time";
    import {update} from "../stores/worklogs";

    export let time;
    export let lastWorklog;
    export let closingTime;
    export let inputMode = 'startTime'
    export let reviewPage = false;
    let modalBadge = inputMode === 'duration' ? 'm' : 'UHR'


    function handleInput(e) {
        if (inputMode !== 'duration') {
            formatTimeInput(e)
        } else {
            modalBadge = formatDurationInput(e.currentTarget.value)
        }

        if (inputMode === 'startTime') {
            time = e.currentTarget.value
        }
    }

    function handleFocus() {
        if (inputMode === 'duration' && time) {
            modalBadge = time;
        }
        this.select();
    }

    function handleBlur(e) {
        if (!e.currentTarget.value) {
            time = null;
            return;
        }

        if (inputMode !== 'duration') {
            time = checkTimeInputLength(e.currentTarget.value)

            if (inputMode === 'closingTime') {
                const lastWorklogStartTime = formatTimeInTimeZone(lastWorklog.startTime);

                const date = lastWorklog.startTime.split('T')[0];
                updateClosingTime(e, lastWorklogStartTime, date);
            }
        } else {
            time = modalBadge;
        }
    }

    function updateClosingTime(e, lastWorklogStartTime, date) {

        if (time <= lastWorklogStartTime || closingTime === time) {
            e.currentTarget.value = closingTime
            return
        }

        lastWorklog.endTime = `${date} ${time}`;
        update(lastWorklog, lastWorklog.id, date)
    }
</script>

<div>
    <slot name="input-label">
        <label class="time-input-label font-regular font-size-small" for="time-input">Startzeit</label>
    </slot>

    <input class="time-input {inputMode === 'closingTime' ? 'closingTime font-semi-bold pl-3' : 'font-regular'} font-size-default" type="text"
           id="time-input-field" value={time}
           on:input={handleInput}
           on:focus={handleFocus}
           on:blur={handleBlur}
           disabled={reviewPage}
           maxlength="5"
    />
    <div class="time-input-badge">
        <span class="time-input-span {inputMode} font-semi-bold font-size-default">{modalBadge}</span>
    </div>
    <slot name="time-input-icon">
        <div class="time-input-badge">
            <svg class="time-input-icon {inputMode}">
                <use xlink:href="/images/icons/time.svg#Ebene_1"></use>
            </svg>
        </div>
    </slot>
</div>

<style>
    .time-input-label {
        font-size: 0.7rem;
        color: var(--main-text-color)
    }

    .time-input-badge {
        position: relative !important;
        float: right !important;
        height: 0 !important;
        width: 0 !important;
    }

    .time-input-icon {
        position: absolute;
        height: 1.1rem;
        max-height: 1.1rem;
        width: 1.1rem;
        max-width: 1.1rem;
        top: 0.65rem;
        right: 27rem;
        fill: var(--primary-color-5);
    }

    .time-input-icon.startTime {
        right: 26.6rem;
        top: 0.65rem;
    }

    .time-input {
        width: 27rem;
        background-color: var(--primary-color-2);
        border: 1px solid var(--primary-color-2);
        border-radius: 3px;
        height: 2.5rem;
        text-align: left;
        padding-left: 2.5rem;
    }

    .time-input.closingTime {
        width: 7rem;
        font-size: 0.8rem;
        border: none;
        padding: 0.8rem;
        color: var(--primary-color-5);
    }

    .time-input-span {
        height: 1.5rem;
        position: absolute;
        font-size: 0.7rem;
        top: 0.5rem;
        right: 2rem;
        background: var(--primary-color-3) 0% 0% no-repeat padding-box;
        width: 2.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--primary-color-5);
        border-radius: 2px;
    }

    .time-input-span.duration {
        right: 2.5rem;
    }

    .time-input-span.closingTime {
        right: 0.5rem;
    }

</style>
