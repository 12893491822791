<script>
    import WorklogModal from "./WorklogModal.svelte";
    import TimeInput from "../util/TimeInput.svelte";
    import {formatTimeInTimeZone} from "../util/date-time";
    import {trapFocus} from "../util/Shortcut";
    export let worklog = [];
    export let selectedUser
    let showModal = false;
    let formattedTime;

    function handleOpenModal() {
        showModal = true;
        formattedTime = formatTimeInTimeZone(worklog.startTime);
        document.addEventListener('keydown', trapFocus);
    }

</script>

<button class="worklog-edit-button button-effect-2" id="edit-modal" on:click={handleOpenModal}>
    <svg class="worklog-edit-icon">
        <use xlink:href="/images/icons/edit.svg#Ebene_1"></use>
    </svg>
</button>

<WorklogModal {worklog} {formattedTime} bind:showModal>
    <div slot="action-button-worklog-modal"></div>
    <h1 slot="worklog-modal-header" class="font-semi-bold">Task bearbeiten</h1>
    <div slot="worklog-start-time-slot">
        <TimeInput bind:time={formattedTime} selectedUser={selectedUser}/>
    </div>
</WorklogModal>

<style>
    .worklog-edit-button {
        background-color: var(--primary-color-2);
        border: 0;
        height: 2rem;
        width: 2rem;
        border-radius: 0.2rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .worklog-edit-button:hover {
        background-color: var(--accent-colors-blue);
    }

    .worklog-edit-icon {
        height: 1rem;
        width: 1rem;
        fill: var(--primary-color-5);
    }
</style>
