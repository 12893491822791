<script>
    import Modal from "../util/Modal.svelte";
    import {worklogs, fetchWorklogs, create, update, fetchRejectedWorklogs, weeklyWorklogs} from "../stores/worklogs";
    import {visibleProjects, projects} from "../stores/projects"
    import {costCenters} from "../stores/costCenters";
    import CoWorkerInput from "../util/CoWorkerInput.svelte";
    import {fetchDivisions, divisions} from "../stores/divisions";
    import {user} from "../stores/user.js"
    import {reviews} from "../stores/reviews.js"
    import TicketNrInput from "../util/TicketNrInput.svelte";
    import TimeInput from "../util/TimeInput.svelte";
    import {subTasks} from "../stores/subTasks.js";
    import {selectedDate} from "../stores/selectedDate";
    import {getWorkingTime, formatTimeInTimeZone} from "../util/date-time.js";
    import DropdownMenu from "../util/DropdownMenu.svelte";
    import CreateConfirmationModal from "./CreateConfirmationModal.svelte";
    import {disableInputFields, checkForInternalProject} from "../util/inputfieldDisabler";
    import {getCoWorkers} from "../stores/users.js";
    import {getWeekdays, getWeeklyWorklogs, highlightReviewIcon} from "../util/highlightReviewStatus.js";
    import {trapFocus} from "../util/Shortcut";
    import {validateUpdateTask} from "../util/InputValidation";

    export let formattedTime;
    export let selectedUser;
    export let showModal = false;
    export let reviewPage = false
    let dateParam;
    let coWorkers = [];
    let current = new Date($selectedDate)
    let errorMessage;
    let selectedProject;
    let selectedCostCenter;
    let showConfirmationModal = false;
    let hasAcceptedTasks = false;
    let disableFields = false;
    const options = {weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'}
    let filteredCostCenters = [];

    export let worklog = {
        title: '',
        description: null,
        project: null,
        costCenter: null,
        coWorkers: [],
        tickets: [],
        user: `/api/users/${$user.id}`,
        onceApproved: false,
        startTime: '',
        endTime: '',
        missingTicketNr: false,
        billable: $user.billable,
        reviewStatus: ''
    };

    function handleTaskCreateKeyDown(event) {
        if (event.key === 'Enter') {
            checkReviewStatus(event);
        } else {
            event.preventDefault();
        }
    }

    function resetModal() {
        worklog = {
            title: '',
            description: null,
            project: null,
            costCenter: null,
            coWorkers: [],
            tickets: [],
            user: `/api/users/${$user.id}`,
            onceApproved: false,
            startTime: '',
            endTime: '',
            missingTicketNr: false,
            billable: $user.billable,
            reviewStatus: ''
        };

        coWorkers = [];
        errorMessage = null;
        selectedProject = null;
        selectedCostCenter = null;
        disableFields = false;
    }

    function handleModalOpen() {
        showModal = true;
        document.addEventListener('keydown', trapFocus);
    }

    function handleModalClose() {
        showModal = false;
        showConfirmationModal = false;
        hasAcceptedTasks = false;
        fetchWorklogs();
        document.removeEventListener('keydown', trapFocus);
        getWorklogs()
        resetModal();
    }

    function getWorklogs() {
        const pageTitle = document.querySelector('.content-title').innerHTML

        if (pageTitle.includes('Issues')) {
            fetchRejectedWorklogs()
        } else {
            fetchWorklogs()
        }
    }

    function updateWorkingTime(selectedDate, worklogs, subTasks, costCenters) {
        const formattedTime = selectedDate.toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
        getWorkingTime(formattedTime, worklogs, subTasks, costCenters)
    }

    function checkReviewStatus(event) {
        $worklogs.forEach(worklog => {
            if (worklog.reviewStatus === 'success') hasAcceptedTasks = true
        })

        if (hasAcceptedTasks && !worklog.id) {
            showConfirmationModal = true
        } else {
            handleSubmit(event)
        }
    }

    function handleClick(event) {
        const createSubmit = event.detail.createSubmit

        if (createSubmit) {
            handleSubmit(event)
        } else {
            showModal = false
            resetModal()
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        worklog.coWorkers = getCoWorkers(worklog)
        try {
            if (!worklog.id) {
                await create(worklog, dateParam)
            } else {
                if (!worklog.startTime) throw "Bitte Startzeit angeben"
                dateParam = worklog.startTime.split('T')[0]
                worklog.startTime = formattedTime;
                await update(worklog, worklog.id, dateParam, $reviews)
            }
            if (!reviewPage) {
                let weekdays = await getWeekdays(current, options)
                await getWeeklyWorklogs(weekdays, selectedUser)
                await highlightReviewIcon($weeklyWorklogs, weekdays)
            }
            handleModalClose();
            updateWorkingTime($selectedDate, $worklogs, $subTasks, $costCenters)
        } catch (error) {
            if (worklog.startTime?.length > 5) worklog.startTime = formatTimeInTimeZone(worklog.startTime)
            errorMessage = error;
        }
    }

    $: {
        async function prepare() {
            await fetchDivisions();
            checkDisabledFields();
            filterCostCenters();
            const url = new URL(window.location.href);
            dateParam = url.searchParams.get("routeParams[date]");
        }

        if (showModal) {
            prepare()
            getSelection()
        }
    }

    function filterCostCenters() {
        const divisionIds = $divisions.map(division => division['@id']);
        filteredCostCenters = $costCenters.filter(costCenter => divisionIds.includes(costCenter.division));
    }

    function getSelection() {
        selectedProject = $visibleProjects.find(project => project['@id'] === worklog.project);
        selectedCostCenter = $costCenters.find(costCenter => costCenter['@id'] === worklog.costCenter);
    }

    function handleCostCenterChange() {
        worklog.costCenter = selectedCostCenter['@id']
        const internalProject = checkForInternalProject($projects, selectedCostCenter)

        if (internalProject) {
            selectedProject = internalProject
        }

        disableFields = disableInputFields(worklog, internalProject, selectedCostCenter)
    }

    function handleCostCenterReset() {
        if (disableFields) worklog.missingTicketNr = false
        worklog.costCenter = null
        worklog.title = null
        disableFields = false
    }

    function checkDisabledFields() {
        if (!worklog.id) return
        const internalProject = checkForInternalProject($projects, selectedCostCenter)
        if (internalProject) disableFields = true
    }

</script>

<slot name="action-button-worklog-modal">
    {#if selectedUser?.id === $user.id}
        <div class="worklog-footer">
            <div class="worklog-modal-button-div mb-5">
                <button class="task-hinzufügen font-semi-bold mt-5 rounded-border-1 button-effect-2" id="add-task"
                        on:click={handleModalOpen}>
                    <svg class="new-task-icon">
                        <use xlink:href="/images/icons/new_task.svg#Ebene_1">
                        </use>
                    </svg>
                    Task hinzufügen
                    (C)
                </button>
            </div>
        </div>
    {/if}
</slot>

{#if showModal}
    <Modal class="worklog-modal" bind:showModal on:close={handleModalClose}>
        <div class="worklog-modal-div" id="modal-div">
            <slot name="worklog-modal-header">
                <h1 class="modal-header font-semi-bold">Task hinzufügen</h1>
            </slot>
            {#if errorMessage}
                    <div class="modal-error-message mb-2 rounded-border-1 font-regular font-size-default" data-error={errorMessage}>
                        <svg class="modal-error-message-icon mr-1">
                            <use xlink:href="/images/icons/info.svg#Ebene_1"/>
                        </svg>
                        {errorMessage}
                    </div>
            {/if}

            <slot name="worklog-start-time-slot">
                <TimeInput bind:time={worklog.startTime} selectedUser={selectedUser}/>
            </slot>

            <DropdownMenu bind:selectedItem={selectedProject}
                          bind:dropdownItems={$visibleProjects}
                          on:select={() => worklog.project = selectedProject['@id']}
                          on:delete={() => worklog.project = null}
                          mode="task" collection="Projekt" disabled={disableFields}/>

            <DropdownMenu bind:selectedItem={selectedCostCenter}
                          bind:dropdownItems={filteredCostCenters}
                          on:select={() => handleCostCenterChange()}
                          on:delete={handleCostCenterReset}
                          mode="task" collection="Kostenstelle">
                <div slot="dropdown-item-code"></div>
            </DropdownMenu>

            <div class="worklog-half-div">
                <TicketNrInput bind:ticketNumbers={worklog.tickets}
                               bind:missingTicketNr={worklog.missingTicketNr}
                               bind:errorMessage={errorMessage} disabled={disableFields}/>
                <CoWorkerInput bind:coWorkers={worklog.coWorkers} disabled={disableFields}/>

            </div>
            <label class="worklog-modal-label font-regular font-size-small" for="title">Zusammenfassung</label>
            <input class="worklog-modal-input font-regular font-size-default" type="text" id="title" bind:value={worklog.title}
                   disabled={disableFields}/>


            <label class="{disableFields ? 'worklog-modal-label font-regular disabled' : 'worklog-modal-label font-regular'} font-size-small"
                   for="description">Beschreibung</label>
            <textarea class="{disableFields ?'worklog-modal-text disabled' : 'worklog-modal-text'} font-regular font-size-default"
                      id="description"
                      bind:value={worklog.description} disabled={disableFields}></textarea>


            <button class="worklog-modal-button font-bold button-effect-2 font-size-medium" id="modal-button" on:click={checkReviewStatus}
                    on:keydown={handleTaskCreateKeyDown}>
                Task {worklog.id ? 'bearbeiten' : 'erstellen'}
            </button>
        </div>
    </Modal>
{/if}

<CreateConfirmationModal bind:showModal={showConfirmationModal} on:click={handleClick}/>

<style>
    .worklog-footer {
        display: flex;
        justify-content: center;
    }

    .task-hinzufügen {
        position: relative;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--accent-colors-blue);
        padding: 0.7rem 1rem;
    }

    .new-task-icon {
        fill: #FFFFFF;
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.5rem;
        margin-bottom: 2px;
        align-items: center;
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .worklog-modal-div {
        margin-left: 1.75rem;
        text-align: left;
    }

    .worklog-modal-button {
        background-color: var(--accent-colors-blue);
        color: #fff;
        width: 27rem;
        padding: 0.625rem;
        margin-bottom: 0.938rem;
        margin-top: 1.5rem;
        border: 1px solid var(--accent-colors-blue);
        border-radius: 3px;
    }

    .worklog-modal-input {
        width: 27rem;
        background-color: var(--primary-color-2);
        border: 1px solid var(--primary-color-2);
        border-radius: 3px;
        height: 2rem;
        margin-top: 0.2rem;
    }

    .worklog-modal-text {
        width: 27rem;
        background-color: var(--primary-color-2);
        border: 1px solid var(--primary-color-2);
        height: 6rem;
        border-radius: 3px;
        margin-top: 0.2rem;
        color: var(--main-text-color)
    }

    .worklog-half-div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .worklog-modal-label {
        margin-top: 0.8rem;
        color: var(--main-text-color)
    }

    .worklog-modal-label, .modal-header, .modal-error-message {
        color: var(--main-text-color);
    }

</style>