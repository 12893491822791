import {formatWeekdayDate} from "./date-time.js";
import {fetchWeeklyWorklogs, worklogs} from "../stores/worklogs.js";

export async function highlightReviewIcon(worklogs, weekdays) {
    resetClassName();

    const icons = document.querySelectorAll('.weekday-review-icon');

    if (!icons.length) return

    weekdays.forEach(weekday => {
        let failure = false;
        let success = false;
        let open = false;
        let privately = false;
        let status;
        let startTime;

        worklogs.forEach(worklog => {
            status = worklog.reviewStatus;
            startTime = worklog.startTime;
            let formattedDate = formatWeekdayDate(weekday.date)

            if (startTime.includes(formattedDate)) {
                status = worklog.reviewStatus;

                if (status === 'failure') {
                    failure = true;
                }
                if (status === 'private') {
                    privately = true
                }
                if (status === 'open') {
                    open = true;
                }
                if (status === 'success') {
                    success = true;
                }
            }
        })

        if (failure) {
            icons[weekdays.indexOf(weekday)].classList.add('failure');
        }

        if (privately && !failure) {
            icons[weekdays.indexOf(weekday)].classList.add('private');
        }

        if (open && !failure && !privately) {
            icons[weekdays.indexOf(weekday)].classList.add('open');
        }

        if (success && !failure && !open && !privately) {
            icons[weekdays.indexOf(weekday)].classList.add('success');
        }
    })
}

export function resetClassName() {
    const icons = document.querySelectorAll('.weekday-review-icon');

    icons.forEach(icon => {
        icon.classList.remove('failure');
        icon.classList.remove('open');
        icon.classList.remove('success');
    })
}

export async function getWeekdays(currentDate, options, roles) {
    let weekdays = []
    for (let i = 1; i <= 5; i++) {
        const currentDays = currentDate.getDate() - currentDate.getDay() + i;
        const dayDate = new Date(currentDate.setDate(currentDays)).toLocaleDateString('de-DE', options);



        const splitDate = dayDate.split(', ');
        const [day, date] = splitDate;

        //TODO fetchWeeklyWorklogs und dann filter für openWorklogs
        weekdays.push({day, date});
    }

    return weekdays
}

async function getOpenReviews(worklogs, weekdayDate, roles) {
    const openReviews = [];
    if (!roles.some(role => role.code.includes("ROLE_REVIEWER"))) return

    const formattedDate = formatWeekdayDate(weekdayDate);

    worklogs.forEach(worklog => {
        const startTime = worklog.startTime;

        if (startTime.includes(formattedDate)) {
            openReviews.push(worklog);
        }
    })

    return openReviews.length;
}

export async function getWeeklyWorklogs(weekdays, selectedUser) {
    const firstDate = formatWeekdayDate(weekdays[0].date);

    if (selectedUser) {
        await fetchWeeklyWorklogs(firstDate, selectedUser)
    } else {
        await fetchWeeklyWorklogs(firstDate);
    }
}

export function getMyReviews(weekday, reviews, user, openWorklogs) {
    const currentReviews = reviews.filter(review => +review.user.split('/')[3] === user.id)
    const foundWorklogs = openWorklogs.filter(openWorklog => currentReviews.find(currentReview => currentReview.worklog.includes(openWorklog['@id'])))
    let parts
    if (!weekday.date) {
        parts = weekday.split('.')
    } else {
        parts = weekday.date.split('.')
    }
    const startDate = parts[2] + '-' + parts[1] + '-' + parts[0]

    return foundWorklogs.filter(foundWorklog => foundWorklog.startTime.includes(startDate)).length;
}

export async function updateHighlightStatus(current, worklogs, options) {
    const weekdays = await getWeekdays(current, worklogs, options)
    await highlightReviewIcon(worklogs, weekdays)
}