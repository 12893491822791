<script>
    import {selectedDate} from "../stores/selectedDate";
    import {formatWeekdayDate, formatWeekdayDateEvent} from "../util/date-time";
    import Calendar from "./Calendar.svelte";
    import {data, storeProjectId} from "../stores/statistic";
    import OneProjectStatisticTab from "./OneProjectStatisticTab.svelte";
    import {onMount, onDestroy} from "svelte";
    import StatisticTimeRangeModal from "./StatisticTimeRangeModal.svelte";
    import {validateCreateTask} from "../util/InputValidation";
    import {fetchWorklogs} from "../stores/worklogs";


    const options = {weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'};
    let current = new Date($selectedDate);
    export let webpages

    onMount(() => {
        addClassName(tabName)
    })

    export let tab;
    let selectedPage = webpages.find(webpage => webpage.name === tab);
    let currentIndex = 0;
    const pageSize = 5;
    let tabName = selectedPage.name;
    export let projectId = $data?.id
    let currentPage;
    let monthName
    let endDate

    function handleClick(obj) {

        removeClassName(tabName)

        selectedPage = obj;
        currentPage = selectedPage.component
        tabName = (obj.name);
        addClassName(tabName)
    }

    onMount(() => {
        currentPage = selectedPage.component
        if ($storeProjectId) {
            currentPage = OneProjectStatisticTab
        }

        monthName = $selectedDate.toLocaleString('de-DE', {month: 'long', year: 'numeric'})
    })

    function switchMonths(shift) {
        const year = $selectedDate.getFullYear()
        const month = $selectedDate.getMonth() + shift
        const date = new Date(year, month, 1)
        let formattedDate = date.toLocaleDateString('de-DE', options);
        let splitDate = formattedDate.split(', ')[1];
        redirect(splitDate);
    }

    function resetCurrentWeek() {
        current = new Date();
        let formattedDate = current.toLocaleDateString('de-DE', options);
        let splitDate = formattedDate.split(', ')[1];
        redirect(splitDate);
    }

    function redirect(selectedDate) {
        const page = 'statistic';
        const domain = window.location.origin;
        if (selectedDate instanceof Event) {
            const {startDate, endDate} = selectedDate.detail;
            const formattedEndDate = formatWeekdayDateEvent(endDate)
            const formattedStartDate = formatWeekdayDateEvent(startDate);
            return window.location.href = `${domain}/admin?routeName=${page}_date&routeParams%5Bdate%5D=${formattedStartDate}&routeParams%5BendDate%5D=${formattedEndDate}&routeParams%5Btab%5D=${tabName}`;
        }
        const formattedDate = formatWeekdayDate(selectedDate);
        window.location.href = `${domain}/admin?routeName=${page}_date&routeParams%5Bdate%5D=${formattedDate}&routeParams%5Btab%5D=${tabName}`;
    }

    function showNext() {
        if (currentIndex + pageSize < webpages.length) {
            currentIndex += pageSize;
        }
    }

    function showPrev() {
        if (currentIndex - pageSize >= 0) {
            currentIndex -= pageSize;
        }
    }

    function removeClassName(name) {
        const element = document.getElementById(name);
        element.classList.remove('selected')
    }

    function addClassName(name) {
        const element = document.getElementById(name);
        element.classList.add('selected')
    }
</script>

<style>
    .weekdaysWorklogPageStyle {
        display: flex;
        position: relative;
        left: 0.6vw;
        bottom: 40px;
        width: 100%;
    }

    .tab {
        background-color: var(--primary-color-1);
        width: 12vw;
        margin-right: 1.5vw;
        border-radius: 2rem;
        border: 0.05rem solid var(--primary-color-3);
        padding-inline: 0.75rem;
        position: relative;
        left: 1.2vw;
        font-family: "Poppins Bold";
        color: var(--primary-color-5);
        word-break: break-word;
    }

    .weekdays-today-button {
        background-color: var(--primary-color-2);
        width: 7vw;
        height: 70px;
        border-radius: 1rem 0 0 1rem;
        border: 0;
        font-size: 12px;
        color: var(--primary-color-5);
        margin: 0 0 0 auto;
    }

    .weekdays-today-button:hover {
        background-color: var(--accent-colors-blue);
    }

    .weekdays-today-icon {
        height: 1.3rem;
        width: 1.3rem;
        margin: 0 0.2rem 0.2rem 0;
        fill: var(--signal-green);
    }

    .weekdays-left-button, .weekdays-right-button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color-5);
    }

    .weekdays-left-button, .weekdays-right-button {
        position: relative;
        background-color: var(--primary-color-2);
        height: 1.8rem;
        width: 1.8rem;
        margin-right: 1vw;
        border-radius: 0.2rem;
        border: 0;
        align-self: center;
        left: 1vw;
    }

    .statistics-today-calendar-button {
        display: flex;
        width: 11%;
        flex-direction: column;
        margin-left: auto;
    }

    :global(.selected) {
        background-color: var(--primary-color-2) !important;
    }

    .statistic-month-button {
        border: none;
        background-color: var(--primary-color-3);
        color: var(--primary-color-4);
    }

    .statistic-months {
        display: flex;
        justify-content: center;
        font-size: 18px;
    }

    .month-name {
        margin: 0;
        padding-inline: 0.5rem;
    }

    .statistic-date-selection {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>

<div class="weekdaysWorklogPageStyle">
    {#if webpages.length > pageSize && currentIndex - pageSize >= 0}
        <button class="weekdays-left-button button-effect-1" on:click={showPrev} disabled={currentIndex === 0}>
            <i class="fa-solid fa-chevron-left fa-lg"></i>
        </button>
    {/if}
    {#each webpages.slice(currentIndex, currentIndex + pageSize) as webpageObj}
        <button class="tab button-effect-1 font-size-medium" title={webpageObj.name}
                on:click={() => handleClick(webpageObj)} id={webpageObj.name}>
            {webpageObj.name}
        </button>
    {/each}
    {#if webpages.length > pageSize && currentIndex + pageSize < webpages.length}
        <button class="weekdays-right-button button-effect-1" on:click={showNext}
                disabled={currentIndex + pageSize >= webpages.length}>
            <i class="fa-solid fa-chevron-right fa-lg"></i>
        </button>
    {/if}
    <div class="statistics-today-calendar-button">
        <button class="weekdays-today-button button-effect-2 font-semi-bold font-size-default"
                on:click={resetCurrentWeek}>
            <svg class="weekdays-today-icon">
                <use xlink:href="/images/icons/today.svg#Ebene_1"></use>
            </svg>
            Heute
        </button>
        <Calendar {tabName} projectId={$storeProjectId}/>
    </div>
</div>
<div class="statistic-date-selection">
    <div class="statistic-months">
        <button class="statistic-month-button previous fa-solid fa-chevron-left button-effect-1"
                on:click={() => switchMonths(-1)}></button>
        <p class="month-name font-semi-bold">
            {monthName}
        </p>
        <button class="statistic-month-button next fa-solid fa-chevron-right button-effect-1"
                on:click={() => switchMonths(+1)}></button>
    </div>
    <StatisticTimeRangeModal on:selected={redirect}/>
</div>

<svelte:component this={currentPage} bind:projectId/>

