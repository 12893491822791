import {writable} from "svelte/store";
import {apiUrl} from "./environment";

export const costCenters = writable([]);

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})


export async function fetchCostCenters() {
    try {
        const response = await fetch(`${baseUrl}/api/cost_centers`);
        const data = await response.json();
        costCenters.set(data['hydra:member']);
    }catch(error) {
        throw new Error(error);
    }
}

