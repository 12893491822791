<script>
    import DropdownMenu from "../util/DropdownMenu.svelte";
    import {fetchProjects, projects} from "../stores/projects";
    import {fetchColors} from "../stores/colors";
    import {onMount} from "svelte";
    import {createProjectYield, updateProjectYield, fetchProjectYields, yieldEntries} from "../stores/statisticConfig";

    let selectedProject
    let hideDropdown = true
    let selectedEntry
    let selectedIndex
    let previousEntry
    let nextEntry
    let errorMessage = null

    onMount(async () => {
        await fetchColors()
        await fetchProjects()
    })

    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    };

    let projectYield = {
        'startDate': null,
        'projectId': null,
        'yield': null
    }

    function resetFields() {
        projectYield = {
            'startDate': null,
            'projectId': null,
            'yield': null
        }
        selectedIndex = null
        previousEntry = null
        nextEntry = null
        selectedEntry = null
        errorMessage = null
    }

    async function selectProject() {
        resetFields()
        projectYield.projectId = selectedProject['@id']

        await fetchProjectYields(selectedProject.id)
    }

    async function deleteSelection() {
        resetFields()
        selectedProject = null
    }

    async function submit() {
        try {
            if (selectedEntry === 'Neu erstellen') {
                await createProjectYield(projectYield)
            } else {
                const selected = $yieldEntries[selectedIndex]
                await updateProjectYield(projectYield, selected['@id'])
            }
            resetFields()
            selectedProject = null
        } catch (error) {
            errorMessage = error
        }
    }

    document.addEventListener("mousedown", (event) => {
        if (!event.target.classList.contains('choose-entry')) {
            hideDropdown = true
        }
    });

    function formatDate(dateString) {
        const date = new Date(dateString)
        return date.toLocaleDateString('de-DE', options)
    }

    function selectEntry(entry) {
        projectYield.startDate = null
        projectYield.yield = null

        if (entry === 'new') {
            selectedEntry = 'Neu erstellen'
            selectedIndex = null
            nextEntry = null
            previousEntry = $yieldEntries.at(-1)
        } else {
            selectedEntry = formatDate(entry.startDate)
            selectedIndex = $yieldEntries.indexOf(entry)
            previousEntry = $yieldEntries[selectedIndex - 1]
            nextEntry = $yieldEntries[selectedIndex + 1]
        }

        errorMessage = null
        hideDropdown = true
    }

</script>

<p class="yield-config-headline font-semi-bold font-size-medium ml-2">Projekt auswählen, um den Betrag pro Stunde zu aktualisieren</p>
<div class="yield-config-select mb-5">
    <DropdownMenu bind:selectedItem={selectedProject}
                  bind:dropdownItems={$projects}
                  on:select={selectProject}
                  on:delete={deleteSelection}
                  mode="config" collection="Projekt">
        <div slot="dropdown-label"></div>
    </DropdownMenu>

    {#if projectYield.projectId}
        <div class="yield-config-dropdown-div font-regular font-size-default">
            <button class="yield-config-dropdown-toggle choose-entry button-effect-1 font-medium font-size-medium" on:mousedown={() => hideDropdown = !hideDropdown}>
                {selectedEntry ?? 'Auswählen' }
            </button>
            <div class="yield-config-dropdown-content choose-entry" hidden={hideDropdown}>
                <ul class="item-list choose-entry">
                    <li class="dropdown-item choose-entry {selectedEntry === 'Neu erstellen' ? 'selected' : ''}" on:click={() => selectEntry('new')}>Neuer Eintrag</li>
                    {#if $yieldEntries.length}
                        {#each $yieldEntries as entry}
                            <li class="dropdown-item choose-entry{selectedEntry === formatDate(entry.startDate) ? 'selected' : ''}" on:click={() => selectEntry(entry)}>
                                Startzeit: {formatDate(entry.startDate)}
                                Ertrag: {entry.yield}
                            </li>
                        {/each}
                    {/if}
                </ul>
            </div>
        </div>
    {/if}
</div>
<div class="yield-config-wrapper">
    {#if selectedEntry}
        <div class="yield-config-info mb-5 rounded-border-1 font-medium font-size-medium">
            <svg class="yield-config-info-icon mr-3">
                <use xlink:href="/images/icons/info.svg#Ebene_1"/>
            </svg>
            <p class="yield-config-info-text">
                Zwischen zwei Einträgen muss mindestens ein Monat liegen.
                Ein Eintrag darf beim Erstellen/Bearbeiten den Zeitraum eines anderen Eintrags nicht überschneiden.
            </p>
        </div>
        <div class="yield-config-main-content font-regular font-size-default">
            <div class="yield-config-form">
                <div class="yield-config-form-field">
                    <label for="start-date" class="mr-2">Startdatum</label>
                    <input type="date" class="yield-config-date-picker rounded-border-1" id="start-date" bind:value={projectYield.startDate}>
                </div>
                <div class="yield-config-form-field">
                    <label for="project-yield" class="mr-2">Ertrag pro Stunde (in €)</label>
                    <input type="number" class="yield-config-input-field rounded-border-1" id="project-yield" bind:value={projectYield.yield}>
                </div>
                <button class="yield-config-submit-button rounded-border-1 mt-2 button-effect-2 font-medium" on:click={submit}>Bestätigen</button>
            </div>
            <div class="yield-config-entry-wrapper">
                <p class="yield-config-entry-headline font-semi-bold font-size-medium">
                    Übersicht über bestehende Eintage für Projekt {selectedProject.name}
                </p>
                <div class="yield-config-entries">
                    {#if !previousEntry && !nextEntry }
                        <p class="yield-config-entry-headline font-medium">
                            Es existieren keine bestehenden Einträge für dieses Projekt
                        </p>
                    {/if}
                    {#if previousEntry}
                        <div class="yield-config-entry rounded-border-1 previous">
                            <p class="yield-config-entry-headline font-medium">
                                Werte des vorherigen Eintrags
                            </p>
                            <p class="yield-config-entry-data">
                                Startdatum: {formatDate(previousEntry.startDate)}
                                Enddatum: {formatDate(previousEntry.endDate)}
                                Ertrag pro Stunde: {previousEntry.yield}
                            </p>
                        </div>
                    {/if}
                    {#if selectedEntry !== 'Neu erstellen'}
                        <div class="yield-config-entry rounded-border-1 current">
                            <p class="yield-config-entry-headline font-medium">
                                Werte des ausgewählten Eintrags
                            </p>
                            <p class="yield-config-entry-data">
                                Startdatum: {formatDate($yieldEntries[selectedIndex].startDate)}
                                Enddatum: {formatDate($yieldEntries[selectedIndex].endDate)}
                                Ertrag pro Stunde: {$yieldEntries[selectedIndex].yield}
                            </p>
                        </div>
                    {/if}
                    {#if nextEntry}
                        <div class="yield-config-entry rounded-border-1 next">
                            <p class="yield-config-entry-headline font-medium">
                                Werte des nächsten Eintrags
                            </p>
                            <p class="yield-config-entry-data">
                                Startdatum: {formatDate(nextEntry.startDate)}
                                Enddatum: {formatDate(nextEntry.endDate)}
                                Ertrag pro Stunde: {nextEntry.yield}
                            </p>
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    {/if}
    {#if errorMessage}
        <div class="yield-config-error-message font-semi-bold font-size-medium rounded-border-1">
            {errorMessage}
        </div>
    {/if}
</div>

<style>
    .yield-config-dropdown-toggle {
        cursor: pointer;
        color: var(--primary-color-5);
        background-color: var(--primary-color-2);
        padding-inline: 0.7rem;
        border-radius: 0.2rem;
        height: 2.4rem;
        border: none;
        width: 10rem;
    }

    .yield-config-dropdown-toggle::after {
        display: none;
    }

    .yield-config-dropdown-content {
        position: absolute;
        background-color: var(--primary-color-2);
        border-radius: 0.2rem;
        z-index: 2;
        animation: fadeIn 0.2s ease-in-out;
        box-shadow: 0 3px 4px 1px #1B242C;
    }

    .item-list {
        padding-left: 0;
        margin: 0;
        list-style: none;
        border-radius: 0.2rem;
        max-height: 9rem;
        overflow-y: auto;
        background-color: var(--primary-color-2);
        width: 19rem;
        animation: fadeIn 0.2s ease;
    }

    .item-list::-webkit-scrollbar {
        background-color: var(--primary-color-1);
        border-bottom-right-radius: 0.2rem;
        width: 0.5rem;
    }

    .item-list::-webkit-scrollbar-thumb {
        background-color: var(--primary-color-4);
        border-radius: 1rem;
    }

    .dropdown-item {
        cursor: pointer;
        padding: 0.5rem 0 0.5rem;
        padding-inline: 0.5rem 0;
        color: var(--main-text-color)
    }

    .dropdown-item.selected, .dropdown-item:hover {
        background-color: var(--primary-color-3) !important;
    }

    .yield-config-select {
        display: inline-flex;
        gap: 0.5rem;
    }

    .yield-config-main-content {
        display: flex;
    }

    .yield-config-form {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .yield-config-error-message {
        background-color: var(--signal-light-red);
        padding: 0.5rem;
        width: fit-content;
        align-self: center;
        margin-top: 1rem;
    }

    .yield-config-wrapper {
        display: flex;
        flex-direction: column;
        padding-inline: 1rem;
        color: var(--primary-color-5)
    }

    .yield-config-entry-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem;
        flex: 50%;
    }

    .yield-config-entry {
        white-space: pre-line;
        background-color: var(--primary-color-2);
        padding: 0.5rem;
        margin-bottom: 1rem;
    }

    .yield-config-form-field input {
        float: right;
    }

    .yield-config-form-field label {
        vertical-align: sub;
    }

    .yield-config-submit-button {
        border: none;
        background-color: var(--accent-colors-blue);
        height: 2rem;
        width: 10rem;
        align-self: center;
    }

    .yield-config-entry-data {
        margin: 0;
    }

    .yield-config-date-picker, .yield-config-input-field {
        border: none;
        background-color: var(--primary-color-2);
        text-align: center;
        height: 2rem;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            max-height: 0;
        }
        to {
            opacity: 1;
            max-height: 9rem;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
            max-height: 9rem;
        }
        to {
            opacity: 0;
            max-height: 0;
        }
    }

    .yield-config-info-text {
        margin: 0;
        white-space: pre-line;
    }

    .yield-config-info-icon {
        height: 1.5rem;
        width: 1.5rem;
        fill: var(--accent-colors-blue);
    }

    .yield-config-info {
        display: flex;
        align-items: center;
        background-color: var(--accent-colors-blue-light);
        color: var(--primary-color-5);
        justify-content: center;
        height: 4rem;
        width: fit-content;
        align-self: center;
        padding-inline: 1rem;
    }
</style>