import {writable} from "svelte/store";
import {fetchWorklogs} from "./worklogs.js";
import {apiUrl} from "./environment";

export const reviews = writable([]);

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})

export const showReviewsCurrentUser = writable()

export async function fetchReviews() {
    try {
        const response = await fetch(`${baseUrl}/api/reviews?page=1&status=open`);
        const data = await response.json();
        reviews.set(data['hydra:member']);
    } catch (error) {
        throw new Error(error);
    }
}

export async function fetchRejectedReviews() {
    try {
        const response = await fetch(`${baseUrl}/api/reviews?page=1&outdated=false&status=failure`);
        const data = await response.json();
        reviews.set(data['hydra:member']);
    } catch (error) {
        throw new Error(error);
    }
}
export async function createReviewFromRejectedWorklog(reviewer, worklogId, user) {

    try {
        const response = await fetch(`${baseUrl}/api/review`, {
            method: 'POST',
            headers: {
                'Content-Type': 'appliation/json',
            },
            body: JSON.stringify({
                reviewer: reviewer,
                worklog: [worklogId],
                user: user
            }),
        });
    } catch (error) {
        console.error('API request error', error);
    }
}

export async function createReviewRequest(worklogs, selectedUser, user) {
    try {
        // Create the new review
        const response = await fetch(`${baseUrl}/api/review`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                worklog: worklogs.map(item => item["@id"]),
                reviewer: selectedUser.toString(),
                user: user
            }),
        });
        await fetchWorklogs()
    } catch (e) {
        throw new Error(e);
    }
}

export async function acceptReview(reviewId) {
    try {
        const response = await fetch(`${baseUrl}${reviewId}/accept`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Unable to accept review');
        }
    } catch (error) {
        console.error('Error accepting review:', error);
    }
}

export async function denyReview(selectedReasons, comment, reviewId) {
    try {
        const response = await fetch(`${baseUrl}${reviewId}/deny`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                reasons: selectedReasons.map(item => item['@id']),
                comment
            }),
        });
        if (!response.ok) {
            throw new Error('Unable to deny review');
        }
    } catch (error) {
        console.error('Error denying review:', error);
    }
}

export async function updateBillable(worklog, subtasks) {
    try {
        const response = await fetch(`/update-billable`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({worklog: worklog, subtasks: subtasks}),
        });
        if (!response.ok) {
            throw new Error('Unable to update billable field');
        }
    } catch (error) {
        console.error('Error updating billable field:', error);
    }
}
