import { registerSvelteControllerComponents } from '@symfony/ux-svelte';
import './bootstrap.js';
import './svelte/util/date-time';
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/sidebar.css';
import './styles/toggle.css';
import './styles/color.css';
import './styles/app.css';
import './styles/admin.css';
import './styles/detail.css';
import './styles/worklogModalSvelte.css'
import './styles/exportmodal.css';
import './styles/reviewSvelte.css';
import './styles/usermodal.css';
import './styles/projectmodal.css';
import './styles/padding.css';
import './styles/margin.css';

registerSvelteControllerComponents(require.context('./svelte/', true, /\.svelte$/));