<script>
    import {millisecondsToHours, calculateTotalWorkTime, formatTimeInTimeZone, needsUpdate} from "../util/date-time";
    import SubTaskTableRow from "./SubTaskTableRow.svelte";
    import WorklogActions from "./WorklogActions.svelte";
    import {getTasks, worklogs as worklogStore, subtasks, fetchWorklogs, fetchWorklogsByUser} from '../stores/worklogs';
    import {filterSubtasks} from "../stores/subTasks.js";
    import {onMount} from "svelte";
    import {setContext} from "svelte";
    import {fetchProjects, projects as projectStore} from "../stores/projects.js";
    import {fetchCostCenters, costCenters as costCenterStore} from "../stores/costCenters.js";
    import {fetchUsers, users as usersStore} from "../stores/users.js";
    import {fetchColors, colors as colorStore} from "../stores/colors.js";
    import {fetchUserRoles, roles} from "../stores/roles.js";
    import TimeInput from "../util/TimeInput.svelte";
    import TaskDescription from "../util/TaskDescription.svelte";
    import {user} from "../stores/user.js";
    import {selectedDate} from "../stores/selectedDate.js";

    export let selectedUser
    export let filteredWorklogs
    export let reviewPage
    let costCenters
    let users
    let colors
    let worklogs
    let projects
    let closingTime
    let lastWorklog
    let userRoles
    let showModal = false

    $:{
        worklogs = filteredWorklogs ? filteredWorklogs : $worklogStore;
        costCenters = $costCenterStore;
        users = $usersStore;
        colors = $colorStore;
        projects = $projectStore;
        userRoles = $roles
        lastWorklog = worklogs.at(-1);
        closingTime = worklogs.at(-1) ? formatTimeInTimeZone(worklogs.at(-1).endTime) : '';
    }

    setContext('worklog-table', {
        getWorklogs: () => worklogs,
        getProjects: () => projects,
        getColors: () => colors,
        getCostCenters: () => costCenters,
        getUsers: () => users,
        getUserRoles: () => userRoles
    })

    function checkAuthorization(worklog) {
        const userString = `/api/users/${$user.id}`
        return !!(worklog.coWorkers.includes(userString) ||
            worklog.user.includes(userString) ||
            $roles.some(({code, users}) => code.includes("ROLE_REVIEWER", "ROLE_ADMIN")
                && users.includes(userString)));
    }

    onMount(async () => {
        await fetchProjects();
        await fetchCostCenters();
        await fetchUsers();
        await fetchColors();
        if (!reviewPage) {
            await fetchWorklogsByUser($selectedDate, selectedUser.id);
        }
        await getTasks(worklogs);
        await fetchUserRoles().then(needsUpdate.set(true));
    })


    function calculateBreak(worklogs, costCenters) {
        if (worklogs.length === 0 || costCenters.length === 0) return
        const filteredSubtasks = filterSubtasks(worklogs, $subtasks)
        const costCenter = costCenters.find(costCenter => costCenter.name === 'Pause')['@id']
        const breakTask = (worklogs.find(worklog => worklog.costCenter === costCenter)?.durationMills) ?? 0;
        const breakSubtask = (filteredSubtasks.find(subtask => subtask.costCenter === costCenter)?.durationMills) ?? 0;
        const breakTime = (breakTask + breakSubtask) / 60000;


        if (breakTime >= 60) {
            const hours = Math.floor(breakTime / 60);
            const minutes = breakTime % 60;
            return `${hours}h${minutes}m`
        }
        return `${breakTime}m`
    }

</script>

<div class="worklog-table">
    <table class="worklog-main-table">
        <slot name="worklog-table-header">
            <thead class="worklog-table-head font-regular font-size-medium">
            <tr>
                <th class="worklog-table-head-data">
                    <div class="table-head-time">Zeit</div>
                </th>
                <th class="worklog-table-head-data"></th>
                <th class="worklog-table-head-data"> Projekt</th>
                <th class="worklog-table-head-data"> Kostenstelle</th>
                <th class="worklog-table-head-data pl-4"> Worklog</th>
                <th class="worklog-table-head-data"> Bearbeiter</th>
                <th></th>
                <th class="worklog-edit-delete"> Löschen/Bearbeiten</th>
            </tr>
            </thead>
        </slot>
        <tbody class="worklog-table-body">
        {#each worklogs as worklog }
            {#if checkAuthorization(worklog)}
                <tr class={`worklog-table-row worklog-status-${worklog.reviewStatus ?? "private"}`}
                    task="{worklog['@id']}" id="table-row">
                    <td class="worklog-table-row-start-time font-bold align-cell-items font-size-default">
                        {formatTimeInTimeZone(worklog.startTime)} Uhr
                    </td>
                    <td class="worklog-table-row-end-time font-bold align-cell-items font-size-smal">
                        <div class="worklog-table-row-end-time-border center-content">
                            {millisecondsToHours(worklog.durationMills)}
                        </div>
                    </td>
                    <td class="worklog-table-row-project-data font-bold align-cell-items font-size-small">
                        <div class="worklog-table-row-project-costCenter-border center-content">
                            {#each projects as project}
                                {#if worklog.project === project['@id']}
                                    {#each colors as color}
                                        {#if project.color === color['@id']}
                                            <svg class="color-dot-worklog"
                                                 style="fill: {color.code};">
                                                <use xlink:href="/images/icons/color_dot.svg#Ebene_1">
                                                </use>
                                            </svg>
                                        {/if}
                                    {/each}
                                    <div class="ml-1">
                                        {project.code}
                                    </div>
                                {/if}
                            {/each}
                        </div>
                    </td>
                    <td class="worklog-table-row-cost-center-data font-bold align-cell-items font-size-small">
                        <div class="worklog-table-row-project-costCenter-border center-content">
                            {#each costCenters as costCenter }
                                {#if worklog.costCenter === costCenter['@id']}
                                    {#each colors as color}
                                        {#if costCenter.color === color['@id']}
                                            <svg class="color-dot-worklog"
                                                 style="fill: {color.code}">
                                                <use xlink:href="/images/icons/color_dot.svg#Ebene_1">
                                                </use>
                                            </svg>
                                        {/if}
                                    {/each}
                                    <div class="ml-1">
                                        {costCenter.name}
                                    </div>
                                {/if}
                            {/each}
                        </div>
                    </td>
                    <td class="worklog-table-row-data-description pl-4 align-cell-items">
                        <div class="worklog-table-row-task-title font-bold font-size-default">
                            {worklog.title}
                        </div>
                        <TaskDescription task={worklog} projects={projects}/>
                    </td>
                    <td class="worklog-table-row-data-processor font-bold align-cell-items font-size-small">
                        <div class="co-workers-div">
                            <div class="worklog-table-row-processor-border center-content">
                                {#each users as user}
                                    {#if worklog.user === user['@id']}
                                        {user.code.toUpperCase()}
                                    {/if}
                                {/each}
                            </div>
                            {#if worklog.coWorkers}
                                <div class="co-workers">
                                    {#each worklog.coWorkers as coWorkerId }
                                        <div class="worklog-table-row-processor-border center-content">
                                            {#each users as coWorker }
                                                {#if coWorkerId === coWorker['@id']}
                                                    {coWorker.code.toUpperCase()}
                                                {/if}
                                            {/each}
                                        </div>
                                    {/each}
                                </div>
                            {/if}
                        </div>
                    </td>
                    <td class="worklog-table-row-slot align-cell-items font-size-default" id="{worklog['@id']}">
                        <slot name="billable">
                        </slot>
                    </td>
                    <slot {worklog} name="table-actions">
                        <td class="worklog-table-row-edit-delete align-cell-items">
                            {#if worklog.user.includes(`/api/users/${$user.id}`)}
                                <WorklogActions {worklog} selectedUser={selectedUser}/>
                            {/if}
                        </td>
                    </slot>
                </tr>
                {#each $subtasks as subTask}
                    <slot name="subtasks" {subTask} {worklog}>
                        <SubTaskTableRow {subTask} {worklog} selectedUser={selectedUser} {worklogs}/>
                    </slot>
                {/each}
            {/if}
        {/each}
        </tbody>
    </table>

    <slot name="worklog-table-footer">
        {#if selectedUser.id === $user.id}
            <div class="worklog-table-footer">
                <div class="worklog-footer">
                    <TimeInput bind:time={closingTime} lastWorklog={lastWorklog} closingTime={closingTime}
                               selectedUser={selectedUser}
                               inputMode="closingTime">
                        <div slot="input-label"></div>
                        <div slot="time-input-icon"></div>
                    </TimeInput>
                    <div class="footer-text font-bold">Feierabend</div>
                </div>
                <div class="worklog-footer">
                    <div class="arbeitszeit font-semi-bold">{calculateTotalWorkTime(worklogs, costCenters, $subtasks)}</div>
                    <div class="footer-text font-bold"> Arbeitszeit</div>
                </div>
                <div class="worklog-footer">
                    <div class="pause font-semi-bold">{calculateBreak(worklogs, costCenters, $subtasks) ?? '0m'}</div>
                    <div class="footer-text font-semi-bold">Pause</div>
                </div>
            </div>
        {/if}
    </slot>
</div>


<style>
    td.worklog-table-row-start-time {
        width: 6rem;
        padding: 0.5rem;
    }

    .worklog-table-row-end-time, .worklog-table-row-project-data, .worklog-table-row-cost-center-data, .worklog-table-row-data-processor {
        width: 7rem;
    }

    .worklog-table-footer {
        display: flex;
        align-items: center;
        padding-top: 1.5rem;
        position: relative;
        left: 3vw;
        width: fit-content
    }

    .worklog-table-head-data {
        text-align: left;
        width: auto;
        color: var(--primary-color-5);
    }

    .worklog-edit-delete {
        color: var(--primary-color-5);
        padding-right: 0.5rem;
        position: relative;
        width: 10%;
    }

    .worklog-table-row-data-description {
        text-align: unset;
        width: 40%;
    }

    .worklog-table-row-project-costCenter-border, .worklog-table-row-processor-border, .worklog-table-row-end-time-border {
        width: fit-content;
        padding: 0.5rem;
        background-color: var(--primary-color-2);
        height: 1.5rem;
        text-wrap: nowrap;
    }

    .worklog-table-row-project-costCenter-border, .worklog-table-row-end-time-border {
        border-radius: 1.375rem;
    }

    .worklog-table-row-processor-border {
        border-radius: 1rem;
        width: 3rem;
        height: 1.5rem;
    }

    .worklog-table-row td {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        background-color: var(--primary-color-1);
        color: var(--primary-color-5);
    }

    .worklog-main-table {
        width: 95%;
        max-width: 95%;
        position: relative;
        left: 2.5vw;
        bottom: 6.25rem;
    }

    .worklog-table-row-task-title {
        color: var(--primary-color-5);
    }

    .worklog-table-row-end-time {
        color: var(--accent-colors-blue) !important;
    }

    .table-head-time {
        padding: 1rem;
    }

    .arbeitszeit, .pause {
        display: grid;
        justify-items: center;
        align-items: center;
        color: var(--primary-color-5);
    }

    .arbeitszeit, .pause {
        width: 5rem;
        height: 2rem;
        border-radius: 1.375rem;
        color: var(--accent-colors-blue);
        background-color: var(--primary-color-2);
    }

    .worklog-footer {
        display: flex;
        align-items: center;
        margin-right: 8rem;
    }

    .footer-text {
        margin-left: 1rem;
        color: var(--primary-color-5);
    }

    .color-dot-worklog {
        height: 0.625rem;
        width: 1rem;
    }

    .worklog-status-private td {
        background-color: var(--primary-color-1);
    }

    .worklog-status-open td {
        background-color: var(--signal-light-yellow)
    }

    .worklog-status-success td {
        background-color: var(--signal-light-green);
    }

    .worklog-status-failure td {
        background-color: var(--signal-light-red);
    }

    .worklog-table-head {
        bottom: 0;
        border-bottom: 0.1rem solid var(--primary-color-3);
        border-top: 0.1rem solid var(--primary-color-3);
    }

    .co-workers-div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px;
    }

    .co-workers {
        display: inline-flex;
        gap: 5px;
    }

    .worklog-table-row-edit-delete {
        background-color: var(--primary-color-1);
        text-align: center;
    }

    .worklog-table-row-project-costCenter-border {
        display: flex;
        align-items: center;
    }
</style>
