<script>
    import StatisticHeader from "./controllers/StatisticHeader.svelte";
    import {user} from "./stores/user.js";
    import {calendarWeek} from "./util/date-time";
    import {storeProjectId} from "./stores/statistic";
    import {selectedDate} from "./stores/selectedDate";
    import DefaultStatisticPage from "./controllers/DefaultStatisticPage.svelte";
    import UserStatisticTab from "./controllers/UserStatisticTab.svelte";
    import ProjectStatisticTab from "./controllers/ProjectStatisticTab.svelte";
    import DivisionStatistic from "./controllers/DivisionStatistic.svelte";
    import GraphStatistic from "./controllers/GraphStatistic.svelte";

    export let userId;
    export let tab;

    user.set({id: userId,});


    export let date = "";
    selectedDate.set(new Date(date))
    export let week
    calendarWeek.set(week)
    export let projectId
    storeProjectId.set(projectId)

    const webpages = [
        {name: "Allgemeine Statistik", component: DefaultStatisticPage},
        {name: "Mitarbeiter", component: UserStatisticTab},
        {name: "Projekte", component: ProjectStatisticTab},
        {name: "Kostenstellengruppen", component: DivisionStatistic},
        {name: "Gewinn der letzten 12 Monate", component: GraphStatistic},
    ];

</script>

<StatisticHeader {tab} {webpages}/>

