<script>
    export let ticketNumbers = [];
    export let missingTicketNr = false;
    export let disabled = false;

    let duplicates = [];
    let focused = false


    function getTicketNr(e) {
        let input = e.currentTarget.value.replace(/\s|\D/g, '');

        if (!input)
            return;

        checkTicketNr();

        ticketNumbers = [input]
    }

    function checkTicketNr() {
        const inputElement = document.querySelector('.worklog-modal-half.ticket-input')
        if (missingTicketNr) {
            ticketNumbers = [];
            inputElement.disabled = true
        } else {
            inputElement.disabled = false
        }
    }
    
    document.addEventListener('keydown', function(event) {
        if (focused && event.key === 'Enter') {
            missingTicketNr = !missingTicketNr
            checkTicketNr()
        }
    })

</script>

<div>
    <label class="{disabled ? 'worklog-modal-label disabled':'worklog-modal-label'} font-regular" for="ticket">Ticket</label>
    <input class="{ disabled ? 'worklog-modal-half ticket-input disabled' : 'worklog-modal-half ticket-input'} font-regular font-size-default" type="number" id="ticket"
           on:blur={getTicketNr} bind:value={ticketNumbers} disabled={disabled || missingTicketNr}
    />
</div>
<label class="checkbox">
    <input type="checkbox" class="{ disabled? 'ticket-number-checkbox disabled':'ticket-number-checkbox'}" bind:checked={missingTicketNr}
           on:change={checkTicketNr} on:focus={() => focused = true} on:blur={() => focused = false} {disabled}/>
    <span class="{disabled? 'checkbox-frame disabled' : 'checkbox-frame'} {missingTicketNr ? 'checked' : ''} {focused ? 'focused' : ''}">
        <svg class="checkmark {disabled ? 'checked' : ''}{missingTicketNr ? 'checked' : ''}"><use xlink:href="/images/icons/accept.svg#Ebene_1"/></svg>
    </span>
</label>


<style>
    .worklog-modal-half.ticket-input:disabled {
        background-color: var(--primary-color-1);
        border: 2px solid var(--primary-color-2);
        color: transparent;
    }

    .checkbox input {
        opacity: 0;
        height: 0;
        width: 0;
        cursor: pointer;
    }

    .checkbox {
        z-index: 1;
        margin-left: -1.5rem;
    }

    .checkbox-frame {
        border: 1px solid var(--primary-color-5);
    }

    .checkbox-frame.focused {
        outline: #428df5 solid;
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .worklog-modal-label {
        color: var(--main-text-color);
    }
</style>