import {apiUrl} from "./environment";
import {writable} from "svelte/store";

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})

export const yieldEntries = writable([])

export async function createProjectYield(data) {
    try {
        validateFields(data)

        const response = await fetch(`${baseUrl}/config/create_project_yield`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                start_date: data.startDate,
                project: data.projectId,
                yield: data.yield
            })
        });

        if (!response.ok) {
            const error = await response.json();
            throw error;
        }
    } catch (error) {
        throw error;
    }
}

export async function updateProjectYield(data, iri) {
    try {
        validateFields(data)

        const response = await fetch(`${baseUrl}/config/update_project_yield`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                iri: iri,
                start_date: data.startDate,
                yield: data.yield,
            })
        });

        if (!response.ok) {
            const error = await response.json();
            throw error;
        }
    } catch (error) {
        throw error;
    }
}

export async function fetchProjectYields(projectId) {
    const url = `${baseUrl}/api/project_yields?page=1&project=${projectId}`

    const response = await fetch(url);
    const responseData = await response.json();

    yieldEntries.set(responseData['hydra:member'])
    return responseData
}

function validateFields(data) {
    if (!data.startDate) throw 'Bitte Startdatum angeben.'

    if (!data.yield) throw 'Bitte Ertrag pro Stunde angeben.'
}