export function validateCreateTask(task) {
    if (!task.startTime) throw "Bitte Startzeit eingeben"

    if (!task.project) throw "Bitte Projekt auswählen"

    if (!task.costCenter) throw "Bitte Kostenstelle auswählen"

    if (!task.tickets || !task.tickets.length && !task.missingTicketNr) throw "Bitte Ticketnummer eingeben"

    if (!task.title) throw "Bitte Titel eingeben"
}
export const validateUpdateTask = validateCreateTask;

export function validateCreateSubtask(subtask) {
    if (!subtask.durationMills) throw "Bitte Dauer eingeben"

    if (!subtask.project) throw "Bitte Projekt auswählen"

    if (!subtask.costCenter) throw "Bitte Kostenstelle auswählen"

    if (!subtask.tickets ||!subtask.tickets.length && !subtask.missingTicketNr) throw "Bitte Ticketnummer eingeben"

    if (!subtask.title) throw "Bitte Titel eingeben"
}

export const validateUpdateSubtask = validateCreateSubtask;
