<script>
    import {onMount} from "svelte";
    import {selectedDate} from "../stores/selectedDate";
    import {fetchArchivedWorklogs, archivedWorklogs, getArchivedTasks, archivedSubtasks, restore} from "../stores/archive";
    import {fetchCostCenters, costCenters} from "../stores/costCenters";
    import {fetchProjects, projects} from "../stores/projects";
    import {fetchUsers, users} from "../stores/users";
    import {formatTimeInTimeZone, millisecondsToHours} from "../util/date-time";
    import RestoreConfirmationModal from "./RestoreConfirmationModal.svelte";

    onMount(async () => {
        await fetchCostCenters()
        await fetchProjects()
        await fetchUsers()
        await fetchArchivedWorklogs($selectedDate)
        await getArchivedTasks($archivedWorklogs)
    })

    async function restoreWorklog(worklogId) {
        const params = {
            start: '',
            end: '',
            id: worklogId
        }


        try {
            await restore(params)
            await fetchArchivedWorklogs($selectedDate)
        }  catch (error) {
            errorMessage = error;
        }
    }

    let sortBy = {col: "startTime", ascending: true};
    function sortTable(column) {
        if (sortBy.col === column) {
            sortBy.ascending = !sortBy.ascending
        } else {
            sortBy.col = column
            sortBy.ascending = true
        }

        let sortModifier = (sortBy.ascending) ? 1 : -1;


        let sort = function(a, b) {
            if (a[column] < b[column]) {
                return -1 * sortModifier;
            } else if (a[column] > b[column]) {
                return 1 * sortModifier;
            } else {
                return 0;
            }
        };


        $archivedWorklogs = $archivedWorklogs.reverse();
    }
</script>



<div class="archive-table">
    <table class="archive-main-table">
        <thead class="archive-table-head font-medium font-size-medium">
        <tr>
            <th class="archive-table-head-data" on:click={() => sortTable("startTime")}>Zeit</th>
            <th class="archive-table-head-data" on:click={() => sortTable("project")}>Projekt</th>
            <th class="archive-table-head-data" on:click={() => sortTable("costCenter")}>Kostenstelle</th>
            <th class="archive-table-head-data" on:click={() => sortTable("title")}>Titel</th>
            <th class="archive-table-head-data" on:click={() => sortTable("user")}>Ersteller</th>
        </tr>
        </thead>
        <tbody class="archive-table-body font-regular font-size-default">
        {#each $archivedWorklogs as archivedWorklog}
            <tr class="archive-table-row-task">
                <td class="archive-table-cell start-time">
                    {formatTimeInTimeZone(archivedWorklog.startTime)}
                </td>
                <td class="archive-table-cell project">
                    {#each $projects as project}
                        {#if archivedWorklog.project === project['@id']}
                            {project.name}
                        {/if}
                    {/each}
                </td>
                <td class="archive-table-cell costcenter">
                    {#each $costCenters as costCenter}
                        {#if archivedWorklog.costCenter === costCenter['@id']}
                            {costCenter.name}
                        {/if}
                    {/each}
                </td>
                <td class="archive-table-cell title">
                    {archivedWorklog.title}
                </td>
                <td class="archive-table-cell creator">
                    {#each $users as user}
                        {#if archivedWorklog.user === user['@id']}
                            {user.name}
                        {/if}
                    {/each}
                </td>
                <td class="archive-table-cell restore">
                    <RestoreConfirmationModal on:submit={restoreWorklog(archivedWorklog.id)}/>
                </td>
            </tr>
            {#each $archivedSubtasks as subtask}
                {#if subtask.parent === archivedWorklog['@id']}
                    <tr class="archive-table-row-subtask">
                        <td>
                            {millisecondsToHours(subtask.durationMills)}
                        </td>
                        <td class="archive-table-cell project">
                            {#each $projects as project}
                                {#if subtask.project === project['@id']}
                                    {project.name}
                                {/if}
                            {/each}
                        </td>
                        <td class="archive-table-cell costcenter">
                            {#each $costCenters as costCenter}
                                {#if subtask.costCenter === costCenter['@id']}
                                    {costCenter.name}
                                {/if}
                            {/each}
                        </td>
                        <td class="archive-table-cell title">
                            {subtask.title}
                        </td>
                        <td>
                        </td>
                    </tr>
                {/if}
            {/each}
            <tr class="archive-table-blank-row">
                <td colspan="5"></td>
            </tr>
        {/each}
        </tbody>
    </table>
</div>

<style>
    .archive-main-table {
        width: 100%;
        position: relative;
        bottom: 8rem;
    }

    .archive-table-cell {
        text-align: center;
    }

    .archive-table-blank-row {
        height: 1rem;
        border-top: none;
    }

    .archive-table-row-task {
        border-bottom: none;
        height: 5vh;
    }

    .archive-table-row-subtask {
        border: none;
    }
</style>