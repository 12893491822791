<script>
    import Modal from '../util/Modal.svelte';
    import {mainProjects, subProjects, visibleProjects} from "../stores/projects";
    import {costCenters} from "../stores/costCenters";
    import {users} from "../stores/users";
    import DropdownMenu from "../util/DropdownMenu.svelte";
    import {onMount} from "svelte";
    import {roles, fetchUserRoles} from "../stores/roles.js";
    import {user} from "../stores/user.js";
    import Calendar from "./Calendar.svelte";


    let showCalendar = false
    let showModal = false;
    let worklog = {
        project: null,
        costCenter: null,
        user: null
    };
    let timePeriods = [{name: 'Tag', period: 'day'},
        {name: 'Woche', period: 'week'},
        {name: 'Monat', period: 'month'},
        {name: 'Quartal', period: 'quarter'},
        {name: 'Jahr', period: 'year'}]

    let selectedDate
    let selectedPeriod = timePeriods[1]
    let selectedUser;
    let selectedProject;
    let selectedCostCenter;
    let currentFirst;
    let currentLast;
    let year;
    let month;
    let firstDay;
    let start;
    let end;
    let formattedStart;
    let formattedEnd;
    let ticketNumber;
    let current = new Date();
    let exportFormatOptions = [{id: 1, name: 'CSV', format: 'csv'}, {id: 2, name: 'XLSX', format: 'xlsx'}, {
        id: 3,
        name: 'XLSX (Rechnung)',
        format: 'xlsx (Rechnung)'
    }];
    let exportFormat = exportFormatOptions[0]; // Default export format
    let showMainProjects = false;
    let filteredProjects = $visibleProjects
    const options = {weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'};
    let disabledFields = false
    let errorMessage;
    let isCollectionUpdated = false;

    function resetToday(period) {
        current = new Date()

        switchPeriods(period)
    }

    function switchPeriods(period) {
        if (period === 'Tag') {
            getToday();
        } else if (period === 'Woche') {
            startOfWeek();
        } else if (period === 'Monat') {
            startOfMonth();
        } else if (period === 'Quartal') {
            startOfQuarter();
        } else if (period === 'Jahr') {
            startOfYear();
        }
    }

    function shiftDays(shift) {
        current = new Date(start);
        let day = current.getDate() + shift;
        start = new Date(current.setDate(day));
        end = new Date(current.setDate(day));
        dateFormat(start, end);
    }

    function shiftWeeks(shift) {
        current = new Date(start);
        firstDay = current.getDate() - current.getDay() + shift;
        start = new Date(current.setDate(firstDay));
        let lastDay = current.getDate() + 6;
        end = new Date(current.setDate(lastDay));
        dateFormat(start, end);
    }

    function shiftQuarter(shift) {
        current = new Date(start);
        year = current.getFullYear();
        month = current.getMonth() + shift;
        if (month > 11) {
            month = 0;
            year += 1;
        }
        if (month < 0) {
            month = 11;
            year -= 1;
        }
        getQuarter(year, month);
    }

    function shiftMonth(shift) {
        current = new Date(start);
        year = current.getFullYear();
        month = current.getMonth() + shift;
        start = new Date(year, month, 1);
        end = new Date(year, month + 1, 0);
        dateFormat(start, end);
    }

    function shiftYear(shift) {
        current = new Date(start);
        year = current.getFullYear() + shift;
        start = new Date(year, 0, 1);
        end = new Date(year + 1, 0, 1);
        dateFormat(start, end);
    }

    function getToday() {
        start = current;
        end = current;
        dateFormat(start, end);
    }

    function startOfWeek() {
        currentFirst = current.getDate() - current.getDay() + 1;
        currentLast = currentFirst + 6;
        start = new Date(current.setDate(currentFirst));
        end = new Date(current.setDate(currentLast));
        dateFormat(start, end);
    }

    function startOfMonth() {
        year = current.getFullYear();
        month = current.getMonth();
        start = new Date(year, month, 1);
        end = new Date(year, month + 1, 0);
        dateFormat(start, end);
    }

    function startOfQuarter() {
        year = current.getFullYear();
        month = current.getMonth();
        getQuarter(year, month);
    }

    function startOfYear() {
        year = current.getFullYear();
        start = new Date(year, 0, 1);
        end = new Date(year + 1, 0, 0);
        dateFormat(start, end);
    }

    function getQuarter(year, month) {
        let firstMonth;
        let lastMonth;

        if (month >= 0 && month <= 2) {
            firstMonth = 0;
            lastMonth = 3;
        } else if (month >= 3 && month <= 5) {
            firstMonth = 3;
            lastMonth = 6;
        } else if (month >= 6 && month <= 8) {
            firstMonth = 6;
            lastMonth = 9;
        } else if (month >= 9 && month <= 11) {
            firstMonth = 9;
            lastMonth = 0;
        }

        firstDay = new Date(year, firstMonth, 1);
        start = new Date(year, month, 1);

        end = new Date(year, lastMonth, 0);

        if (month >= 9 && month <= 11) {
            end = new Date(year + 1, lastMonth, 0);
        }
        dateFormat(start, end);
    }

    function shift(prefix, period) {
        let weekMultiplier = prefix > 0 ? 8 : 6;

        if (period === 'Tag') {
            shiftDays(1 * prefix);
        } else if (period === 'Woche') {
            shiftWeeks(weekMultiplier * prefix);
        } else if (period === 'Monat') {
            shiftMonth(1 * prefix);
        } else if (period === 'Quartal') {
            shiftQuarter(3 * prefix);
        } else if (period === 'Jahr') {
            shiftYear(1 * prefix);
        }
    }

    function dateFormat(start, end) {
        formattedStart = new Date(start).toLocaleDateString('de-DE', options);
        formattedEnd = new Date(end).toLocaleDateString('de-DE', options);
    }

    function exportDateFormat(date) {
        let splitDate = date.split(', ')[1].split('.');
        let y = splitDate[2];
        let m = splitDate[1];
        let d = splitDate[0];

        return `${y}-${m}-${d}`;
    }

    function redirect() {
        try {
            if (ticketNumber && !selectedProject) {
                throw("Bitte zusätzlich zur Ticketnummer ein Projekt auswählen");
            }

            if (!worklog.user && selectedUser) {
                worklog.user = selectedUser.id
            }

            let newDateFormat = exportDateFormat(formattedStart);
            const projectId = worklog.project != null ? '&project=' + worklog.project : '';
            const userId = worklog.user != null ? '&user=' + worklog.user : '';
            const kostId = worklog.costCenter != null ? '&costcenter=' + worklog.costCenter : '';
            const numberTicket = ticketNumber != null ? '&ticketNumber=' + ticketNumber : '';
            const dateTime = '&date=' + newDateFormat;
            const timePeriod = '&period=' + selectedPeriod.period;
            const exportFormatParam = '&format=' + exportFormat.format;
            const hasSubProjects = showMainProjects ? '&hassubprojects=true' : '';
            
            window.location.href = '/worklog?' + hasSubProjects + projectId + userId + kostId + numberTicket + dateTime + timePeriod + exportFormatParam;

            showModal = false;
        } catch (error) {
            errorMessage = error;
        }
    }

    function handleSelect() {
        current = new Date(selectedDate)
        switchPeriods(selectedPeriod.name)
        showCalendar = false
    }


    function changeProjectCollection() {
        worklog.project = null
        selectedProject = null

        if (showMainProjects) {
            filteredProjects = $mainProjects
        } else {
            filteredProjects = $subProjects
        }
        isCollectionUpdated = true
    }

    function selectProject() {
        worklog.project = selectedProject.id;
    }

    function deleteSelection() {
        selectedProject = null;
        worklog.project = null;
    }

    function resetFields() {
        ticketNumber = null;
        selectedCostCenter = null;
        selectedProject = null;
        exportFormat = null;
        selectedUser = null;
        worklog.project = null
        worklog.costCenter = null
        worklog.user = null
        selectedPeriod = timePeriods[1];
        exportFormat = exportFormatOptions[0]
        errorMessage = "";
        showMainProjects = false
    }

    $: if (!showModal) {
        resetFields();
        isCollectionUpdated = false
    }

    onMount(() => {
        startOfWeek();
        fetchUserRoles()
    })

    $: {
        if (showModal && !isCollectionUpdated) {
            changeProjectCollection()
            disableSelection(selectedUser)
        }
    }

    function disableSelection() {
        if (!$roles.some(role => role.code === "ROLE_EXPORTER")) {
            selectedUser = $users.find(u => u.id === $user.id)
            disabledFields = true
            exportFormatOptions = [{id: 1, name: 'CSV', format: 'csv'}, {id: 2, name: 'XLSX', format: 'xlsx'}]
        }
    }

    function handleOpenModal() {
        showModal = true
        current = new Date()
        startOfWeek();
    }


</script>

<style>
    .headline {
        font-size: 22px;
        color: var(--primary-color-5);
    }

    .info1 {
        color: var(--primary-color-4);
    }

    .info2 {
        color: var(--primary-color-4);
        background-color: var(--primary-color-2);
        border-radius: 0.2rem;
        padding-inline: 1rem;
        display: flex;
        margin: 2rem 0 1.5rem 0;
        height: 4rem;
        align-items: center;
        vertical-align: middle;
    }

    .modalfield {
        padding-bottom: 1.5rem;
    }

    .timewrapper {
        display: flex;
        margin: 0 0.5rem 0 0.5rem;
    }

    .export {
        color: #FFFFFF;
        background-color: var(--accent-colors-blue);
        width: 100%;
        border: 1px solid var(--accent-colors-blue);
        border-radius: 3px;
    }

    .infoSvg {
        fill: var(--accent-colors-blue);
        height: 2.3rem;
        width: 2.3rem;
        margin: 0.5rem 1rem 0.5rem 0.5rem;
    }

    .todaySvg {
        fill: var(--signal-green);
        height: 1rem;
        width: 1rem;
        margin-bottom: 0.2rem;
        margin-right: 0.2rem;
    }

    .export-button:hover {
        background-color: var(--accent-colors-blue);
    }

    .export-icon {
        fill: var(--primary-color-5);
        width: 1rem;
        height: 1rem;
        align-items: center;
    }

    .export-modal-date {
        background-color: var(--primary-color-1);
        border: none;
        text-align: center;
        width: 14.6rem;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
    }

    .select-time-div {
        display: flex;
        flex-wrap: wrap;
    }

    .checkbox input {
        opacity: 0;
        height: 0;
        width: 0;
        cursor: pointer;
    }

    .checkbox-frame {
        border: 1px solid var(--primary-color-5);
        height: 1rem;
        width: 1rem;
    }

    .checkbox {
        display: flex;
        position: absolute;
        left: 5rem;
        align-items: center;
    }

    .export-date-arrow:hover, .export-modal-today:hover {
        background-color: var(--primary-color-3);
    }

    .export-modal-labels,
    .export-date-arrow {
        color: var(--primary-color-5);
    }

    .modalfield.format-ticket {
        display: flex;
        gap: 3rem;
    }

    .modal-field-column {
        width: 50%;
    }

    .ticket-number-field {
        background-color: var(--primary-color-2);
        border: none;
        border-radius: 0.2rem;
        width: 90%;
        height: 2.4rem;
        padding-inline: 0.5rem;
    }

    .modal-error-message {
        display: none;
    }

    .modal-error-message[data-error] {
        background-color: var(--signal-light-red);
        display: flex;
        height: 2.5rem;
        align-items: center;
        justify-content: center;
        width: auto;
        text-align: center;
        padding-inline: 1rem;
    }

    .modal-error-message-icon {
        height: 1rem;
        width: 1rem;
        fill: var(--signal-red);
        left: 2rem;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>
<div class="worklog-export">
    <button class="export-button font-bold button-effect-2" on:click={handleOpenModal}>
        <svg class="export-icon">
            <use xlink:href="/images/icons/export.svg#Ebene_1">
            </use>
        </svg>
        Exportieren...
    </button>
</div>

{#if showModal}
    <Modal bind:showModal>
        <h1 class="headline font-bold">
            Exportieren
        </h1>
        <p class="info1 font-regular font-size-small">Bitte geben Sie weitere Informationen zum gewünschten Exportformat an.</p>
        {#if errorMessage}
            <div class="modal-error-message mb-2 rounded-border-1 font-regular font-size-default" data-error={errorMessage}>
                <svg class="modal-error-message-icon mr-1">
                    <use xlink:href="/images/icons/info.svg#Ebene_1"/>
                </svg>
                {errorMessage}
            </div>
        {/if}
        <div class="info2 font-regular font-size-default">
            <svg class="infoSvg">
                <use xlink:href="/images/icons/info.svg#Ebene_1"></use>
            </svg>
            <p class="pt-3">
                Die Worklogs werden für den Zeitraum von <strong
                    class="bolddate font-semi-bold">{formattedStart}</strong> bis
                <strong class="bolddate font-semi-bold">{formattedEnd}</strong> exportiert.
            </p>
        </div>
        <div class="modalfield">
            <div class="select-time-div" id="time">
                <DropdownMenu bind:selectedItem={selectedPeriod}
                              bind:dropdownItems={timePeriods}
                              on:select={() => resetToday(selectedPeriod.name, 'a')}
                              mode="time" collection="Zeitraum">
                    <div slot="reset-selection"></div>
                    <div slot="dropdown-search-filter"></div>
                    <div slot="dropdown-item-color"></div>
                    <div slot="dropdown-item-code"></div>
                </DropdownMenu>

                <div class="timewrapper font-size-default">
                    <button class="export-date-arrow fa-solid fa-chevron-left bg-primary-2 text-center rounded-border-start button-effect-1 border-none"
                            on:click={() => shift(-1, selectedPeriod.name)}></button>
                    <input class="export-modal-date font-semi-bold" bind:value={formattedStart} on:mousedown={() => showCalendar = true}/>
                    <button class="export-date-arrow fa-solid fa-chevron-right bg-primary-2 text-center rounded-border-end button-effect-1 border-none"
                            on:click={() => shift(1, selectedPeriod.name)}></button>
                </div>
                <button class="export-modal-today button-effect-1 font-semi-bold border-none"
                        on:click={() => resetToday(selectedPeriod.name, 'b')}>
                    <svg class="todaySvg">
                        <use xlink:href="/images/icons/today.svg#Ebene_1"></use>
                    </svg>
                    Heute
                </button>
            </div>
        </div>
        <div class="modalfield format-ticket export-modal-labels">
            <div class="modal-field-column">
                <label for="exampleDataList" class="ticket-label font-regular font-size-default">Ticket</label>
                <input id="exampleDataList" class="ticket-number-field"  type="number" bind:value={ticketNumber}>
            </div>
            <div class="modal-field-column">
                <DropdownMenu
                        bind:selectedItem={exportFormat}
                        bind:dropdownItems={exportFormatOptions}
                        mode="export" collection="Format">
                    <div slot="reset-selection"></div>
                    <div slot="dropdown-search-filter"></div>
                    <div slot="dropdown-item-color"></div>
                    <div slot="dropdown-item-code"></div>
                </DropdownMenu>
            </div>
        </div>
        <div class="modalfield export-modal-labels">
            <label class="checkbox font-regular">
                <input type="checkbox" class="ticket-number-checkbox" bind:checked={showMainProjects}
                       on:change={changeProjectCollection}/>
                <span class="checkbox-frame {showMainProjects ? 'checked' : ''}">
                    <svg class="checkmark {showMainProjects ? 'checked' : ''}">
                        <use xlink:href="/images/icons/accept.svg#Ebene_1"/>
                    </svg>
                </span>
                Übergeordnete Projekte anzeigen
            </label>
            <DropdownMenu bind:selectedItem={selectedProject}
                          bind:dropdownItems={filteredProjects}
                          on:select={selectProject}
                          on:delete={deleteSelection}
                          mode="export" collection="Projekt">
            </DropdownMenu>
        </div>
        <div class="modalfield export-modal-labels">
            <DropdownMenu bind:selectedItem={selectedCostCenter}
                          bind:dropdownItems={$costCenters}
                          on:select={() => worklog.costCenter = selectedCostCenter.id}
                          on:delete={() => worklog.costCenter = null}
                          mode="export" collection="Kostenstelle">
                <div slot="dropdown-item-code"></div>
            </DropdownMenu>
        </div>
        <div class="modalfield export-modal-labels">
            <DropdownMenu bind:selectedItem={selectedUser}
                          bind:dropdownItems={$users}
                          on:select={() => worklog.user = selectedUser.id}
                          on:delete={() => worklog.user = null}
                          mode="export" collection="Benutzer" disabled={disabledFields}>
            </DropdownMenu>
        </div>
        <button class="export font-bold mt-2 p-1 button-effect-2 font-size-medium" on:click="{redirect}">Exportieren</button>
    </Modal>
{/if}

<Calendar bind:showModal={showCalendar} bind:selectedDate on:select={handleSelect} mode="export">
    <div slot="calendar-button"></div>
</Calendar>
