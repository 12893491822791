<script>

    import ReviewPage from "./controllers/ReviewPage.svelte";
    import {user} from "./stores/user";
    import {selectedDate} from "./stores/selectedDate";
    import {calendarWeek} from "./util/date-time";
    import {showReviewsCurrentUser} from "./stores/reviews";

    export let date = "";
    selectedDate.set(new Date(date))
    export let userId = "";
    export let userBillable = "";
    export let username = "";
    export let selectedUser
    export let week
    export let showMyReviews
    showReviewsCurrentUser.set(showMyReviews === 'true')

    calendarWeek.set(week)

    user.set({id: userId,
        billable: userBillable,
        name: username})

</script>

<ReviewPage {selectedUser}/>