import {apiUrl} from "./environment";
import {writable} from 'svelte/store';
import {fetchSubTasksByWorklog} from "./subTasks.js";
import {fetchWorklogs, subtasks} from "./worklogs.js";

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})


export let archivedWorklogs = writable([]);
export let archivedSubtasks = writable([])

export async function create(dateParams) {

    try {
        const response = await fetch(`${baseUrl}/api/archive`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dateParams),
        });
    } catch (error) {
        throw error;
    }
}

export async function restore(params) {
    try {
        const response = await fetch(`${baseUrl}/api/restore`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        })
    } catch (error) {
        throw error
    }
}


export async function fetchArchivedWorklogs(selectedDate) {
    if (!selectedDate)
        selectedDate = date;

    const currentDate = new Date(selectedDate);
    const afterDate = new Date(selectedDate);

    afterDate.setDate(afterDate.getDate() + 1);


    const url = `${baseUrl}/api/worklog_archives?page=1&startTime[before]=${afterDate.toISOString()}&startTime[after]=${currentDate.toISOString()}`;

    const response = await fetch(url);
    const responseData = await response.json();
    const sortedWorklog = sortWorklogs(responseData['hydra:member'])

    archivedWorklogs.set(sortedWorklog);

    return responseData;
}

function sortWorklogs(worklogs) {
    return worklogs.sort((a, b) => {
        return new Date(a.startTime) - new Date(b.startTime)
    })
}

export async function fetchArchivedSubTasksByWorklog(worklogId) {
    try {
        const response = await fetch(`${baseUrl}/api/subtask_archives?page=1&parent=${worklogId}`);
        const data = await response.json();
        return data['hydra:member'];
    } catch (error) {
        throw new Error(error);
    }
}

export async function getArchivedTasks(worklogs) {
    let subtaskArray = []
    if (!worklogs) {
        await fetchWorklogs()
    }
    for (const task of worklogs) {
        let subtask = await fetchArchivedSubTasksByWorklog(task.id)
        subtaskArray.push(...subtask)
    }
    archivedSubtasks.set(subtaskArray)
}