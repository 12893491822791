import {writable} from "svelte/store";
import {apiUrl} from "./environment";

export const reasons = writable([]);

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})

export async function fetchReasons() {
    try {
        const response = await fetch(`${baseUrl}/api/reasons`);
        const data = await response.json();
        reasons.set(data['hydra:member']);
    } catch(error) {
        throw new Error(error);
    }
}

