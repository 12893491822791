import {getHoursAndMinutes} from "./date-time";

export function formatTimeInput(e) {
    let time = e.currentTarget.value;
    let hours;
    let minutes;

    time = deleteNonNumericChars(time);

    if (time.length > 1 && time.indexOf(':') === -1) {
        if ('3456789'.indexOf(time[0]) !== -1 || (time[0] === '2' && '456789'.indexOf(time[1]) !== -1)) {
            hours = checkHours(time[0]);
            minutes = checkMinutes(time.substring(1));
        } else {
            hours = checkHours(time.substring(0, 2));
            minutes = checkMinutes(time.substring(2));
        }

        if (minutes === '00' && e.data === '0' && time.length > 2) {
            minutes = '0';
        }

        time = hours + ':' + minutes;
    }

    if (e.inputType !== 'deleteContentBackward') {
        e.currentTarget.value = time;
        e.currentTarget.selectionStart = getSelectionStart(time);
        e.currentTarget.selectionEnd = time.length;
    }
}

export function formatDurationInput(value) {
    let duration = value.replace(/[^0-9hm]+/g, '')


    let [hours, minutes] = getHoursAndMinutes(duration)

    while (minutes >= 60) {
        minutes -= 60
        hours += 1
    }

    return hours === 0 ? minutes + 'm' : hours + 'h' + (minutes > 0 ? minutes + 'm' : '')
}

export function deleteNonNumericChars(input) {
    return input.replace(/\s|\D/g, '');
}

export function checkTimeInputLength(value) {
    let time = deleteNonNumericChars(value)


    if (time.length === 1) {
        time = checkHours(time[0])
    }

    while (time.length < 4) {
        time += "0";
    }

    return time.substring(0, 2) + ':' + time.substring(2);
}

function checkMinutes(minute) {
    if (minute > 59 || minute.length === 0 || minute === '00') {
        return `00`;
    }

    if (minute < 6 && minute.length < 2) {
        return `${minute}0`;
    } else if (minute < 10 && minute.length < 2) {
        return `0${minute}`;
    } else {
        return minute;
    }
}

function checkHours(hour) {
    if (hour > 9 || (hour.length === 2 && hour[0] === '0')) {
        return hour;
    } else if (hour.length === 1 && hour < 3){
        return `${hour}0`;
    } else {
        return `0${hour}`;
    }
}

function getSelectionStart(time) {
    if (time.substring(3) === '0') {
      return time.length;
    } else if (time.substring(3) === '00') {
        return time.length - 2;
    } else if (time.indexOf('0', 2) === 4) {
        return time.length - 1;
    } else if (time.indexOf('0', 2) === -1 || time[4] > 0) {
        return time.length;
    }
}