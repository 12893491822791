import {filterSubtasks} from "../stores/subTasks.js";
import {writable} from "svelte/store";

export let monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
];

export let dayNames = [
    "So",
    "Mo",
    "Di",
    "Mi",
    "Do",
    "Fr",
    "Sa"
]

export let previousMonthDays = {
    "Di": 0,
    "Mi": 1,
    "Do": 2,
    "Fr": 3,
    "Sa": 4,
    "So": 5
}

export let nextMonthDays = {
    "Mo": 6,
    "Di": 5,
    "Mi": 4,
    "Do": 3,
    "Fr": 2,
    "Sa": 1
}

export const calendarWeek = writable('');
export const needsUpdate = writable(false)

const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const isLeapYear = year => year % 4 === 0;
const getEmptyRows = () => {
    return Array.from({length: 42}).map(() => []);
};
export let getMonthDays = (index, year) => {
    return index !== 1 ? monthDays[index] : isLeapYear(year) ? 29 : 28;
};

const getMonthStats = (monthIndex, year) => {
    const today = new Date(year, monthIndex, 1);
    const index = today.getMonth();
    return {
        name: index[index],
        days: getMonthDays(index, year)
    };
};

export const getMonthName = index => monthNames[index];

export const getDateRows = (monthIndex, year) => {
    const {days} = getMonthStats(monthIndex, year);
    const rows = getEmptyRows();
    const startIndex = new Date(year, monthIndex, 1).getDay();
    Array.from({length: days}).forEach((_, i) => {
        const index = startIndex + i;
        rows[index] = i + 1;
    });
    const filled = rows.map(i => (Array.isArray(i) ? undefined : i));

    return filled[35] ? filled : filled.slice(0, -7);
};

export function formatWeekdayDate(date) {
    if (!date) return
    let splitDate = date.split('.');
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
}

export function formatWeekdayDateEvent(date) {
    if (!date) return
    let splitDate = date.split('-');
    return `${splitDate[0]}-${splitDate[1]}-${splitDate[2]}`
}

export function getWorkingTime(weekdayDate, worklogs, subTasks, costCenters) {
    const formattedDate = formatWeekdayDate(weekdayDate);
    const filteredWorklogs = worklogs.filter(worklog => worklog.startTime.includes(formattedDate));
    return calculateTotalWorkTime(filteredWorklogs, costCenters, subTasks);
}

export function calculateTotalWorkTime(worklogs, costCenters = [], subtasks) {
    let totalTime = 0;
    let costCenterId = []
    costCenters.forEach(costCenter => {
        if (['Pause', 'Außerhalb der Arbeitszeit'].includes(costCenter.name)) {
            costCenterId.push(costCenter['@id']);
        }
    })

    worklogs.forEach(worklog => {
        if (!costCenterId.includes(worklog.costCenter)) {
            totalTime += worklog.durationMills;
        }
    });
    const filteredSubtasks = filterSubtasks(worklogs, subtasks);
    if (subtasks) {
        filteredSubtasks.forEach(subtask => {
            if (!costCenterId.includes(subtask.costCenter)) {
                totalTime += subtask.durationMills;
            }
        })
    }
    return millisecondsToHours(totalTime);
}

export function formatTimeInTimeZone(dateTimeString) {
    if (dateTimeString.length <= 5) return dateTimeString

    const date = new Date(dateTimeString);
    const options = {
        timeZone: 'UTC',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
    };

    return date.toLocaleString('de-DE', options);
}

export function millisecondsToHours(duration) {
    const durationInHours = duration / 3.6e+6;
    const hours = Math.floor(durationInHours);
    const minutes = Math.round((durationInHours - hours) * 60);

    if (!durationInHours) {
        return '0m';
    }
    return `${hours > 0 ? String(hours) + 'h' : ''}${minutes > 0 ? String(minutes) + 'm' : ''}`
}

export function hoursToMilliseconds(subTask, formattedTime) {
    const duration = formattedTime ? formattedTime : subTask.durationMills

    let [hours, minutes] = getHoursAndMinutes(duration);

    subTask.durationMills = hours * 3.6e+6 + minutes * 6e+4
}

export function getHoursAndMinutes(duration) {
    let hours = 0
    let minutes = 0

    const regex = /^((\d+)?h)?((\d+)?(m)?)?$/;

    const match = duration.match(regex)


    if (match[2]) {
        hours = Number(match[2])
    }

    if (match[4]) {
        minutes = Number(match[4])
    }
    return [hours, minutes]
}

function isSameDay(date1, date2) {
    return date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();

}

export function worklogDate(worklogDate) {
    const currentDate = new Date();
    const logDate = new Date(worklogDate);

    let dateString = logDate.toLocaleDateString('de-DE', {
        weekday: 'long'
    });

    dateString += ", ";

    return dateString + logDate.toLocaleDateString('de-DE', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    })
}

