import {writable} from "svelte/store";
import {apiUrl} from "./environment";

export const users = writable({});

let userArray = users.subscribe((data) => {
    userArray = data
})

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})

export async function fetchUsers() {
    try {
        const response = await fetch(`${baseUrl}/api/users`);
        const data = await response.json();
        users.set(data['hydra:member']);
    } catch(error) {
        throw new Error(error);
    }
}

export function getUserById(userId) {
    return userArray.find(user => user['@id'] === userId);
}

export function getCoWorkers(task) {
    return task.coWorkers.map(coWorker => coWorker['@id']);
}