<script>
    import Modal from '../util/Modal.svelte';
    import {createEventDispatcher} from 'svelte';
    import {fetchProjects, visibleProjects} from "../stores/projects"
    import {fetchColors, colors} from "../stores/colors"


    let showModal = false;
    const dispatch = createEventDispatcher();
    let selectedProject;

    function selectProject(project) {
        if (!project) {
            selectedProject = null;
        } else {
            selectedProject = project;
        }
        dispatch('projectSelected', {selectedProject: project});
        showModal = false;
    }

    function findColors(id) {
        let cId = $colors.find(color => color['@id'] === id);
        return cId.code;
    }

    $: {
        fetchColors();

        if (showModal) {
            fetchProjects();
        }
    }
</script>

<div class="choose-project">
    <button class="choose-project-button font-bold font-size-medium button-effect-2" on:click={() => showModal = true}>
        {#if !selectedProject}
            Projekt wählen...
        {:else}
            {selectedProject.name}
        {/if}
        <svg class="review-arrow_down-icon">
            <use xlink:href="/images/icons/arrow_down.svg#Ebene_1">
            </use>
        </svg>
    </button>
</div>

{#if showModal}
    <Modal bind:showModal>
        <div class="project-modal">
            <p class="project-headline font-bold">
                Projektauswahl
            </p>
            <p class="project-modal-info font-medium font-size-default">
                Bitte wählen Sie ein Projekt aus, nach welchem die Reviews gefiltert werden sollen.
            </p>
            <div class="project-list-wrapper">
                <ul class="project-list">
                    <li on:mousedown={() => selectProject()}
                        style="justify-content: center"

                        class="project-list-item project-list-title {!selectedProject ? 'selected' : ''} font-semi-bold font-size-large">Alle anzeigen
                    </li>
                    {#each $visibleProjects as project (project.id)}

                        <li on:mousedown={() => selectProject(project)}
                            class="project-list-item {selectedProject && selectedProject.id === project.id ? 'selected' : ''} font-semi-bold font-size-large">
                            <svg class="project-color-dot" style="fill: {findColors(project.color)}">
                                <use xlink:href="/images/icons/color_dot.svg#Ebene_1"></use>
                            </svg>
                            {project.name}
                            <small class="project-list-code font-regular font-size-large">
                                {project.code}
                            </small>
                        </li>
                    {/each}
                </ul>
            </div>
        </div>
    </Modal>
{/if}

<style>
    .project-color-dot{
        width: 24px;
        height: 24px;
    }
</style>