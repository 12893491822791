<script>
    import BoardOfShame from "./controllers/BoardOfShame.svelte";

    import {user} from "./stores/user";
    import {selectedDate} from "./stores/selectedDate";

    export let date = "";
    selectedDate.set(new Date(date))
    export let userId = "";
    export let userBillable = "";
    export let username = "";

    user.set({id: userId,
        billable: userBillable,
        name: username})

</script>

<BoardOfShame />

