<script>
    import {fetchSubTasks} from "../stores/subTasks.js";
    import DeleteSubtask from "./DeleteSubtask.svelte";
    import EditSubtaskModal from "./EditSubtaskModal.svelte";
    import SubtaskModal from "./SubtaskModal.svelte";

    export let subTask;
    export let worklog;
    export let showModal = false;
    export let selectedUser
    export let worklogs

</script>

<slot>
    <slot name="delete-subtask-action">
        <DeleteSubtask subtask={subTask} worklog={worklog} {worklogs} />
    </slot>
    <EditSubtaskModal selectedUser={selectedUser} {subTask} {worklog} {worklogs} bind:showModal>
    </EditSubtaskModal>
</slot>
<SubtaskModal selectedUser={selectedUser} worklogId={worklog['@id']}/>
