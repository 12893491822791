<script>
    import ArchiveModal from "./ArchiveModal.svelte";
    import {restore} from "../stores/archive.js";
    import {createEventDispatcher} from "svelte";


    export let startDate
    export let endDate
    export let showModal = false
    const dispatch = createEventDispatcher()

    function openModal() {
        showModal = true
    }

    function handleModalClose() {
        startDate = null
        endDate = null
        showModal = false
    }

    async function handleRestore() {
        const params = {
            start: startDate,
            end: endDate,
            id: ''
        }

        if (!startDate || !endDate) {
            return
        }

        try {
            await restore(params)
            handleModalClose()
            dispatch("restored")
        } catch (error) {
            throw error;
        }
    }
</script>

<ArchiveModal bind:showModal bind:endDate bind:startDate>
    <p slot="archive-modal-headline" class="archive-modal-headline-text font-semi-bold font-size-large">Worklogs wiederherstellen für den Zeitraum</p>
    <button class="archive-page-buttons button-effect-2 font-regular font-size-medium" slot="button-slot" on:click={() => openModal()}>Wiederherstellen</button>

    <button slot="modal-button" class="archive-modal-button button-effect-2 font-medium font-size-medium" on:click={() => handleRestore()}>Wiederherstellen</button>
</ArchiveModal>