import {writable} from "svelte/store";
import {user} from "./user.js";
import {apiUrl} from "./environment";

export const roles = writable([])
let userId

user.subscribe((data) => {
    userId = data?.id
})

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})

export async function fetchAllRoles() {
    try {
        const response = await fetch(`${baseUrl}/api/roles`);
        const data = await response.json();
        roles.set(data['hydra:member']);
    } catch (error) {
        throw new Error(error)
    }
}

export async function fetchUserRoles() {
        try {
            const response = await fetch(`${baseUrl}/api/roles?page=1&users=${userId}`);
            const data = await response.json();
            roles.set(data['hydra:member']);
        } catch(error) {
            throw new Error(error);
        }
}