<script>
    import WorklogModal from "./WorklogModal.svelte";
    import TimeInput from "../util/TimeInput.svelte";
    import {formatTimeInTimeZone} from "../util/date-time";

    export let worklog = [];
    let showModal = false;
    let formattedTime;

    function handleOpenModal() {
        showModal = true;
        formattedTime = formatTimeInTimeZone(worklog.startTime);
    }

</script>

<button class="worklog-edit-button" id="edit-modal" on:click={handleOpenModal} hidden="hidden">
    <svg class="worklog-edit-icon">
        <use xlink:href="/images/icons/edit.svg#Ebene_1"></use>
    </svg>
</button>

<WorklogModal {worklog} {formattedTime} bind:showModal reviewPage>
    <div slot="action-button-worklog-modal"></div>
    <h1 slot="worklog-modal-header">Task Bearbeiten</h1>
    <div slot="worklog-start-time-slot">
        <TimeInput bind:time={formattedTime} reviewPage/>
    </div>
</WorklogModal>

<style>
    .worklog-edit-button {
        position: relative;
        background-color: var(--primary-color-2);
        border: 0;
        height: 2rem;
        width: 2rem;
        border-radius: 0.2rem;
    }

    .worklog-edit-button:hover {
        background-color: var(--accent-colors-blue);
    }

    .worklog-edit-icon {
        height: 1rem;
        width: 1rem;
        fill: var(--primary-color-5);
    }
</style>
