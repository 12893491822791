<script>
    import {deleteSubtask} from "../stores/subTasks.js";
    import SubmitDeletionModal from "./DeleteConfirmationModal.svelte";
    import {createEventDispatcher} from "svelte";
    import {getTasks} from "../stores/worklogs.js";

    export let subtask;
    export let worklog;
    export let worklogs;
    const dispatch = createEventDispatcher()
    let  showModal = false;
    let deleteSubmit = false;

    function checkSubmit(e) {
        deleteSubmit = e.detail.deleteSubmit;

        if (deleteSubmit) {
            deleteSubtask(subtask.id);
            getTasks(worklogs)
        }
    }
</script>

<button on:click={() => showModal = true} class="worklog-subtask-delete-button button-effect-3" id="delete-task">
    <svg class="worklog-subtask-delete-icon">
        <use xlink:href="/images/icons/delete.svg#Ebene_1"></use>
    </svg>
</button>

<SubmitDeletionModal bind:showModal={showModal} on:submit={checkSubmit} status={worklog.reviewStatus} mode="Subtask"/>

<style>
    .worklog-subtask-delete-button {
        background-color: var(--primary-color-2);
        border: none;
        height: 2rem;
        width: 2rem;
        border-radius: 0.2rem;
        margin-right: 0.6rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .worklog-subtask-delete-button:hover {
        background-color: var(--signal-red);
    }

    .worklog-subtask-delete-icon {
        height: 1rem;
        width: 1rem;
        fill: var(--primary-color-5);
    }
</style>