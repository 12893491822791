<script>
    import WorklogModal from "./controllers/WorklogModal.svelte";
    import Worklog from "./controllers/WorklogPage.svelte";
    import {user} from "./stores/user";
    import {selectedDate} from "./stores/selectedDate";
    import ExportModal from "./controllers/ExportModal.svelte";
    import ReleaseForReviewModal from "./controllers/ReleaseForReviewModal.svelte";
    import ChooseUser from "./controllers/ChooseUser.svelte";
    import {fetchWorklogsByUser, getTasks, worklogs} from "./stores/worklogs";
    import {calendarWeek} from "./util/date-time";

    export let date = "";
    selectedDate.set(new Date(date))
    export let userId = "";
    export let userBillable = "";
    export let username = "";
    export let week
    calendarWeek.set(week)

    user.set({id: userId,
        billable: userBillable,
        name: username})

    export let selectedUser

    async function handleSelected(event) {
        selectedUser = event.detail.selectedUser;
        await fetchWorklogsByUser($selectedDate, selectedUser.id)
        await getTasks($worklogs)

    }
</script>

<div class="modal-container">
    <ExportModal/>

    <ReleaseForReviewModal/>

    <ChooseUser on:userSelected={handleSelected} {selectedUser}>
        <p slot="subtitle" class="user-modal-info font-medium">
            Bitte wählen sie einen Nutzer, dessen Worklogs angezeigt werden sollen
        </p>

        <li slot="show-all-user"></li>
    </ChooseUser>

</div>

<Worklog selectedUser={selectedUser}/>

<WorklogModal selectedUser={selectedUser}/>