<script>
import ArchivePage from "./controllers/ArchivePage.svelte";
import {selectedDate} from "./stores/selectedDate";
import Weekdays from "./controllers/Weekdays.svelte";
import {fetchArchivedWorklogs} from "./stores/archive";
import ArchiveModal from "./controllers/ArchiveModal.svelte";
import ArchiveRestoreModal from "./controllers/ArchiveRestoreModal.svelte";
import {calendarWeek} from "./util/date-time";

export let date = "";
selectedDate.set(new Date(date))

export let week
calendarWeek.set(week)

</script>

<Weekdays page={'archive'}/>

<div class="archive-modal-container">
    <ArchiveModal on:archived={() => fetchArchivedWorklogs($selectedDate)}/>
    <ArchiveRestoreModal on:restored={() => fetchArchivedWorklogs($selectedDate)}/>
</div>

<ArchivePage/>

<style>
    .archive-modal-container {
        justify-content: end;
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        bottom: 11rem;
        margin-right: 3rem;
    }
</style>