<script>
    import {createEventDispatcher} from "svelte";

    export let showModal; // boolean

    let dialog; // HTMLDialogElement

    const dispatch = createEventDispatcher();

    function handleClose() {
        showModal = false;
        dispatch('close')
    }

    $: if (dialog && showModal) dialog.showModal();
</script>

<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<dialog
        bind:this={dialog}
        on:close={handleClose}
>
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div on:click|stopPropagation>
        <slot name="header" />


        <slot />

        <!-- svelte-ignore a11y-autofocus -->
        <div class="close-modal" autofocus on:click={() => dialog.close()}>
            <svg class="close-icon"> <use xlink:href="/images/icons/close.svg#Ebene_1"> </use> </svg>
        </div>
    </div>
</dialog>

<style>
    dialog {
        width: 32rem;
        border-radius: 0.5rem;
        border: none;
        background: var(--primary-color-1);
    }

    dialog::backdrop {
        background: rgba(0, 0, 0, 0.5);
    }
    dialog[open] {
        animation: zoom 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    }

    .close-icon {
        fill: var(--primary-color-5);
        height: 1.25rem;
        width: 1.25rem;
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
    }

    .close-modal {
        cursor: pointer;
        right: 0;
    }
    @keyframes zoom {
        from {
            transform: scale(0.95);
        }
        to {
            transform: scale(1);
        }
    }
    dialog[open]::backdrop {
        animation: fade 0.2s ease-out;
    }
    @keyframes fade {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

</style>
