<script>
    import Modal from '../util/Modal.svelte';
    import {createEventDispatcher} from 'svelte';
    import {users} from "../stores/users"
    import {user} from "../stores/user";
    import {colors} from "../stores/colors";


    let showModal = false;
    const dispatch = createEventDispatcher();
    export let selectedUser;

    function selectUser(user) {
        if (!user) {
            selectedUser = null;
        } else {
            selectedUser = user;
        }
        dispatch('userSelected', {selectedUser: selectedUser});
        showModal = false;
    }

    function resetSelection() {
        selectedUser = $user

        dispatch('userSelected', {selectedUser: selectedUser})
    }

    function findColors(id) {
        let cId = $colors.find(color => color['@id'] === id);
        return cId.code;
    }


</script>

<div class="choose-user">
    <button class="choose-user-button button-effect-2 font-bold font-size-medium" on:click={() => showModal = true}>
        {#if !selectedUser}
            Benutzer wählen...
        {:else}
            {selectedUser.name}
        {/if}
        <svg class="review-arrow_down-icon">
            <use xlink:href="/images/icons/arrow_down.svg#Ebene_1">
            </use>
        </svg>
    </button>
    <slot name="reset-selection-button">
        {#if selectedUser.id !== $user.id}
            <button class="reset-selection ml-1 rounded-border-1 button-effect-1" on:click={resetSelection}>
                <svg class="reset-selection-icon">
                    <use xlink:href="/images/icons/delete.svg#Ebene_1"></use>
                </svg>
            </button>
        {/if}
    </slot>
</div>

{#if showModal && $colors.length}
    <Modal bind:showModal>
        <div class="user-modal">
            <p class="user-headline font-bold">Benutzerauswahl</p>
            <slot name="subtitle">
                <p class="user-modal-info font-medium font-size-default">
                    Bitte wählen Sie einen Nutzer, dessen Reviews angezeigt werden sollen.
                </p>
            </slot>
            <div class="user-list-wrapper">
                <ul class="user-list">
                    <slot name="show-all-user">
                        <li on:mousedown={() => selectUser()}
                            class="user-list-item {!selectedUser ? 'selected' : ''} font-semi-bold font-size-large"
                            style="justify-content: center">Alle anzeigen
                        </li>
                    </slot>
                    {#each $users as user (user.id)}
                        {#if user.active}
                            <li on:mousedown={() => selectUser(user)}
                                class="user-list-item {selectedUser && selectedUser.id === user.id ? 'selected' : ''} font-semi-bold font-size-large">
                                <svg class="user-list-dot" style="fill: {findColors(user.color)}">
                                    <use xlink:href="/images/icons/color_dot.svg#Ebene_1"></use>
                                </svg>
                                {user.name}
                            </li>
                        {/if}
                    {/each}
                </ul>
            </div>
        </div>
    </Modal>
{/if}

<style>
    .choose-user {
        display: flex;
    }

    .reset-selection {
        background-color: var(--primary-color-2);
        border: none;
        height: 2.5rem;
        width: 2.5rem;
    }

    .reset-selection-icon {
        fill: var(--primary-color-5);
        height: 1.5rem;
        width: 1.5rem;
    }

    .reset-selection:hover .reset-selection-icon {
        fill: var(--signal-red);
    }
</style>