<script>
    import ReviewModal from "./ReviewModal.svelte";
    import WorklogTable from "./WorklogTable.svelte";
    import ChooseProject from "./ChooseProject.svelte";
    import {reasons} from "../stores/reasons.js";
    import {
        fetchReviewWorklogs,
        update,
        worklogs,
        openWorklogs,
        fetchOpenWorklogs,
        subtasks,
        getTasks
    } from "../stores/worklogs";
    import {onMount} from 'svelte';
    import {visibleProjects} from '../stores/projects';
    import {costCenters} from '../stores/costCenters';
    import {colors} from "../stores/colors";
    import {users} from "../stores/users";
    import {fetchReviews, reviews, acceptReview, showReviewsCurrentUser, updateBillable} from "../stores/reviews";
    import Weekdays from "./Weekdays.svelte";
    import {user} from "../stores/user";
    import ChooseUser from "./ChooseUser.svelte";
    import SubTaskTableRow from "./SubTaskTableRow.svelte";
    import {fetchUserRoles, roles} from "../stores/roles.js";
    import {getMyReviews} from "../util/highlightReviewStatus.js";
    import {selectedDate} from "../stores/selectedDate.js";
    import ContextMenu from "../util/ContextMenu.svelte";
    import {handleClick, onKeyUp} from "../util/Shortcut.js";
    import ContextMenuItem from "../util/ContextMenuItem.svelte";
    import EditWorklogReviewer from "./EditWorklogReviewer.svelte";
    import EditSubtaskReviewer from "./EditSubtaskReviewer.svelte";
    import {fetchSubTasksByWorklog} from "../stores/subTasks.js";

    let sortWorklogs = [];
    let reviewId = "";
    export let showModal = false;
    export let showEditModalTask = false;
    export let showEditModalSubtask = false
    export let selectedUser;
    export let selectedProject;
    let showOptions
    let selectedTaskId
    let action
    let reviewPage = true

    onMount(async () => {
        await fetchUserRoles()
        await fetchReviews()
        await filterData();
    });


    async function filterData() {
        if (!$roles.some(userRole => userRole.code.includes("ROLE_REVIEWER"))) return
        await fetchReviewWorklogs();
        sortWorklogs = $worklogs.filter(worklogs => worklogs.reviewStatus === 'open');

        if (selectedUser) {
            filterPostsByUserId();
        }
        if (selectedProject) {
            filterPostsByProjectId();
        }
        if ($showReviewsCurrentUser) {
            filterPostsByCurrentUser();
        }
        await getTasks(sortWorklogs)
    }

    function handleUserSelected(event) {
        selectedUser = event.detail.selectedUser;
        filterData();
    }

    function handleProjectSelected(event) {
        selectedProject = event.detail.selectedProject;
        filterData();
    }

    function filterPostsByUserId() {
        sortWorklogs = sortWorklogs.filter(worklogs => worklogs.user === `/api/users/${selectedUser.id}`);
    }

    function filterPostsByProjectId() {
        sortWorklogs = sortWorklogs.filter(worklogs => worklogs.project === selectedProject['@id']);
    }

    function filterPostsByCurrentUser() {
        sortWorklogs = sortWorklogs.filter(worklog => $reviews.some(review => +review.worklog.split('/')[3] === worklog.id && +review.user.split('/')[3] === $user.id));
    }

    async function handleAcceptClick(worklog) {
        reviewId = $reviews
            .filter(review => review.status === 'open')
            .find(review => review.worklog === worklog['@id'])['@id'];
        await updateWorklog(worklog);
        await acceptReview(reviewId);
        await filterData()
        await weekdayUpdate(worklog)
    }

    async function updateWorklog(worklog) {
        const subtasks = await fetchSubTasksByWorklog(worklog['@id'])
        try {
            const worklogBillable = +document.getElementById(worklog['@id']).querySelector("input").value
            if (isNaN(worklogBillable)) {
                throw new Error("invalid input");
            }
            worklog.billable = worklogBillable;

            for (const subtask of subtasks) {
                subtask.billable = +document.getElementById(subtask['@id']).value
            }
            await updateBillable(worklog, subtasks)
            document.getElementById(worklog['@id']).querySelector("input").value = $user.billable
        } catch (error) {
            throw new Error(error)
        }
    }

    async function weekdayUpdate(worklog) {
        await fetchOpenWorklogs($selectedDate)
        const timeArray = worklog.startTime.split('T')[0].split('-')
        const formattedTime = `${timeArray[2]}.${timeArray[1]}.${timeArray[0]}`
        await getMyReviews(formattedTime, $reviews, $user, $openWorklogs)
    }

    function handleReviewDenied(worklog) {
        filterData()
        weekdayUpdate(worklog)
    }

    function handleOpenContextMenu(event) {
        const detail = event.detail;
        selectedTaskId = detail.selectedElement.getAttribute('task');

        if (selectedTaskId.includes('subtask')) {
            action = 'Subtask bearbeiten'
        } else {
            action = 'Task bearbeiten'
        }
    }
</script>

<svelte:window on:keyup|preventDefault={onKeyUp}/>


<Weekdays page={'reviews'} worklogs={sortWorklogs} {selectedUser}/>

<div class="worklog-date">
    <ChooseProject on:projectSelected={handleProjectSelected} {selectedProject}/>
    <ChooseUser on:userSelected={handleUserSelected} {selectedUser}>
        <div slot="reset-selection-button"></div>
    </ChooseUser>
    <div class="checkbox-wrapper">
        <label class="checkbox-label font-semi-bold font-size-default mt-2">
            <input type="checkbox" id="loggedIn" bind:checked={$showReviewsCurrentUser} on:change={filterData}>
            <span class="checkbox-frame {$showReviewsCurrentUser ? 'checked' : ''}">
                <svg class="checkmark {$showReviewsCurrentUser ? 'checked' : ''}">
                    <use xlink:href="/images/icons/accept.svg#Ebene_1"></use>
                </svg>
            </span>
            Nur mir Zugewiesene</label>
    </div>
</div>

<div class="review-page-table font-regular">
    <WorklogTable filteredWorklogs={sortWorklogs} projects={$visibleProjects} colors={$colors}
                  costCenters={$costCenters}
                  users={$users}
                  let:worklog={worklog} subTasks={$subtasks} selectedUser={selectedUser} {reviewPage}>
        <thead class="review-table-head font-size-medium" slot="worklog-table-header">
        <tr>
            <th class="review-table-head-data">
                <div class="table-head-time">Zeit</div>
            </th>
            <th class="review-table-head-data"></th>
            <th class="review-table-head-data"> Projekt</th>
            <th class="review-table-head-data"> Kostenstelle</th>
            <th class="review-table-head-data"> Worklog</th>
            <th class="review-table-head-data"> Bearbeiter</th>
            <th class="review-table-head-data pl-2"> Abrechenbarkeit</th>
            <th class="review-accept-reject"> Akzeptieren/Ablehnen</th>
        </tr>
        </thead>
        <input class="billable rounded-border-1 font-regular font-size-default" type="number" slot="billable"
               value={$user.billable}>
        <td class="review-table-accept-reject" slot="table-actions">
            <button class="review-accept-button button-effect-4" on:click={() => handleAcceptClick(worklog)}>
                <svg class="accept-icon">
                    <use xlink:href="/images/icons/review_status.svg#Ebene_1">
                    </use>
                </svg>
            </button>
            <ReviewModal {showModal} {worklog} reasons={$reasons}
                         on:reviewDenied={() => handleReviewDenied(worklog)}/>
            <EditWorklogReviewer {worklog} showModal={showEditModalTask}/>
        </td>
        <div slot="worklog-table-footer">

        </div>
        <SubTaskTableRow let:subTask {subTask} let:worklog {worklog} slot="subtasks">
            <td slot="actions" class="worklog-table-edit-delete">
                <EditSubtaskReviewer {subTask} {worklog} showModal={showEditModalSubtask}/>
            </td>
            <input class="billable rounded-border-1 font-regular" type="number" slot="billable"
                   id="{subTask['@id']}" value={$user.billable}>
        </SubTaskTableRow>
    </WorklogTable>
</div>

<ContextMenu selector="table-row" on:open={handleOpenContextMenu} bind:showOptions>
    <ContextMenuItem icon="edit" title={action} shortcut="E" on:click={() => handleClick(selectedTaskId, 'edit')}/>
</ContextMenu>
<style>
    .review-accept-button {
        background-color: var(--primary-color-2);
        border: 0;
        height: 2rem;
        width: 2rem;
        border-radius: 0.2rem;
    }

    .review-accept-button:hover {
        background-color: var(--signal-green);
    }

    .review-page-table {
        position: relative;
        top: 2.5vh;
    }

    .worklog-date {
        position: relative;
        bottom: 155px;
        right: 4rem;
    }

    .review-table-accept-reject, .worklog-table-edit-delete {
        text-align: right;
        background-color: var(--signal-light-yellow);
    }


    .review-table-head {
        bottom: 0;
        border-top: 1px solid var(--primary-color-3);
        border-bottom: 1.5px solid var(--primary-color-3);
    }

    .billable {
        background: var(--primary-color-2);
        border: none;
        color: var(--primary-color-5);
        text-align: center;
    }

    .review-table-accept-reject {
        text-align: center;
    }

    .checkbox-label {
        color: var(--primary-color-5)
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>

