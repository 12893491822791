<script>
    import {createEventDispatcher} from "svelte";

    export let dropdownItems;
    export let selectedItem;
    export let collection;
    export let createdAtArray;
    export let currentYear;

    const dispatch = createEventDispatcher();
    let hideDropdown = true;


    function selectItem(selection) {
        selectedItem = dropdownItems.find(item => item === selection);
        hideDropdown = true;

        dispatch('select');
    }

    $: {
        if (!hideDropdown) checkReviews()

        document.addEventListener("mousedown", (event) => {
            if (!event.target.classList.contains(collection)) {
                hideDropdown = true;
            }
        });
    }


    function handleMouseDown() {
        hideDropdown = !hideDropdown;

        if (!hideDropdown) {
            const toggleButton = document.querySelector(`.dropdown-toggle.${collection}`);
            const dropdownContent = document.querySelector(`.dropdown-content.${collection}`)

            const toggleRect = toggleButton.getBoundingClientRect();
            dropdownContent.style.top = `${toggleRect.bottom}px`;
            dropdownContent.style.left = `${toggleRect.left}px`;
            dropdownContent.style.width = `${toggleRect.width}px`;

            setTimeout(() => {
                const selectedElement = document.querySelector(`.calendar-dropdown-item.${collection}.selected`);
                if (selectedElement) {
                    selectedElement.scrollIntoView({ block: "nearest" });
                }
            }, 0);
        }
    }

    function checkReviews() {
        if (!createdAtArray) return

        const items = document.querySelectorAll('.calendar-dropdown-item')
        let proofDate

        items.forEach(item => {
            item.classList.remove('open-reviews')
            const value = item.getAttribute('data-value')

            switch (collection) {
                case 'months':
                    proofDate = `${value} ${currentYear}`
                    break
                case 'years':
                    proofDate = value
                    break;
            }

            if (createdAtArray.filter(date => date.includes(proofDate)).length) {
                item.classList.add('open-reviews')
            }
        })
    }
</script>


<div class="dropdown-menu-div" id="dropdown">
    <button class="dropdown-toggle {collection} button-effect-1 font-semi-bold font-size-medium" id="toggle"
            on:mousedown={handleMouseDown}>
        {selectedItem}
        <div class="dropdown-icons">
            <svg class="dropdown-arrow {collection}">
                <use xlink:href="/images/icons/arrow_down.svg#Ebene_1" class={collection}/>
            </svg>
        </div>
    </button>

    <div class="dropdown-content {collection}" hidden={hideDropdown}>
        <ul class="item-list {collection} font-regular font-size-medium">
            {#each dropdownItems as item}
                <li on:mousedown={() => selectItem(item)}
                    class="calendar-dropdown-item {collection} {selectedItem === item ? 'selected' : ''}" data-value={item}>
                    {item}
                </li>
            {/each}
        </ul>
    </div>
</div>


<style>
    .calendar-dropdown-item.open-reviews {
        color: var(--accent-colors-orange);
    }

    .item-list {
        padding-left: 0;
        margin: 0;
        list-style: none;
        border-radius: 0.2rem;
        max-height: 9rem;
        overflow-y: auto;
        background-color: var(--primary-color-2);
        width: 100%;
        animation: fadeIn 0.2s ease;
        text-align: center;
    }

    .item-list::-webkit-scrollbar {
        background-color: var(--primary-color-1);
        border-bottom-right-radius: 0.2rem;
        width: 0.5rem;
    }

    .item-list::-webkit-scrollbar-thumb {
        background-color: var(--primary-color-4);
        border-radius: 1rem;
    }

    .dropdown-content {
        position: fixed;
        background-color: var(--primary-color-2);
        border-radius: 0.2rem;
        z-index: 2;
        animation: fadeIn 0.2s ease-in-out;
        box-shadow: 0 3px 4px 1px #1B242C;
    }

    .dropdown-content.months {
        width: 12.3rem;
    }

    .dropdown-content.years {
        width: 7.5rem;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            max-height: 0;
        }
        to {
            opacity: 1;
            max-height: 9rem;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
            max-height: 9rem;
        }
        to {
            opacity: 0;
            max-height: 0;
        }
    }

    .calendar-dropdown-item {
        cursor: pointer;
        padding: 0.5rem 0 0.5rem;
        padding-inline: 0.5rem 0;
        color: var(--main-text-color)
    }

    .calendar-dropdown-item.selected, .calendar-dropdown-item:hover {
        background-color: var(--primary-color-3);
    }

    .calendar-dropdown-item:last-child {
        border-bottom: none;
    }

    .dropdown-toggle {
        cursor: pointer;
        color: var(--primary-color-5);
        background-color: var(--primary-color-2);
        padding-inline: 0.7rem;
        border-radius: 0.2rem;
        height: 2.4rem;
        border: none;
    }

    .dropdown-toggle.months {
        width: 12.3rem;
    }

    .dropdown-toggle.years {
        width: 7.5rem;
    }

    .dropdown-toggle:hover {
        background-color: var(--primary-color-3);
    }

    .dropdown-arrow {
        height: 1.2rem;
        width: 1.2rem;
        fill: var(--primary-color-5);
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-icons {
        float: right;
    }
</style>