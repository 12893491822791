<script>
    import {fetchRejectedWorklogs, getTasks, rejectedWorklogs, subtasks} from "../stores/worklogs.js";
    import {visibleProjects, fetchProjects} from "../stores/projects.js";
    import {colors, fetchColors} from "../stores/colors.js";
    import {costCenters, fetchCostCenters} from "../stores/costCenters.js";
    import {users, fetchUsers} from "../stores/users.js";
    import {fetchRejectedReviews, reviews} from "../stores/reviews.js";
    import {fetchReasons, reasons} from "../stores/reasons.js";
    import {onMount} from "svelte";
    import IssueTable from "./IssueTable.svelte";

    onMount(async () => {
        await fetchProjects();
        await fetchCostCenters();
        await fetchUsers();
        await fetchColors();
        await fetchRejectedWorklogs();
        await fetchRejectedReviews();
        await fetchReasons();
        await getTasks($rejectedWorklogs)
    })


</script>

<IssueTable worklogs={$rejectedWorklogs} projects={$visibleProjects} colors={$colors} costCenters={$costCenters}
            users={$users} reviews={$reviews} reasons={$reasons} subtasks={$subtasks}>
    <div slot="worklog-table-footer"></div>
</IssueTable>