<script>
    import {fetchRejectedWorklogs} from '../stores/worklogs';
    import {createReviewFromRejectedWorklog} from '../stores/reviews.js'
    import {millisecondsToHours, formatTimeInTimeZone, worklogDate} from "../util/date-time.js";
    import WorklogActions from "./WorklogActions.svelte";
    import SubTaskTableRow from "./SubTaskTableRow.svelte";
    import {setContext} from "svelte";
    import TaskDescription from "../util/TaskDescription.svelte";
    import {user} from "../stores/user.js";

    export let costCenters = [];
    export let projects = [];
    export let users = [];
    export let colors = [];
    export let worklogs = [];
    export let reviews = [];
    export let reasons = [];
    export let subtasks = []

    function handleReleaseIssue(reviewer, worklogId) {
        const userString = `/api/users/${$user.id}`
        createReviewFromRejectedWorklog(reviewer, worklogId, userString)
        fetchRejectedWorklogs();
    }

    setContext('worklog-table', {
        getWorklogs: () => worklogs,
        getSubtasks: () => subtasks,
        getProjects: () => projects,
        getColors: () => colors,
        getCostCenters: () => costCenters,
        getUsers: () => users,
    })

</script>


<div class="open-issue-info m-4">
    <h1 class="open-issue-info-header font-semi-bold">
        <svg class="issue-info-icon">
            <use xlink:href="/images/icons/issues.svg#Ebene_1"></use>
        </svg>
        Du hast {worklogs.length} offene Issues
    </h1>
    <p class="open-issue-info-text font-regular font-size-medium">Bitte verbessere deine Worklogs entsprechend der Begründung</p>
</div>
{#each worklogs as worklog }
    <div class="issue-table">
        <div class="issue-date font-semi-bold font-size-large">
            <p id="originalDate">  {worklogDate(worklog.startTime)} </p>
        </div>
        <table class="issue-main-table font-semi-bold">
            <thead class="review-table-head font-size-medium">
            <tr>
                <th class="review-table-head-data">
                    <div class="table-head-time">Zeit</div>
                </th>
                <th class="review-table-head-data"></th>
                <th class="review-table-head-data"> Projekt</th>
                <th class="review-table-head-data"> Kostenstelle</th>
                <th class="review-table-head-data"> Worklog</th>
                <th class="review-table-head-data"> Bearbeiter</th>
                <th></th>
                <th class="review-accept-reject"> Löschen/Bearbeiten</th>
            </tr>
            </thead>
            <tbody class="worklog-table-body">
            <tr class={`subtask-table-row`}>
                <td class="worklog-table-start-time font-size-default">
                    {formatTimeInTimeZone(worklog.startTime, 'UTC')} Uhr
                </td>
                <td class="worklog-table-end-time font-size-small">
                    <div class="worklog-table-end-time-border">
                        {millisecondsToHours(worklog.durationMills)}
                    </div>
                </td>
                <td class="worklog-table-project-data font-bold align-cell-items font-size-small">
                    <div class="worklog-project-costCenter-border center-content font-bold">
                        {#each projects as project}
                            {#if worklog.project === project['@id'] }
                                {#each colors as color}
                                    {#if project.color === color['@id']}
                                        <svg class="color-dot-worklog"
                                             style="fill: {color.code};">
                                            <use xlink:href="/images/icons/color_dot.svg#Ebene_1">
                                            </use>
                                        </svg>
                                    {/if}
                                {/each}
                                {project.name}
                            {/if}
                        {/each}
                    </div>
                </td>
                <td class="worklog-table-cost-center-data font-bold align-cell-items font-size-small">
                    <div class="worklog-project-costCenter-border center-content font-bold">
                        {#each costCenters as costCenter }
                            {#if worklog.costCenter === costCenter['@id']}
                                {#each colors as color}
                                    {#if costCenter.color === color['@id']}
                                        <svg class="color-dot-worklog"
                                             style="fill: {color.code}">
                                            <use xlink:href="/images/icons/color_dot.svg#Ebene_1">
                                            </use>
                                        </svg>
                                    {/if}
                                {/each}
                                {costCenter.name}
                            {/if}
                        {/each}
                    </div>
                </td>
                <td class="worklog-table-data-description pl-4 align-cell-items font-size-default">
                    <div class="worklog-task-title">
                        {worklog.title}
                    </div>
                    <TaskDescription task={worklog} projects={projects}/>
                </td>

                <td class="worklog-table-data-processor font-bold align-cell-items font-size-small">
                    <div class="co-workers-div">
                        <div class="worklog-table-row-processor-border center-content">
                            {#each users as user}
                                {#if worklog.user === user['@id']}
                                    {user.code.toUpperCase()}
                                {/if}
                            {/each}
                        </div>
                        {#if worklog.coWorkers}
                            <div class="co-workers">
                                {#each worklog.coWorkers as coWorkerId }
                                    <div class="worklog-table-row-processor-border center-content">
                                        {#each users as coWorker }
                                            {#if coWorkerId === coWorker['@id']}
                                                {coWorker.code.toUpperCase()}
                                            {/if}
                                        {/each}
                                    </div>
                                {/each}
                            </div>
                        {/if}
                    </div>
                </td>
                <slot name="billable">
                    <td></td>
                </slot>
                <slot {worklog}>
                    <td class="issue-table-edit-delete">
                        <WorklogActions {worklog}/>
                    </td>
                </slot>
            </tr>
            {#each subtasks as subTask}
                <slot name="subtasks" {subTask} {worklog}>
                    <SubTaskTableRow {subTask} {worklog} selectedUser={$user} justifyContent="end" highlightRow={false} {worklogs}/>
                </slot>
            {/each}
            </tbody>
        </table>
        <div class="reject-reason font-size-medium">
            <div class="issue-reason-header font-semi-bold">Gründe:</div>
            {#each reviews as review}
                {#if review.worklog === worklog['@id'] && review.outdated === false && review.status === "failure" }
                    <div class="reason-block" style="display: flex;">
                        {#each review.reasons as reviewReason}
                            {#each reasons as reason}
                                {#if reviewReason === reason["@id"]}
                                    <div class="reject-reason-block font-regular font-size-medium">
                                        {reason.title}
                                    </div>
                                {/if}
                            {/each}
                        {/each}
                    </div>
                    <div class="reject-comment">
                        <div class="issue-comment-header font-semi-bold">Kommentar:</div>
                        <p class="issue-comment font-regular">
                            {review.comment}
                        </p>
                    </div>
                    <div>
                        <button class="release-issue rounded-border-1 button-effect-2 font-semi-bold font-size-medium" on:click={handleReleaseIssue(review.user,worklog['@id'])}>
                            <svg class="issue-button-icon">
                                <use xlink:href="/images/icons/review_status.svg#Ebene_1"></use>
                            </svg>
                            Änderungen freigeben
                        </button>
                    </div>
                {/if}
            {/each}
        </div>
    </div>
{/each}

<style>
    .reason-block {
        color: var(--primary-color-5)
    }

    .open-issue-info {
        position: relative;
        bottom: 3rem;
        background-color: var(--primary-color-1);
        border: 0.1rem solid var(--primary-color-3);
        border-radius: 1rem;
        padding: 0.25rem;
        width: 30rem;
    }

    .issue-info-icon {
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--signal-red);
    }

    .issue-table {
        border-left: 0.2rem solid var(--signal-red);
        margin: 2rem 0 0 1.5rem;
    }

    .issue-date, .issue-main-table {
        margin-left: 2rem;
        margin-bottom: 3rem;
        width: 95%;
    }

    .reject-reason-block {
        width: fit-content;
        margin-left: 1rem;
        margin-bottom: 1rem;
        padding: 0.25rem 0.5rem 0.25rem 0.5rem;
        background: var(--signal-light-red);
    }

    .reject-comment {
        margin-left: 1rem;
    }

    .release-issue {
        margin: 1rem;
        border: 0;
        height: 2.5rem;
        width: 19rem;
        background-color: var(--accent-colors-blue);
    }

    .issue-button-icon {
        height: 1rem;
        width: 1rem;
        fill: #FFFFFF;
    }

    .issue-reason-header {
        margin: 0 0 0.5rem 1rem;
    }

    .issue-comment-header {
        margin-bottom: 0.5rem;
    }

    .open-issue-info-header {
        font-size: 1.375rem;
        padding-top: 2rem;
        padding-left: 1.5rem;
        color: var(--primary-color-5);
    }

    .open-issue-info-text {
        padding: 0 2rem 1rem 2rem;
        color: var(--primary-color-5);
    }

    .color-dot-worklog{
        height: 0.625rem;
        width: 1rem;
    }

    .issue-table-edit-delete{
        width: 0;
        position: relative;
        text-align: center;
    }

    .worklog-table-start-time{
        width: 8vw;
    }

    .worklog-table-project-data{
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        background-color: var(--primary-color-1);
        color: var(--primary-color-5);
        height: 9rem;
    }

    .worklog-project-costCenter-border{
        width: fit-content;
        padding: 0.5rem;
        background-color: var(--primary-color-2);
        border-radius: 1.375rem;
        color: var(--primary-color-5);
        height: 1.5rem;
        text-wrap: nowrap;
    }

    .issue-date,
    .worklog-table-data-description,
    .worklog-table-start-time,
    .worklog-table-end-time,
    .worklog-table-data-processor,
    .issue-reason-header,
    .issue-comment-header,
    .issue-comment{
        color: var(--primary-color-5);
    }

    .worklog-table-row-processor-border {
        width: 3rem;
        background-color: var(--primary-color-2);
        border-radius: 1rem;
        height: 1.5rem;
    }

    .co-workers-div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
    }

    .worklog-table-data-description{
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
</style>
