<script>
    import StatisticTable from "./StatisticTable.svelte";
    import {clearData, data, fetchData, userData} from "../stores/statistic.js";
    import {onDestroy, onMount} from "svelte";
    import ChooseUser from "./ChooseUser.svelte";
    import {fetchColors} from "../stores/colors.js";
    import {fetchUsers} from "../stores/users";
    import Graph from "../util/Graph.svelte";

    let selectedUser = ""
    let workingTime = 0.0
    let billableTime = 0.0
    let profit = 0.0
    let showTable = false
    let hours
    let minutes
    let mainData = []
    let datasets = []
    let dataNodes = []


    onMount(async () => {
        await fetchColors()
        await fetchUsers()

    })

    onDestroy(async () => {
        await clearData()
    })

    async function handleUserSelected(event) {
        showTable = false
        selectedUser = event.detail.selectedUser
        await fetchData('workers', selectedUser.id)
        mainData = $userData.mainData
        dataNodes = $userData.graphData
        datasets = $userData.graphData.datasets
        await calculateWorkingTime()
        mainData.forEach(data => {
            profit += parseFloat(data['Profit in €'].toFixed(2))
        })
        showTable = true
    }


    async function calculateWorkingTime() {
        workingTime = 0.0
        billableTime = 0.0
        profit = 0.0
        mainData.forEach(data => {
            convertTotalWorkingTime(data['Gesamtarbeitszeit'])
            console.log(data)
            if (data.Projekt === 'Intern') return
            convertBillableWorkingTime(data['Berechenbare Zeit'])
        })
        workingTime = formatHoursToTimeString(workingTime)
        billableTime = formatHoursToTimeString(billableTime)
    }

    function convertTotalWorkingTime(timeStr) {
        hours = 0;
        minutes = 0;
        convertTimeStringToHours(timeStr)

        workingTime += hours + (minutes / 60);
    }

    function convertBillableWorkingTime(timeStr) {
        hours = 0
        minutes = 0

        convertTimeStringToHours(timeStr)

        billableTime += hours + (minutes / 60)
    }

    function convertTimeStringToHours(timeStr) {
        if (timeStr.includes('h')) {
            hours = parseInt(timeStr.split('h')[0]);
            timeStr = timeStr.split('h')[1];
        }

        if (timeStr.includes('m')) {
            minutes = parseInt(timeStr.split('m')[0]);
        }
    }

    function formatHoursToTimeString(floatHours) {
        const hours = Math.floor(floatHours);

        const minutes = Math.round((floatHours - hours) * 60);

        return `${hours}h ${minutes}m`;
    }

</script>

<div class="user-div">
    <ChooseUser {selectedUser} on:userSelected={handleUserSelected}>
        <button slot="reset-selection-button" hidden></button>
        <div slot="show-all-user"></div>
    </ChooseUser>
    {#if selectedUser}
        {#if workingTime}
            <p class="working-time-text">
                Hat {workingTime} in diesem Monat gearbeitet. <br>
                Davon sind {billableTime} abrechenbar <br>
                Profit diesen Monat: {profit}€
            </p>
        {:else}
            <p class="working-time-text">Keine Arbeitszeit in diesem Monat vorhanden</p>
        {/if}
    {/if}
</div>
{#if showTable}
    <StatisticTable data={mainData}/>
    <Graph {dataNodes} {datasets} boolean={true}/>
{/if}
<style>
    .user-div {
        display: flex;
        align-items: center;
    }

    .working-time-text {
        font-family: 'Poppins SemiBold';
        font-size: 2vh;
        margin-bottom: 0;
        padding-left: 0.5rem;
    }
</style>