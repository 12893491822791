import {writable} from "svelte/store";
import {fetchRejectedWorklogs, fetchWorklogs, worklogs} from "./worklogs.js";
import {validateCreateSubtask, validateUpdateSubtask} from "../util/InputValidation";
import {hoursToMilliseconds, millisecondsToHours, needsUpdate} from "../util/date-time";
import {selectedDate} from "./selectedDate";
import {getCoWorkers} from "./users";
import {apiUrl} from "./environment";

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})

export let subTasks = writable([]);

let date;
selectedDate.subscribe((newDate) => date = newDate)

export async function fetchSubTasks() {
    try {
        const response = await fetch(`${baseUrl}/api/subtasks`);
        const data = await response.json();
        subTasks.set(data['hydra:member']);
    } catch (error) {
        throw new Error(error);
    }
}

export async function fetchSubTasksByWorklog(worklogId) {
    try {
        const response = await fetch(`${baseUrl}/api/subtasks?page=1&parent=${worklogId}`);
        const data = await response.json();
        return data['hydra:member'];
    } catch (error) {
        throw new Error(error);
    }
}

export async function createSubtask(subtask, worklog, reviews, duration) {
    try {
        validateCreateSubtask(subtask)
        hoursToMilliseconds(subtask, duration)

        subtask.coWorkers = getCoWorkers(subtask)

        const response = await fetch(`${baseUrl}/api/subtasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(subtask),
        });

        if (response.ok) {
            await fetchWorklogs()
            await fetchSubTasks()
        } else {
            const errorData = await response.json();
            throw errorData.error;
        }
    } catch (error) {
        throw error;
    }
}

export async function updateSubtask(subtask, id, duration) {
    try {
        validateUpdateSubtask(subtask)

        if (duration) {
            hoursToMilliseconds(subtask, duration)
            subtask.coWorkers = getCoWorkers(subtask)
        }

        const response = await fetch(`${baseUrl}/api/subtasks/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
            body: JSON.stringify(subtask),
        });

        if (response.ok) {
            await getWorklogs()
            await fetchSubTasks();
        } else {
            const errorData = await response.json();
            throw errorData.error;
        }
    } catch (error) {
        throw error;
    }
}

async function getWorklogs() {
    const pageTitle = document.querySelector('.content-title').innerHTML

    if (pageTitle.includes('Issues')) {
        await fetchRejectedWorklogs()
    } else {
        await fetchWorklogs()
    }
}

export async function updateReviewStatus(worklog, reviews) {
    if (!reviews) return
    if (worklog.reviewStatus !== "success") {
        return
    }
    worklog.reviews.forEach((worklogReview) => {
        reviews.forEach(async (review) => {
            if (review['@id'] !== worklogReview && review.status !== "success") return
            await fetch(`${baseUrl}${review['@id']}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/merge-patch+json',
                },
                body: JSON.stringify({
                    outdated: true
                })
            });
        })
    });

    await fetch(`${baseUrl}/api/worklogs/${worklog.id}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/merge-patch+json',
        },
        body: JSON.stringify({
            onceApproved: false,
            reviewStatus: "private"
        })
    });

}

export async function deleteSubtask(subtaskId, selectedDate = date) {
    try {
        const response = await fetch(`/api/subtasks/${subtaskId}`, {
            method: 'DELETE',
        });

        if (response.ok) {
            needsUpdate.set(true)
        }
    } catch (error) {
        throw new Error(error);
    }
}

export function filterSubtasks (filteredWorklogs, subtasks) {
    const filteredSubtasks = []
    if(!subtasks) return
    subtasks.forEach(subtask => {
        const matchingWorklog = filteredWorklogs.find(worklog => worklog.subtask.includes(subtask['@id']));

        if (matchingWorklog) {
            filteredSubtasks.push(subtask);
        }
    });
    return filteredSubtasks
}