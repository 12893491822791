// stores/statistic.js
import {writable} from "svelte/store";

export let data = writable({});
export let userData = writable({});

export let storeProjectId = writable([]);

export async function fetchData(tab, userId, projectId = null) {
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;
    const date = searchParams.get('routeParams[date]');
    const endDate = searchParams.get('routeParams[endDate]')
    const dateParam = new Date(date);

    const month = dateParam.getMonth() + 1;
    const year = dateParam.getFullYear();

    const url = new URL('/createJson', window.location.origin);

    if (endDate) {
        const endDateParam = new Date(endDate)
        const endDay = endDateParam.getDate()
        const endMonth = endDateParam.getMonth() + 1
        const endYear = endDateParam.getFullYear()
        const day = dateParam.getDate()
        url.searchParams.append('endDay', endDay)
        url.searchParams.append('endMonth', endMonth)
        url.searchParams.append('endYear', endYear)
        url.searchParams.append('day', day)
    }

    url.searchParams.append('month', month);
    url.searchParams.append('year', year);
    url.searchParams.append('tab', tab);
    if (userId) {
        url.searchParams.append('userId', userId);
    }
    if (projectId) {
        storeProjectId.set(projectId);
        url.searchParams.append('projectId', projectId);
    }

    try {
        const response = await fetch(url);
        const responseData = await response.json();

        if (tab === "workers") {
            userData.set(responseData);
        } else {
            data.set(responseData);
        }
    } catch (error) {
        throw new Error(error);
    }
}

export async function clearData() {
    userData.set([]);
    data.set([]);
}
