<script>
    import SubtaskModal from "./SubtaskModal.svelte";
    import {millisecondsToHours} from "../util/date-time.js";
    import TimeInput from "../util/TimeInput.svelte";
    import {trapFocus} from "../util/Shortcut";

    export let subTask
    export let worklog;
    export let showModal = false;
    export let selectedUser
    export let worklogs
    let formattedTime
    let timeUnit

    function handleOpenModal() {
        showModal = true;
        formattedTime = millisecondsToHours(subTask.durationMills)
        document.addEventListener('keydown', trapFocus);
        timeUnit = formattedTime
    }

</script>

<style>
    .worklog-subtask-edit-button {
        background-color: var(--primary-color-2);
        border: 0rem;
        height: 2rem;
        width: 2rem;
        border-radius: 0.2rem;
        margin-right: 2rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .worklog-subtask-edit-button:hover {
        background-color: var(--accent-colors-blue);
    }

    .worklog-subtask-edit-icon {
        height: 1rem;
        width: 1rem;
        fill: var(--primary-color-5);
    }
</style>


<SubtaskModal {subTask} {worklog} {formattedTime} worklogId={worklog['@id']} {worklogs} bind:showModal>
    <div slot="subtask-button">
        <button class="worklog-subtask-edit-button button-effect-2" id="edit-modal" on:click={handleOpenModal}>
            <svg class="worklog-subtask-edit-icon">
                <use xlink:href="/images/icons/edit.svg#Ebene_1">
                </use>
            </svg>
        </button>
    </div>
    <div slot="subtask-modal-header">
        <h1 class="font-semi-bold">Subtask bearbeiten</h1>
    </div>
    <div slot="subtask-duration-slot">
        <TimeInput bind:time={formattedTime} inputMode="duration" selectedUser={selectedUser}/>
    </div>
</SubtaskModal>
