<script>
    import Modal from "../util/Modal.svelte";
    import {createEventDispatcher} from "svelte";

    export let showModal = false;
    const dispatch = createEventDispatcher()

    function submit() {
        dispatch('submit')
        showModal = false
    }

</script>

<button class="restore-button button-effect-2" on:click={() => showModal = true}>
    <i class="fa-solid fa-rotate-left"/>
</button>

{#if showModal}
    <Modal bind:showModal>
        <div class="archive-modal">
            <slot name="archive-modal-headline">
                <p class="archive-modal-headline-text font-semi-bold font-size-large">Worklog wiederherstellen</p>
            </slot>
            <div class="archive-modal-info">
                <p class="font-regular font-size-medium">Sind Sie sicher, dass sie den Worklog wiederherstellen möchten?</p>
            </div>
            <button class="archive-modal-button button-effect-2 font-size-medium" on:click={submit}>Wiederherstellen</button>
        </div>
    </Modal>
{/if}