<script>
    import {onMount, onDestroy} from "svelte";
    import StatisticTable from "./StatisticTable.svelte";
    import {fetchData, data, clearData} from "../stores/statistic.js";
    import {user} from "../stores/user.js";
    import Graph from "../util/Graph.svelte";

    let mainData = []
    let datasets = []
    let dataNodes = []

    onMount(async () => {
        await fetchData('project', $user.id);
        mainData = $data.mainData
        dataNodes = $data.graphData
        datasets = $data.graphData.datasets
    });

    onDestroy(async () => {
        await clearData();
    });

    const projectPage = true;
</script>

<StatisticTable data={mainData} selectedPage={projectPage}/>
<Graph {dataNodes} {datasets} boolean={true}/>
