<script>
    import {onMount} from 'svelte';
    import {users, fetchUsers} from "../stores/users";
    import {fetchUserRoles, roles} from "../stores/roles.js";
    import {fetchPrivateWorklogs, worklogs} from "../stores/worklogs";
    import {user} from "../stores/user";
    import {worklogDate} from "../util/date-time";
    import {selectedDate} from "../stores/selectedDate";
    import {sendNotification, fetchNotifications, notifications} from "../stores/notifications";

    let groupedWorklogs = []
    let firstHalf = []
    let secondHalf = []
    let isAuthorized
    let message
    let requestData = []

    onMount(async () => {
        await fetchUserRoles()
        await fetchUsers()
        await fetchNotifications()
        await fetchPrivateWorklogs($selectedDate)
        await filterData()
        await getUserEmails()
    })

    async function filterData() {
        if (!$roles.some(userRole => userRole.code.includes("ROLE_REVIEWER"))) {
            $worklogs = $worklogs.filter(worklog => worklog.user === `/api/users/${$user.id}`);
        }

        groupedWorklogs = groupWorklogs($worklogs)
        const splittedArray = splitGroupedWorklogs(groupedWorklogs);

        firstHalf = splittedArray[0];
        secondHalf = splittedArray[1];
    }

    async function getUserEmails() {
        Object.entries(groupedWorklogs).forEach(([user, date]) => {
            let userEmail = $users.find(u => u['@id'] === user).email

            let dates = Object.keys(date).flat()
            let dateString = dates.join(', ');

            requestData.push({
                email: userEmail,
                dates: dateString
            })
        })
    }

    async function checkAuthorization() {
        const userString = `/api/users/${$user.id}`

        return $roles.some(({code, users}) => code.includes("ROLE_REVIEWER", "ROLE_ADMIN")
            && users.includes(userString));
    }

    function groupWorklogs(worklogs) {
        return worklogs.reduce((item, worklog) => {
            const user = worklog.user;
            const date = worklog.startTime.split('T')[0];
            if (!item[user]) {
                item[user] = {};
            }

            if (!item[user][date]) {
                item[user][date] = [];
            }
            item[user][date].push(worklog);

            return item;
        }, {});
    }

    function splitGroupedWorklogs(groupedWorklogs) {
        const keys = Object.keys(groupedWorklogs);
        const midIndex = Math.ceil(keys.length / 2);

        const firstHalfKeys = keys.slice(0, midIndex);
        const secondHalfKeys = keys.slice(midIndex);

        const firstHalf = firstHalfKeys.reduce((item, key) => {
            item[key] = groupedWorklogs[key];
            return item;
        }, {});

        const secondHalf = secondHalfKeys.reduce((item, key) => {
            item[key] = groupedWorklogs[key];
            return item;
        }, {});

        return [firstHalf, secondHalf];
    }

    async function handleClick() {
        const audio = document.getElementById('bell-ringing')
        audio.play()

        if (!checkAuthorization() || !$worklogs.length) return


        try {
            await sendNotification(requestData, message)
            await filterData()
        } catch (error) {
            throw error
        }

        message = null
    }

    function getNotificationCount(user) {
        const notification = $notifications.find(notification => notification.user === user);

        if (notification) {
            return `Botschaften aus ferner Zeit: ${notification.count}`
        } else {
            return 'Keine Botschaft ward versandt bis dato.'
        }
    }
</script>

<div class="board-of-shame-wrapper">
    <div class="board-of-shame-header">
        {#if checkAuthorization()}
        <p class="board-of-shame-headline">Press to Shame</p>
            {#if Object.entries(groupedWorklogs).length}
                <p class="board-of-shame-info font-regular font-size-medium">Verkündet durch einen Gesandten an alle, die noch nicht ihre Werktagebücher entrichtet haben.</p>
            {:else}
                <p class="board-of-shame-info font-regular font-size-medium">Derzeit bedarf es keines Missetäters, der seine Sünden büßen muss.</p>
            {/if}
        {:else}
            {#if Object.entries(groupedWorklogs).length}
                <p class="board-of-shame-headline">Shame on You</p>
                <p class="board-of-shame-info font-regular">Legt dar, was Ihr gewirkt habt.</p>
            {:else}
                <p class="board-of-shame-headline">Ihr seid rein von Vergehen.</p>
            {/if}
        {/if}
    </div>
    <div class="board-of-shame">
        <div class="board-of-shame-group">
            {#if Object.entries(firstHalf).length}
            <svg class="scroll-piece start">
                <use xlink:href="/images/boardofshame/scroll_end_piece.svg#Ebene_1"></use>
            </svg>
            <div class="board-of-shame-group-list">
                {#each Object.entries(firstHalf) as [user, dates]}
                    <div class="board-of-shame-group data">
                        <p class="board-of-shame-group-text name font-size-large">
                            {$users.find(u => u['@id'] === user).name}
                        </p>
                        {#each Object.keys(dates) as date}
                            <p class="board-of-shame-group-text date font-extra-light font-size-medium">
                                {worklogDate(date)}
                            </p>
                        {/each}
                        <p class="board-of-shame-group-text notifications font-size-small">
                            {getNotificationCount(user)}
                        </p>
                    </div>
                {/each}
            </div>
            <svg class="scroll-piece end">
                <use xlink:href="/images/boardofshame/scroll_end_piece.svg#Ebene_1"></use>
            </svg>
            {/if}
        </div>
        <div class="board-of-shame-group middle">
            <div class="board-of-shame-group-circle">
                <svg class="outer-circle">
                    <use xlink:href="/images/boardofshame/shame_outer_circle.svg#Ebene_1"></use>
                </svg>
                <svg class="inner-circle">
                    <use xlink:href="/images/boardofshame/shame_inner_circle.svg#Ebene_1"></use>
                </svg>
                <svg class="bell" on:mousedown={handleClick}>
                    <use xlink:href="/images/boardofshame/shame_ringing.svg#Ebene_1"></use>
                </svg>
                <audio id="bell-ringing" src="/audio/shame_bell_sound.mp3"></audio>
            </div>
            {#if checkAuthorization() && Object.entries(groupedWorklogs).length}
            <div class="board-of-shame-group-message mt-2">
                <p class="shame-message-headline font-size-large">Eure fakultative Zugabe zur Botschaft</p>
                <input type="text" bind:value={message} placeholder="Verbum reservans locum" class="shame-message-input-field rounded-border-1">
            </div>
            {/if}
        </div>
        <div class="board-of-shame-group">
            {#if Object.entries(secondHalf).length}
            <svg class="scroll-piece start">
                <use xlink:href="/images/boardofshame/scroll_end_piece.svg#Ebene_1"></use>
            </svg>
            <div class="board-of-shame-group-list">
                {#each Object.entries(secondHalf) as [user, dates]}
                    <div class="board-of-shame-group data">
                        <p class="board-of-shame-group-text name font-size-large">
                            {$users.find(u => u['@id'] === user).name}
                        </p>
                        {#each Object.keys(dates) as date}
                            <p class="board-of-shame-group-text date font-extra-light font-size-medium">
                                {worklogDate(date)}
                            </p>
                        {/each}
                        <p class="board-of-shame-group-text notifications font-size-small">
                            {getNotificationCount(user)}
                        </p>
                    </div>
                {/each}
            </div>
            <svg class="scroll-piece end">
                <use xlink:href="/images/boardofshame/scroll_end_piece.svg#Ebene_1"></use>
            </svg>
            {/if}
        </div>
    </div>
</div>


<style>
    .board-of-shame {
        display: flex;
        justify-content: space-around;
        gap: 8rem;
    }

    .board-of-shame-wrapper {
        padding: 2rem;
    }

    .board-of-shame-headline {
        font-family: Luminari;
        color: var(--primary-color-5);
        font-size: 36px;
        font-weight: bolder;
    }

    .board-of-shame-info {
        color: var(--primary-color-4);
    }

    .board-of-shame-header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .board-of-shame-group, .board-of-shame-group-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 13vw;
    }

    .board-of-shame-group-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3rem;
        min-height: 30rem;
        background-color: var(--primary-color-2);
        margin: -4.5rem;
        padding: 3rem;
        width: 19vw;
    }

    .board-of-shame-group-message {
        padding: 2rem;
    }

    .shame-message-input-field {
        border: none;
        width: 15rem;
        height: 6rem;
        text-align: center;
        background-color: var(--active-color-1);
        color: var(--primary-color-3);
        font-family: Luminari;
    }

    .shame-message-headline {
        text-align: center;
        font-family: Luminari;
        color: var(--primary-color-4)
    }

    .board-of-shame-group-text {
        margin: 0;
    }

    .board-of-shame-group-text.name {
        font-family: Luminari;
        color: var(--primary-color-5)
    }

    .board-of-shame-group-text.date {
        color: var(--primary-color-4)
    }

    .board-of-shame-group-text.notifications {
        font-family: Luminari;
        margin-top: 0.5rem;
    }

    .bell {
        fill: var(--primary-color-3-light);
        transition: fill 0.3s ease;
    }

    .outer-circle, .inner-circle {
        fill: var(--primary-color-3-light);
        transition: transform 0.3s ease, fill 0.3s ease;
    }

    .board-of-shame-group-circle:hover .outer-circle {
        transform: rotate(90deg) scale(1.1);
        fill: var(--primary-color-3);
    }


    .board-of-shame-group-circle:hover .inner-circle {
        transform: scale(0.9);
        fill: var(--primary-color-3);
    }

    .board-of-shame-group-circle:hover .bell {
        fill: var(--primary-color-5)
    }

    .bell:hover {
        cursor: pointer;
    }

    .scroll-piece {
        fill: var(--primary-color-3);
        width: 23vw;
        z-index: 1;
    }

    .board-of-shame-group.middle {
        position: relative;
    }

    .board-of-shame-group-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 1rem;
    }

    .outer-circle {
        width: 25rem;
        height: 25rem;
    }

    .inner-circle {
        position: absolute;
        width: 22rem;
        height: 22rem;
    }

    .bell {
        position: absolute;
        width: 3.5rem;
        height: 3.5rem;
    }
</style>
