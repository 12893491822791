<script>
    import {colors} from "../stores/colors";
    import {createEventDispatcher} from "svelte";

    export let dropdownItems;
    export let selectedItem;
    export let mode;
    export let collection;

    export let disabled = false;
    let isSelected = false

    const dispatch = createEventDispatcher();
    let searchText = '';
    let openDropdown = false;
    let mouseDown = false
    let suggestedItem

    function selectItem(selection) {
        selectedItem = dropdownItems.find(item => item === selection);
        openDropdown = false;

        dispatch('select');
    }

    function resetSelection() {
        selectedItem = null;
        dispatch('delete');
    }

    function filterItems(searchText, array, key) {
        if (searchText) {
            const lowerSearchText = searchText.toLowerCase();

            const startsWithSearchText = array.filter(item =>
                item[key].toLowerCase().startsWith(lowerSearchText)
            );

            const includesSearchText = array.filter(item =>
                item[key].toLowerCase().includes(lowerSearchText) &&
                !item[key].toLowerCase().startsWith(lowerSearchText)
            );
            return [...startsWithSearchText, ...includesSearchText];
        }
        return array;
    }

    function findColor(id) {
        let color = $colors.find(color => color['@id'] === id);
        return color.code;
    }

    document.addEventListener('keydown', (event) => {
        if (openDropdown && event.target.classList.contains(collection)) {
            document.querySelector('.search-items').focus()
            const items = filterItems(searchText, dropdownItems, 'name')
            if (searchText) suggestedItem = items[0]

            const index = items.findIndex(item => item === selectedItem);

            switch (event.key) {
                case 'ArrowDown':
                    selectedItem = items[index + 1];
                    isSelected = true
                    break;
                case 'ArrowUp':
                    selectedItem = items[index - 1];
                    isSelected = true
                    break;
                case 'Enter':
                    if (document.querySelector('.search-items').value.trim() !== '' && isSelected === false) {
                        selectedItem = items.find(item => item.name.toLowerCase().includes(searchText.toLowerCase()));
                    }
                    searchText = ''
                    isSelected = false
                    if (selectedItem) {
                        selectItem(selectedItem)
                        dispatch('select')
                    }
                    openDropdown = false;
                    break;
                case 'Tab':
                    openDropdown = false;
                    if (searchText) selectedItem = items[0]
                    if (selectedItem) {
                        selectItem(selectedItem)
                        dispatch('select')
                    }
                    break;
            }

        }

    })

    $: {
        document.addEventListener("mousedown", (event) => {
            if (!event.target.classList.contains(collection)) {
                if (selectedItem && openDropdown) {
                    selectItem(selectedItem)
                    dispatch('select')
                }
                openDropdown = false;
            }
        });
    }

    function handleMouseDown() {
        mouseDown = true
        handleOpenDropdown()
    }

    function handleFocus() {
        if (!mouseDown && collection !== 'Zeitraum') handleOpenDropdown()
    }

    function handleOpenDropdown() {
        openDropdown = !openDropdown;
    }
</script>

<slot name="dropdown-label">
    <div class="dropdown-label {disabled ? 'disabled' : ''} {mode} font-regular">
        <label for="dropdown" class="font-regular font-size-small">{collection}</label><br>
    </div>
</slot>

<div class="dropdown-menu-div {disabled ? 'disabled' : ''}" id="dropdown">
    <button class="dropdown-toggle {mode} {collection} button-effect-1 font-semi-bold font-size-medium" id="toggle"
            on:mousedown={handleMouseDown}
            on:focus={handleFocus}
            {disabled}>
        {selectedItem ? selectedItem.name : 'Bitte auswählen...'}
        <div class="dropdown-icons">
            <slot name="reset-selection">
                {#if selectedItem}
                    <svg class="delete-selection mr-3" on:mousedown={() => resetSelection()}>
                        <use xlink:href="/images/icons/delete.svg#Ebene_1"></use>
                    </svg>
                {/if}
            </slot>
            <svg class="dropdown-arrow {collection}">
                <use xlink:href="/images/icons/arrow_down.svg#Ebene_1" class={collection}/>
            </svg>
        </div>
    </button>

    {#if openDropdown && !disabled}
        <div class="dropdown-content {mode} {collection} pt-3">
            <slot name="dropdown-search-filter">
                <input
                        type="text"
                        placeholder=""
                        bind:value={searchText}
                        {disabled}
                        class="search-items {collection} ml-2 mb-3 font-regular font-size-default"
                /><i class="fa fa-search m-2"></i>
            </slot>
            <ul class="item-list {mode} {collection} font-regular font-size-medium">
                {#if filterItems(searchText, dropdownItems, 'name').length > 0}
                    {#each filterItems(searchText, dropdownItems, 'name') as item (item.id)}
                        <li on:mousedown={() => selectItem(item)}
                            class="dropdown-item {collection} {selectedItem === item ? 'selected' : ''} {suggestedItem === item ? 'suggestion' : ''}">
                            <slot name="dropdown-item-color">
                                <svg class="item-color" style="fill: {findColor(item.color)}">
                                    <use xlink:href="/images/icons/color_dot.svg#Ebene_1"></use>
                                </svg>
                            </slot>
                            {item.name}
                            <slot name="dropdown-item-code">
                                <small class="item-code font-regular font-size-default mr-1">
                                    {item.code}
                                </small>
                            </slot>
                        </li>
                    {/each}
                {:else}
                    <p class="no-result font-regular font-size-medium">Keine Ergebnisse gefunden</p>
                {/if}

            </ul>
        </div>
    {/if}
</div>


<style>
    .dropdown-label.task {
        margin-top: 0.8rem;
        color: var(--main-text-color);
    }

    .item-list {
        padding-left: 0;
        margin: 0;
        list-style: none;
        border-radius: 0.2rem;
        max-height: 9rem;
        overflow-y: auto;
        background-color: var(--primary-color-2);
        width: 100%;
        z-index: 2;
        animation: fadeIn 0.2s ease;
    }

    .item-list::-webkit-scrollbar {
        background-color: var(--primary-color-1);
        border-bottom-right-radius: 0.2rem;
        width: 0.5rem;
    }

    .item-list::-webkit-scrollbar-thumb {
        background-color: var(--primary-color-4);
        border-radius: 1rem;
    }

    .item-list.time {
        background-color: var(--primary-color-2);
        text-align: center;
    }

    .dropdown-content.time {
        width: 6rem;
        background-color: var(--primary-color-2);
        border-radius: 0.2rem;
        animation: fadeIn 0.2s ease;
        padding: 0 !important;
    }

    .dropdown-content.export.Format {
        width: 13.5rem;
    }

    .dropdown-content {
        position: fixed;
        width: 30.3rem;
        background-color: var(--primary-color-2);
        border-radius: 0.2rem;
        z-index: 2;
        animation: fadeIn 0.2s ease-in-out;
        box-shadow: 0 3px 4px 1px #1B242C;
    }

    .dropdown-content.task {
        width: 27rem;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            max-height: 0;
        }
        to {
            opacity: 1;
            max-height: 9rem;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
            max-height: 9rem;
        }
        to {
            opacity: 0;
            max-height: 0;
        }
    }

    .fa.fa-search {
        position: absolute;
        color: var(--primary-color-4);
        animation: fadeIn 0.2s ease;
    }

    .search-items {
        width: 90%;
        height: 2rem;
        border-radius: 1rem;
        background-color: var(--primary-color-1);
        border: none;
        animation: fadeIn 0.2s ease;
        padding: 1rem;
    }

    .dropdown-item {
        cursor: pointer;
        padding: 0.5rem 0 0.5rem;
        padding-inline: 0.5rem 0;
        color: var(--main-text-color)
    }

    .dropdown-item.selected, .dropdown-item:hover {
        background-color: var(--primary-color-3);
    }

    .dropdown-item.suggestion {
        outline: 1px dashed var(--primary-color-4);
        outline-offset: -1px;
    }

    .dropdown-item:last-child {
        border-bottom: none;
    }

    .no-result {
        text-align: center;
        color: var(--main-text-color)
    }

    .item-color {
        height: 0.5rem;
        width: 0.5rem;
    }

    .item-code {
        float: right;
        color: var(--primary-color-4);
    }

    .delete-selection {
        height: 1.2rem;
        width: 1.2rem;
        fill: var(--primary-color-5);
        z-index: 1;
    }

    .delete-selection:hover {
        fill: var(--signal-red);
        cursor: pointer;
    }

    .dropdown-toggle {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--primary-color-5);
        background-color: var(--primary-color-2);
        padding-inline: 0.7rem;
        border-radius: 0.2rem;
        height: 2.4rem;
        border: none;
    }

    .dropdown-toggle.time {
        width: 6rem;
    }

    .dropdown-toggle.task {
        width: 27rem;
        height: 2rem;
    }

    .dropdown-toggle.export {
        width: 100%;
    }

    .dropdown-toggle:hover {
        background-color: var(--primary-color-3);
    }

    .dropdown-arrow {
        height: 1.2rem;
        width: 1.2rem;
        fill: var(--primary-color-5);
    }

    .dropdown-toggle::after {
        display: none;
    }


    .dropdown-label {
        width: 100%;
    }

    .arrowdown.time {
        left: 6.5rem;
    }

    .arrowdown:not(.arrowdown.time) {
        right: 2.5rem;
    }

    .dropdown-icons {
        float: left;
    }

    .dropdown-menu-div.disabled {
        opacity: 0.5;
        pointer-events: none;
    }


</style>