<script>
    import {createEventDispatcher} from 'svelte';
    import {fetchWorklogs} from "../stores/worklogs";
    import SubmitDeletionModal from "./DeleteConfirmationModal.svelte";
    import {needsUpdate} from "../util/date-time";
    import {apiUrl} from "../stores/environment";


    const dispatch = createEventDispatcher();
    export let worklog;

    let showModal = false;
    let deleteSubmit = false;

    function checkSubmit(e) {
        deleteSubmit = e.detail.deleteSubmit;

        if (deleteSubmit) {
            deleteWorklog();
        }
    }

    async function deleteWorklog() {
        try {
            const response = await fetch(`${$apiUrl}/api/worklogs/${worklog.id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                await fetchWorklogs()
                needsUpdate.set(true)
            } else {
                console.error('Error deleting worklog');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

</script>

<button on:click={() => showModal = true} class="worklog-table-row-delete-button button-effect-3 mr-2" id="delete-task">
    <svg class="worklog-table-row-delete-icon">
        <use xlink:href="/images/icons/delete.svg#Ebene_1" class="worklog-table-row-svg"></use>
    </svg>
</button>

<SubmitDeletionModal bind:showModal={showModal} task={worklog} status={worklog.reviewStatus} on:submit={checkSubmit} mode="Task"/>

<style>
    .worklog-table-row-delete-button {
        background-color: var(--primary-color-2);
        border: 0;
        height: 2rem;
        width: 2rem;
        border-radius: 0.2rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .worklog-table-row-delete-button:hover {
        background-color: var(--signal-red);
    }

    .worklog-table-row-delete-icon {
        height: 1rem;
        width: 1rem;
        fill: var(--primary-color-5);
    }
</style>