<script>
    import WorklogTable from "./WorklogTable.svelte";
    import Weekdays from "./Weekdays.svelte";
    import ContextMenuItem from "../util/ContextMenuItem.svelte";
    import ContextMenu from "../util/ContextMenu.svelte";
    import {user} from "../stores/user";
    import {handleClick, onKeyUp} from "../util/Shortcut.js";

    export let selectedUser
    let selectedTaskId
    let action

    function handleOpenContextMenu(event) {
        const detail = event.detail;
        selectedTaskId = detail.selectedElement.getAttribute('task');

        if (selectedTaskId.includes('subtask')) {
            action = 'Subtask bearbeiten'
        } else {
            action = 'Task bearbeiten'
        }
    }

</script>

<svelte:window on:keyup|preventDefault={onKeyUp}/>

<Weekdays page={'worklogs'} {selectedUser}/>

<WorklogTable selectedUser={selectedUser}>
</WorklogTable>

{#if selectedUser.id === $user.id}
    <ContextMenu selector="table-row" on:open={handleOpenContextMenu}>
        <ContextMenuItem icon="edit" title={action} shortcut="E" on:click={() => handleClick(selectedTaskId, 'edit')}/>
        <ContextMenuItem icon="new_task" title="Subtask erstellen" shortcut="C"
                         on:click={() => handleClick(selectedTaskId, 'create')}/>
        <hr class="option-menu line mb-3">
        <ContextMenuItem icon="delete_inactive" title="Löschen" on:click={() => handleClick(selectedTaskId, 'delete')}>
            <div slot="shortcut"></div>
        </ContextMenuItem>
    </ContextMenu>
{/if}