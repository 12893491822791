<script>
    import StatisticHeader from "./controllers/StatisticHeader.svelte";
    import ProjectYieldConfig from "./controllers/ProjectYieldConfig.svelte";
    import {user} from "./stores/user.js";
    import {calendarWeek} from "./util/date-time";
    import {selectedDate} from "./stores/selectedDate";
    import {storeProjectId} from "./stores/statistic";

    export let userId;
    export let tab;

    user.set({id: userId,});

    export let date = "";
    selectedDate.set(new Date(date))
    export let week
    calendarWeek.set(week)

    storeProjectId.set(null)

    const webpages = [
        {name: "Abrechenbarkeit Projekte", component: ProjectYieldConfig},
    ];

</script>

<StatisticHeader {tab} {webpages}>
    <div slot="statistic-month-shift"></div>
</StatisticHeader>

