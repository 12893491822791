<script>
    import ProjectStatisticTab from "./ProjectStatisticTab.svelte";
    import { clearData } from "../stores/statistic.js";
    import OneProjectStatisticTab from "./OneProjectStatisticTab.svelte";
    import { storeProjectId } from "../stores/statistic.js";
    import {user} from "../stores/user.js";
    import {onMount} from "svelte";
    import DivisionStatistic from "./DivisionStatistic.svelte";


    export let data = [];
    export let selectedPage;
    let columns = [];
    let rows = [];
    let currentSortColumn = null;
    let sortOrder = 'asc';
    let currentPage = null;
    export let showSingleProject = false;
    let projectId = $storeProjectId;
    export let projectName;
    
    function sortColumn(column) {
        sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

        if (column === 'Abrechenbare Zeit' || column === 'Gesamtarbeitszeit' || column === 'Arbeitszeit für Kundenprojekte' || column === 'Berechenbare Zeit') {
            rows = sortTimeColumns(rows, column)
        } else {
            rows = sortTextColumns(rows, column)
        }
    }

    function sortTextColumns(rows, column) {
        return rows.sort((a, b) => {
            if (data[a][column] < data[b][column]) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (data[a][column] > data[b][column]) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    function sortTimeColumns(rows, column) {
        return rows.sort((a, b) => {
            const timeA = data[a][column].split(' ');
            const timeB = data[b][column].split(' ');

            const hoursA = parseInt(timeA[0], 10);
            const hoursB = parseInt(timeB[0], 10);
            const minutesA = parseInt(timeA[1], 10) || 0;
            const minutesB = parseInt(timeB[1], 10) || 0;

            if (hoursA < hoursB) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (hoursA > hoursB) {
                return sortOrder === 'asc' ? 1 : -1;
            }

            if (minutesA < minutesB) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (minutesA > minutesB) {
                return sortOrder === 'asc' ? 1 : -1;
            }

            return 0;
        });
    }

    function handleClick(rowIndex) {
        const project = data[rowIndex];
        projectId = project?.id;
        if (projectId) {
            clearData();
            removeGraph()
            storeProjectId.set(projectId);
            currentPage = OneProjectStatisticTab;
        } else {
            console.error("Project ID not found");
        }
    }

    function redirect() {
        showSingleProject = false;
        $user.id;
        projectName = '';
        projectId = null;
        removeGraph()
        currentPage = ProjectStatisticTab;
        storeProjectId.set(null);
    }

    $: {
        if (data && Object.keys(data).length > 0 && !currentSortColumn) {
            columns = Object.keys(data[Object.keys(data)[0]]).filter(column => column !== 'id');
            rows = Object.keys(data);
        }
    }

    function removeGraph() {
        document.getElementById('graph-element').remove()
    }

</script>

{#if showSingleProject && projectName}
    <div class="projectName" id="projectName">
        <p class="font-name">{projectName}</p>
        <button class="button-effect-2 mt-5 ml-3 redirect-button font-regular font-size-medium" on:click={redirect}>Zurück zur Übersicht</button>
    </div>
{/if}

{#if currentPage}
    <svelte:component this={currentPage} {projectId}/>
{:else}
    <div class="table">
        <div class="main-statistic-container">
            <div class="row header">
                {#each columns as column}
                    <button class="cell title-header font-regular font-size-medium button-effect-2" on:click={() => sortColumn(column)}>
                        {column}
                    </button>
                {/each}
            </div>
            {#each rows as row}
                <div class="row">
                    {#each columns as column}
                        {#if currentPage || selectedPage}
                            <button class="cell font-regular font-size-default" on:click={() => handleClick(row)}>{data[row][column]}</button>
                        {:else}
                            <div class="cell">{data[row][column]}</div>
                        {/if}
                    {/each}
                </div>
            {/each}
        </div>
    </div>
{/if}

<style>
    .main-statistic-container {
        --bs-table-bg: var(--primary-color-2) !important;
    }

    .table {
        display: flex;
        flex-direction: column;
        width: 95%;
        position: relative;
        left: 2vw;
        top: 2vw;
    }

    .row {
        display: flex;
        flex-direction: row;
    }

    .header {
        background-color: var(--primary-color-1);
        font-weight: bold;
    }

    .cell {
        flex: 1;
        text-align: left;
        border: 0;
        padding: 8px;
        color: var(--primary-color-5);
    }

    .header .cell {
        background-color: var(--primary-color-1);
        border-top: 0.1rem solid var(--primary-color-3);
    }

    .title-header {
        background-color: var(--primary-color-1);
        cursor: pointer;
        font-family: "Poppins Bold";
        color: var(--primary-color-5);
    }

    .projectName {
        position: relative;
        font-weight: bold;
        font-size: 23px;
        display: flex;
        justify-content: space-between;
    }

    .font-name {
        left: 1.5rem;
        position: relative;
    }

    .redirect-button {
        background-color: var(--primary-color-2);
        border: 0;
        text-align: center;
        border-radius: 0.2rem;
        color: var(--primary-color-5);
        right: 1.5rem;
        position: relative;
        width: 15rem;
        height: 2rem;
    }
</style>
