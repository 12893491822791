import {writable} from "svelte/store";
import {user} from "./user";
import {apiUrl} from "./environment";


export const divisions = writable({});

export let userId = "";
user.subscribe((data) => {
    userId = data?.id
})

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})

export async function fetchDivisions() {
    try {
        const response = await fetch(`${baseUrl}/api/divisions?page=1&User=${userId}`);
        const data = await response.json();
        divisions.set(data['hydra:member']);
    }catch(error) {
        throw new Error(error);
    }
}