<script>

    import {user} from "./stores/user";
    import IssuePage from "./controllers/IssuePage.svelte";

    export let userId = "";
    export let userBillable = "";

    user.set({id: userId,
        billable: userBillable
    })

</script>

<IssuePage/>