import {writable} from 'svelte/store';
import {user} from "./user";
import {selectedDate} from "./selectedDate";
import {validateCreateTask, validateUpdateTask} from "../util/InputValidation";
import {formatTimeInTimeZone, needsUpdate, taskAdded} from "../util/date-time";
import {apiUrl} from "./environment";
import {fetchSubTasksByWorklog} from "./subTasks.js";

export let worklogs = writable([]);
export let openWorklogs = writable([]);
export let unsubmittedWorklogs = writable([]);

export let weeklyWorklogs = writable([]);
export let weeklyReviews = writable([]);
export let rejectedWorklogs = writable([]);
export let subtasks = writable([]);
export let weeklySubtasks = writable([]);

let userId;
user.subscribe((data) => {
    userId = data?.id
})

let worklog = worklogs.subscribe((data) => {
    worklog = data
})

let rejectedWorklog = rejectedWorklogs.subscribe((data) => {
    rejectedWorklog = data
})

let date;
selectedDate.subscribe((newDate) => date = newDate)

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})

export async function fetchRejectedWorklogs() {

    const url = `${baseUrl}/api/worklogs?page=1&user=${userId}&reviewStatus=failure`

    const response = await fetch(url);
    const responseData = await response.json();


    rejectedWorklogs.set(responseData['hydra:member']);

    return responseData;
}

export async function fetchOpenWorklogs() {
    const url = `${baseUrl}/api/worklogs?page=1&reviewStatus=open`

    const response = await fetch(url);
    const responseData = await response.json();

    const sortedWorklogs = sortWorklogs(responseData['hydra:member'])
    openWorklogs.set(sortedWorklogs);

    return responseData;
}

export async function fetchPrivateWorklogs(date) {
    const currentDate = new Date(date)
    currentDate.setDate(currentDate.getDate() - 2)
    currentDate.setHours(0,0,0,0)

    const url = `${baseUrl}/api/worklogs?page=1&reviewStatus%5B%5D=failure&reviewStatus%5B%5D=private&startTime[before]=${currentDate.toISOString()}`;

    const response = await fetch(url);
    const responseData = await response.json();
    const sortedWorklog = sortWorklogs(responseData['hydra:member'])

    worklogs.set(sortedWorklog);

    return responseData;
}

export async function fetchWorklogs(selectedDate) {
    if (!selectedDate)
        selectedDate = date;

    const currentDate = new Date(selectedDate);
    const afterDate = new Date(selectedDate);

    afterDate.setDate(afterDate.getDate() + 1);


    const url = `${baseUrl}/api/worklogs?page=1&user=${userId}&startTime[before]=${afterDate.toISOString()}&startTime[after]=${currentDate.toISOString()}`;

    const response = await fetch(url);
    const responseData = await response.json();
    const sortedWorklog = sortWorklogs(responseData['hydra:member'])

    worklogs.set(sortedWorklog);

    return responseData;
}

export async function fetchWorklogsWithoutUser(selectedDate) {
    if (!selectedDate)
        selectedDate = date;

    const currentDate = new Date(selectedDate);
    const afterDate = new Date(selectedDate);

    afterDate.setDate(afterDate.getDate() + 1);


    const url = `${baseUrl}/api/worklogs?page=1&startTime[before]=${afterDate.toISOString()}&startTime[after]=${currentDate.toISOString()}`;

    const response = await fetch(url);
    const responseData = await response.json();
    const sortedWorklog = sortWorklogs(responseData['hydra:member'])

    worklogs.set(sortedWorklog);

    return responseData;
}

export async function fetchWorklogsByUser(selectedDate, userId) {
    const currentDate = new Date(selectedDate)
    const afterDate = new Date(selectedDate)

    afterDate.setDate(afterDate.getDate() + 1)

    const url = `${baseUrl}/api/worklogs?page=1&user=${userId}&startTime[before]=${afterDate.toISOString()}&startTime[after]=${currentDate.toISOString()}`

    const response = await fetch(url);
    const responseData = await response.json();
    const sortedWorklog = sortWorklogs(responseData['hydra:member']);

    worklogs.set(sortedWorklog);

    return responseData
}

function sortWorklogs(worklogs) {
    return worklogs.sort((a, b) => {
        return new Date(a.startTime) - new Date(b.startTime)
    })
}

export async function fetchWeeklyWorklogs(startDate, selectedUser) {
    const currentDate = new Date(startDate);
    const afterDate = new Date(startDate);
    afterDate.setDate(afterDate.getDate() + 5);

    let url;
    if (selectedUser) {
        url = `${baseUrl}/api/worklogs?page=1&user=${selectedUser.id}&startTime[before]=${afterDate.toISOString()}&startTime[after]=${currentDate.toISOString()}`
    } else {
        url = `${baseUrl}/api/worklogs?page=1&user=${userId}&startTime[before]=${afterDate.toISOString()}&startTime[after]=${currentDate.toISOString()}`;
    }


    const response = await fetch(url);
    const responseData = await response.json();
    weeklyWorklogs.set(responseData['hydra:member']);

    return responseData;
}

export async function fetchReviewWorklogs(selectedDate) {
    if (!selectedDate)
        selectedDate = date;

    const currentDate = new Date(selectedDate);
    const afterDate = new Date(selectedDate);

    afterDate.setDate(afterDate.getDate() + 1);


    const url = `${baseUrl}/api/worklogs?page=1&startTime[before]=${afterDate.toISOString()}&startTime[after]=${currentDate.toISOString()}&reviewStatus=open`;

    const response = await fetch(url);
    const responseData = await response.json();
    worklogs.set(responseData['hydra:member']);

    return responseData;
}

export async function fetchWeeklyReviewWorklogs(startDate) {
    const currentDate = new Date(startDate)
    const afterDate = new Date(startDate)
    afterDate.setDate(afterDate.getDate() + 5)

    const url = `${baseUrl}/api/worklogs?page=1&user=${userId}&startTime[before]=${afterDate.toISOString()}&startTime[after]=${currentDate.toISOString()}`;

    const response = await fetch(url);
    const responseData = await response.json();
    weeklyReviews.set(responseData['hydra:member']);

    return responseData;
}

export async function create(worklog, dateParam, selectedDate = date) {

    try {
        validateCreateTask(worklog)
        setTime(worklog, worklog.startTime, dateParam)

        const response = await fetch(`${baseUrl}/api/worklogs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(worklog),
        });

        if (response.ok) {
            await fetchWorklogs(selectedDate);
        } else {
            const errorData = await response.json();
            throw errorData.error;
        }
    } catch (error) {
        throw error;
    }
}

export async function update(worklog, id, dateParam, reviews = [], selectedDate = date) {
    try {
        validateUpdateTask(worklog)
        if (dateParam) {
            setTime(worklog, worklog.startTime, dateParam)
        }

        const response = await fetch(`${baseUrl}/api/worklogs/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/merge-patch+json',
            },
            body: JSON.stringify(worklog),
        });

        if (response.ok) {
            await fetchWorklogs(selectedDate);
        } else {
            const errorData = await response.json();
            throw errorData.error;
        }
    } catch (error) {
        throw error;
    }
}

export function getWorklogById(worklogId) {
    if (!worklog.length) {
        return rejectedWorklog.find(worklog => worklog['@id'] === worklogId)
    }
    return worklog.find(worklog => worklog['@id'] === worklogId);
}

function setTime(worklog, time, dateParam) {
    if (time.includes(dateParam)) {
        time = formatTimeInTimeZone(worklog.startTime)
    }

    worklog.startTime = `${dateParam}T${time}:00.000Z`;

    const startDateTime = new Date(worklog.startTime);

    if (!worklog.endTime) {
        const endDateTime = new Date(startDateTime.getTime() + (8 * 60 + 30) * 60 * 1000);
        worklog.endTime = endDateTime.toISOString();
    }

    needsUpdate.set(true)
}

export async function getTasks(worklogs, weekday) {
    let subtaskArray = []
    if (!worklogs) {
        await fetchWorklogs()
    }
    for (const task of worklogs) {
        let subtask = await fetchSubTasksByWorklog(task.id)
        subtaskArray.push(...subtask)
    }

    if (weekday) {
        weeklySubtasks.set(subtaskArray)
    } else {
        subtasks.set(subtaskArray)
    }
}
