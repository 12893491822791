<script>
    import Modal from '../util/Modal.svelte';
    import {onMount} from "svelte";
    import {reasons, fetchReasons} from "../stores/reasons";
    import {reviews, fetchReviews, denyReview} from "../stores/reviews";
    import {createEventDispatcher} from "svelte";

    let reviewId = null;
    let selectedValue = null;
    let comment = null;
    let reasonDropdown = false
    let selectedReasons = [];
    export let worklog = [];
    export let showModal;
    const dispatch = createEventDispatcher();
    let errorMessage

    onMount(() => {
        fetchReviews();
        fetchReasons();
    })

    function handleDenyClick(worklogId) {
        showModal = true;
        reviewId = $reviews
            .filter(review => review.status === 'open')
            .find(review => review.worklog === worklogId)["@id"];

    }


    function addValue(option) {
        selectedReasons = [...selectedReasons, option];
        reasonDropdown = !reasonDropdown
    }

    async function sendDenyRequest() {
        if (!comment) {
            return errorMessage = "Bitte eine Beschreibung des Problems angeben"
        }
        await denyReview(selectedReasons, comment, reviewId)
        await closeModal()
    }

    function deleteReason(index) {
        selectedReasons = selectedReasons.filter((_, i) => i !== index);
    }

    async function closeModal() {
        dispatch('reviewDenied')
        selectedValue = "";
        selectedReasons = [];
        comment = ""
        showModal = false;
    }
</script>

<button class="review-reject-button button-effect-3" on:click={() => handleDenyClick(worklog['@id'])}>
    <svg class="decline-icon">
        <use xlink:href="/images/icons/reject.svg#Ebene_1">
        </use>
    </svg>
</button>
{#if showModal}
    <Modal bind:showModal on:close={closeModal}>
        <div class="reject-modal-div">
            <h2 class="worklog-reject-header font-bold">
                Worklog ablehnen
            </h2>
            <p class="review-reject-subtitle font-size-default"> Bitte notwendige Informationen ausfüllen</p>
        </div>
        {#if errorMessage}
            <div class="modal-error-message mb-2 rounded-border-1 font-regular font-size-default" data-error={errorMessage}>
                <svg class="modal-error-message-icon mr-1">
                    <use xlink:href="/images/icons/info.svg#Ebene_1"/>
                </svg>
                {errorMessage}
            </div>
        {/if}
        <div class="modal-input-fields">
            <input type="text" id="reviewId" bind:value={reviewId} hidden="hidden"/>
            <div class="input-field-issue-title">
                <div>
                    <label for="reason" class="issue-title-label font-bold font-size-medium">Problem auswählen</label>
                </div>
                <div id="reason">
                    {#each selectedReasons as selectedReason, index}
                        <div class="reason-block mr-1 font-size-default">
                            <div> {selectedReason.title} </div>
                            <div style="padding-left: 0.5rem">
                                <button on:click={() => deleteReason(index)} class="reason-delete-button">
                                    <svg class="delete-reason-icon">
                                        <use xlink:href="/images/icons/delete.svg#Ebene_1">
                                        </use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    {/each}
                </div>

                <button on:mousedown={() => reasonDropdown = !reasonDropdown} class="choose-reason-button font-bold button-effect-2">

                  <svg class="reason-add-icon">
                        <use xlink:href="/images/icons/add.svg#Ebene_1"></use>
                    </svg>
                </button>
                {#if reasonDropdown}
                    <div class="add-reason">
                        <ul class="reason-list-options mb-0 font-size-default">
                            {#each $reasons as option}
                                {#if !selectedReasons.includes(option)}
                                    <li on:mousedown={() => addValue(option)} class="reason-option">
                                        {option.title}
                                    </li>
                                {/if}
                            {/each}
                        </ul>
                    </div>
                {/if}
            </div>
            <div class="input-field-issue-description">
                <div>
                    <label for="issueDescription" class="issue-description-label font-bold font-size-medium">Beschreibung</label>
                </div>
                <div>
                    <textarea class="issue-description font-size-default" id="issueDescription" bind:value={comment} required></textarea>
                </div>
            </div>
        </div>
        <div class="modal-reject font-size-medium">
            <button class="modal-reject-button button-effect-2 rounded-border-1" on:click={sendDenyRequest}>Task
                inklusive Subtasks
                ablehnen
            </button>
            <button class="cancel-reject-worklog-button button-effect-1 rounded-border-1" on:click={closeModal}>
                Abbrechen
            </button>
        </div>
    </Modal>
{/if}

<style>
    .reject-modal-div {
        text-align: left
    }

    .review-reject-button {
        background-color: var(--primary-color-2);
        border: 0;
        height: 2rem;
        width: 2rem;
        border-radius: 0.2rem;
    }

    .review-reject-button:hover {
        background-color: var(--signal-red);
    }

    .add-reason {
        background-color: var(--primary-color-2);
        box-shadow: 0 3px 4px 1px #1B242C;
        position: fixed;
        border-radius: 0.2rem;
    }

    .reason-list-options{
        padding: 0;
    }

    .reason-option {
        list-style: none;
        cursor: pointer;
        padding: 0.5rem;
        color: var(--main-text-color);
    }

    .reason-option:hover {
        background-color: var(--accent-colors-blue);
    }

    .choose-reason-button {
        height: 2.5rem;
        width: 2.5rem;
        background-color: var(--primary-color-2);
        border: 0;
        text-align: center;
        border-radius: 0.2rem;
        color: var(--primary-color-5);
    }

    .reason-add-icon {
        width: 1rem;
        height: 1rem;
        fill: var(--primary-color-5);
    }

    .reason-block {
        display: flex;
        width: fit-content;
        padding: 0.25rem;
        margin-bottom: 0.5rem;
    }

    #reason {
        display: flex;
        flex-wrap: wrap;
    }

    .reason-block, .issue-description, .cancel-reject-worklog-button {
        color: var(--primary-color-5);
    }

    .modal-error-message-icon {
        position: unset;
    }
</style>

