<script>
    import Modal from "../util/Modal.svelte";
    import {create} from "../stores/archive";
    import {createEventDispatcher} from "svelte";


    export let showModal = false

    export let startDate
    export let endDate
    const dispatch = createEventDispatcher()

    async function archive() {
        let dateParams = {
            start: startDate,
            end: endDate
        }

        if (!startDate || !endDate) {
            return
        }

        try {
            await create(dateParams)
            handleModalClose()
            dispatch("archived")
        } catch (error) {
            errorMessage = error;
        }
    }

    function handleModalClose() {
        startDate = null
        endDate = null
        showModal = false
    }

</script>

<slot name="button-slot">
    <button class="archive-page-buttons button-effect-2 font-regular font-size-medium" on:click={() => showModal = true}>Archivieren</button>
</slot>

{#if showModal}
    <Modal bind:showModal>
        <div class="archive-modal">
            <slot name="archive-modal-headline">
                <p class="archive-modal-headline-text font-semi-bold font-size-large">Worklogs archivieren für den Zeitraum</p>
            </slot>
            <div class="archive-modal-input font-size-default">
                <label class="archive-modal-label font-regular" for="start-date">von</label>
                <input bind:value={startDate} type="date" id="start-date" placeholder="12-12-2023"
                       class="archive-modal-date-picker rounded-border-1">
                <label class="archive-modal-label font-regular" for="start-date">bis</label>
                <input bind:value={endDate} type="date" id="end-date" placeholder="12-12-2023"
                       class="archive-modal-date-picker rounded-border-1">
            </div>
            <slot name="modal-button">
                <button class="archive-modal-button button-effect-2 font-medium font-size-medium" on:click={archive}>Archivieren</button>
            </slot>
        </div>
    </Modal>
{/if}