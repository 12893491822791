import {writable} from "svelte/store";
import {apiUrl} from "./environment";

export const notifications = writable({});

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})

export async function fetchNotifications() {
    try {
        const response = await fetch(`${baseUrl}/api/notifications`);
        const data = await response.json();
        notifications.set(data['hydra:member']);
    } catch(error) {
        throw new Error(error);
    }
}

export async function sendNotification(requestData, message) {
    try {
        const response = await fetch(`/send-notification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: requestData, customMessage: message }),
        });

        if (response.ok) {
            await fetchNotifications();
        } else {
            const errorData = await response.json();
            throw errorData.error;
        }
    } catch (error) {
        throw error;
    }
}