import {writable} from "svelte/store";
import {apiUrl} from "./environment";

export const colors = writable({});

let baseUrl = apiUrl.subscribe((url) => {
    baseUrl = url
})

export async function fetchColors() {
    try {
        const response = await fetch(`${baseUrl}/api/colors`);
        const data = await response.json();
        colors.set(data['hydra:member']);
    } catch(error) {
        throw new Error(error);
    }
}