export function onKeyUp(event) {
    const openedExportModal = document.querySelector('.export-content');
    const openedCalendar = document.querySelector('.calendar-navigation-container');
    const openedReviewModal = document.querySelector('.review-modal-content');
    const openedSubtaskModal = document.querySelector('.subtaskmodal-div');
    const openedTaskModal = document.querySelector('.worklog-modal-div');
    const openedDeleteModal = document.querySelector('.delete-modal-div');
    const openedCreateModal = document.querySelector('.create-modal-div');
    const openedRejectMddal = document.querySelector('.reject-modal-div')
    const hoverElement = document.querySelector('.worklog-main-table tr:hover');

    if (openedExportModal || openedCalendar || openedReviewModal || openedSubtaskModal || openedTaskModal || openedDeleteModal || openedCreateModal || openedRejectMddal) {
        return;
    }

    switch (event.keyCode) {
        case 67: // key c
            if (hoverElement && !hoverElement.querySelector('.worklog-table-head-data')) {
                hoverElement.querySelector('#add-subtask').click();
            } else {
                document.querySelector('#add-task').click()
            }
            break;
        case (event.shiftKey && 80): // shift + p
            if(hoverElement) {
                hoverElement.querySelector('#add-break').click();
            }
            break
        case 69: // key e
            if (hoverElement && !hoverElement.querySelector('.worklog-table-head-data')) {
                hoverElement.querySelector('#edit-modal').click();
            }
    }
}

export function handleClick(selectedTask, action) {
    let selectedElement = document.querySelector(`[task='${selectedTask}']`);

    switch (action) {
        case 'create':
            selectedElement.querySelector('#add-subtask').click();
            break;
        case 'edit':
            selectedElement.querySelector('#edit-modal').click();
            break;
        case 'delete':
            selectedElement.querySelector('#delete-task').click();
            break;

    }
}


export function trapFocus(event) {
    const modalContent = document.getElementById('modal-div');
    const focusableElements = modalContent.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    if (event.shiftKey && event.key === 'Tab') {
        const focusableElementsArray = Array.from(focusableElements);
        const activeIndex = focusableElementsArray.indexOf(document.activeElement);

        if (activeIndex > 0) {
            event.preventDefault();
            focusableElements[activeIndex - 1].focus()
        } else {
            lastFocusableElement.focus()
        }
    }

    if (!event.shiftKey && document.activeElement === lastFocusableElement && event.key !== 'Enter') {
        event.preventDefault();
        firstFocusableElement.focus();
    } else if (event.metaKey && event.key === 'Enter') {
        document.getElementById('modal-button').click()
    }
}
