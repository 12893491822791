<script>
    import StatisticTable from "./StatisticTable.svelte";
    import {onDestroy, onMount} from "svelte";
    import {fetchData, data, clearData} from "../stores/statistic.js";
    import {user} from "../stores/user.js";
    import Graph from "../util/Graph.svelte";

    let mainData = []
    let datasets = []
    let dataNodes = []

    onMount(async () => {
        await fetchData('overview', $user.id)
        mainData = $data.mainData
        dataNodes = $data.graphData
        datasets = $data.graphData.datasets
    })

    onDestroy(async () => {
        await clearData()

    })


</script>
<StatisticTable data={mainData}/>
<Graph {dataNodes} {datasets} boolean={true}/>