<script>
    import {fetchWorklogs} from "../stores/worklogs.js";
    import DeleteWorklog from "./DeleteWorklog.svelte";
    import EditWorklogModal from "./EditWorklogModal.svelte";
    import SubtaskModal from "./SubtaskModal.svelte";

    let showModal;
    export let worklog;
    export let selectedUser

</script>

    <DeleteWorklog worklog={worklog} on:worklogDeleted={() => {fetchWorklogs();}}/>
    <EditWorklogModal {worklog} bind:showModal selectedUser={selectedUser}/>
    <SubtaskModal worklogId={worklog['@id']} selectedUser={selectedUser}/>