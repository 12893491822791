<script>
    import SubtaskModal from "./SubtaskModal.svelte";
    import {millisecondsToHours} from "../util/date-time.js";
    import TimeInput from "../util/TimeInput.svelte";

    export let subTask = []
    export let worklog;
    export let showModal = false;
    let formattedTime

    function handleOpenModal() {
        showModal = true;
        formattedTime = millisecondsToHours(subTask.durationMills)
    }

</script>

<style>
    .worklog-subtask-edit-button {
        background-color: var(--primary-color-3);
        padding: 0.5rem;
    }

    .worklog-subtask-edit-button {
        background-color: var(--primary-color-2);
        border: 0rem;
        height: 2rem;
        width: 2rem;
        border-radius: 0.2rem;
        margin-right: 2rem;
    }

    .worklog-subtask-edit-button:hover {
        background-color: var(--accent-colors-blue);
    }

    .worklog-subtask-edit-icon {
        height: 1rem;
        width: 1rem;
        fill: var(--primary-color-5);
    }
</style>

<SubtaskModal {subTask} {worklog} {formattedTime} bind:showModal reviewPage>
    <div slot="subtask-button">
        <button class="worklog-subtask-edit-button" id="edit-modal" on:click={handleOpenModal} hidden="hidden">
            <svg class="worklog-subtask-edit-icon">
                <use xlink:href="/images/icons/edit.svg#Ebene_1">
                </use>
            </svg>
        </button>
    </div>
    <div slot="subtask-modal-header">
        <h1>Subtask bearbeiten</h1>
    </div>
    <div slot="subtask-duration-slot">
        <TimeInput bind:time={formattedTime} inputMode="duration" reviewPage/>
    </div>
</SubtaskModal>
