<script>
    import {Line, Bar} from 'svelte-chartjs';
    import {
        CategoryScale,
        Chart as ChartJS,
        Legend,
        LinearScale,
        LineElement,
        BarElement,
        BarController,
        PointElement,
        Title,
        Tooltip,
    } from 'chart.js';

    ChartJS.register(
        Title,
        Tooltip,
        Legend,
        LineElement,
        BarElement,
        BarController,
        LinearScale,
        PointElement,
        CategoryScale
    );

    export let dataNodes = {}
    export let datasets = []
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    }
    export let boolean = false

    function handleChange(event) {
        const selectedValue = event.target.value;
        const [data, newLabel] = selectedValue.split('|')

        dataNodes.datasets[0].data = data.split(',')
        dataNodes.datasets[0].label = newLabel
    }
</script>

<style>
    .chart-selector {
        background: var(--primary-color-2);
        border: none;
        height: 4vh;
        width: fit-content;
        text-align: center;
    }

    .graph {
        width: 75vw;
        height: 55vh;
    }
</style>

<div id="graph-element" class="graph-element">
    <div class="statistic-graph-header">
    {#if datasets.length}
        <select class="chart-selector m-3" id="chart-selector" on:change={handleChange}>
            {#each datasets as dataset}
                <option value={`${dataset.data}|${dataset.label}`}>{dataset.label}</option>
            {/each}
        </select>
    {/if}
    </div>
    {#if boolean}
        <div class="graph ml-5" id="bar-graph">
            <Bar data={dataNodes} options={options}/>
        </div>
    {:else}
        <div class="graph ml-5" id="line-graph">
            <Line data={dataNodes} options={options}/>
        </div>
    {/if}
</div>