export function checkForInternalProject(projects, costCenter) {
    return projects.find(project => project['@id'] === costCenter.projectName)
}

export function disableInputFields(task, project, costCenter) {
    if (project) {
        task.project = project['@id']
        task.title = costCenter.name
        task.description = null
        task.tickets = []
        task.coWorkers = []
        task.missingTicketNr = true
        return true
    } else {
        return false
    }
}