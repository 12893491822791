<script>
    import { onMount, onDestroy } from "svelte";
    import StatisticTable from "./StatisticTable.svelte";
    import { fetchData, data, clearData, storeProjectId } from "../stores/statistic.js";
    import { fetchSingleProject, storeSingleProject} from "../stores/projects.js";
    import { user } from "../stores/user.js";
    import Graph from "../util/Graph.svelte";

    storeSingleProject.set('');
    let mainData = []
    let datasets = []
    let dataNodes = []

    onMount(async () => {
        await fetchData('project', $user.id, $storeProjectId);
        await fetchSingleProject($storeProjectId);
        mainData = $data.mainData
        dataNodes = $data.graphData
        datasets = $data.graphData.datasets
    });

    onDestroy(async () => {
        await clearData();
    });

</script>

<StatisticTable data={mainData} projectName={$storeSingleProject?.name} showSingleProject={true}/>
<Graph {dataNodes} {datasets} boolean={true}/>
