<script>
    import {onDestroy, onMount} from "svelte";
    import {clearData, data, fetchData} from "../stores/statistic.js";
    import Graph from "../util/Graph.svelte";
    import {selectedDate} from "../stores/selectedDate";

    let updating = false

    let dataNodes = {}
    let datasets = []


    onMount(async () => {
        await fetchData('graph')
        datasets = $data.datasets
        dataNodes = $data
    })

    onDestroy(async () => {
        await clearData()
    })

    function handleChange(event) {
        const selectedValue = event.target.value;
        const [data, newLabel] = selectedValue.split('|')

        dataNodes.datasets[0].data = data.split(',')
        dataNodes.datasets[0].label = newLabel
    }

    async function updateStatistic() {
        try {
            updating = true
            const date = $selectedDate.toLocaleDateString('us-US');

            const [month, year] = date.split('.')

            const url = new URL('/update-statistic', window.location.origin);

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(date)
            });

            if (response.ok) {
                await fetchData('graph')
                datasets = $data.datasets
                dataNodes = $data
                updating = false
            } else {
                const errorData = await response.json();
                throw errorData.error;
            }
        } catch (error) {
            throw error;
        }
    }
</script>

<style>
    .chart-selector {
        background: var(--primary-color-2);
        border: none;
        height: 4vh;
        width: fit-content;
        text-align: center;
    }

    .graph {
        width: 75vw;
        height: 55vh;
    }

    #loader {
        z-index: 1;
        width: 1.5rem;
        height: 1.5rem;
        border: 0.3rem solid var(--primary-color-4);
        border-radius: 50%;
        border-top: 0.3rem solid var(--accent-colors-orange);
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .statistic-graph-header {
        display: flex;
        align-items: center;
    }

    .update-statistic-button {
        border: none;
        background-color: var(--accent-colors-blue);
        height: 2.5rem;
    }
</style>

<div class="statistic-graph-header">
    <button class="update-statistic-button mr-2 ml-3 rounded-border-1 button-effect-2 font-regular font-size-default" on:click={updateStatistic} disabled={updating}>Daten aktualisieren</button>
    {#if updating}
        <div id="loader"></div>
    {/if}
</div>
<Graph {dataNodes} {datasets}/>