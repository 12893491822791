<script>
    import Modal from "../util/Modal.svelte";
    import {users} from "../stores/users.js";
    import {createReviewRequest} from "../stores/reviews.js";
    import {openWorklogs, weeklyWorklogs, worklogs} from "../stores/worklogs.js";
    import {selectedDate} from "../stores/selectedDate.js";
    import {getWeekdays, getWeeklyWorklogs, highlightReviewIcon} from "../util/highlightReviewStatus.js";
    import {fetchAllRoles, roles} from "../stores/roles.js";
    import {user} from "../stores/user";
    import {colors} from "../stores/colors";


    let selectedUser = null;
    let worklogId = "";
    let filteredUsers = [];
    const options = {weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit'};
    const current = new Date($selectedDate)


    export let showModal = false;

    function selectUser(userId) {
        if (selectedUser === userId) {
            selectedUser = null;
        } else {
            selectedUser = userId;
        }
    }

    function findColors(id) {
        let cId = $colors.find(color => color['@id'] === id);
        return cId.code;
    }


    $: {
        if (showModal && $colors.length && $users.length) {
            filterReviewerUsers()
        }
    }

    async function createReview() {
        if (selectedUser === null || worklogId === null) {
            return
        }
       try {
           const userString = `/api/users/${$user.id}`
           await createReviewRequest($worklogs, selectedUser, userString);
            showModal = false
            await handleReviewCreated()
        } catch (e) {
            throw new Error(e);
        }
    }

    async function handleReviewCreated() {
        const weekdays = await getWeekdays(current, options, $roles)

        await getWeeklyWorklogs(weekdays)
        await highlightReviewIcon($weeklyWorklogs, weekdays)
    }

    async function filterReviewerUsers() {
        if (!$users.length) return
        await fetchAllRoles()
        filteredUsers = $users.filter(u => $roles.some(role => role.users.includes(u['@id']) && role.code === "ROLE_REVIEWER") && u.id !== $user.id)
    }

    async function filterWorklogs() {
        $weeklyWorklogs = $weeklyWorklogs.filter()
    }

</script>

<style>

    ul#userList {
        list-style-type: none;
        padding: 0;
        max-height: 25rem;
        overflow-y: auto;
        overflow-x: hidden;
    }

    ul#userList li {
        width: 30rem;
        padding: 10px;
        border-radius: 30rem;
        cursor: pointer;
    }

    ul#userList li:hover {
        background-color: var(--primary-color-3);
    }

    .review-freigeben-icon {
        height: 1rem;
        width: 1rem;
    }

    .review-information-icon {
        fill: var(--accent-colors-blue);
    }

    .review-freigeben-icon {
        fill: var(--primary-color-5);
    }

    .review-information-icon {
        width: 3rem;
        height: 1rem;
    }

    .review-information {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: var(--primary-color-2);
    }

    .review-freigeben-modal {
        text-align: center;
    }

    .review-freigeben-modal-button {
        border: 0;
        height: 2.25rem;
        width: 14rem;
        background: var(--accent-colors-blue);
    }

    .modal-header {
        font-size: 1.5rem;
        letter-spacing: 0;
        color: var(--primary-color-5);
    }

    .modal-sub-title {
        color: var(--primary-color-4)
    }

    .modal-information-text {
    }

    ul#userList::-webkit-scrollbar {
        background-color: var(--primary-color-2);
        border-radius: 0.2rem;
        width: 0.5rem;
    }

    ul#userList::-webkit-scrollbar-thumb {
        background-color: var(--primary-color-4);
        border-radius: 1rem;
    }

    .reviewer-list {
        display: flex;
        justify-content: space-between;
        background-color: var(--primary-color-2) !important;
        margin-top: 0.5rem;
        color: var(--main-text-color);
    }

    .reviewer-list:hover {
        background-color: var(--accent-colors-blue) !important;
    }

    .review-button:hover {
        background-color: var(--accent-colors-blue);
    }

    .review-icon {
        fill: var(--primary-color-5);
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        align-items: center;
    }

    .release-for-review-info {
        color: var(--primary-color-5);
    }
</style>

<div class="worklog-review font-bold font-size-medium">
    <button class="review-button button-effect-2" on:click={() => showModal = true}>
        <svg class="review-icon">
            <use xlink:href="/images/icons/review_status.svg#Ebene_1">
            </use>
        </svg>
        Review-Freigabe
    </button>
</div>

{#if showModal }
    <Modal bind:showModal>
        <div class="review-modal-content">
            <div>
                <h1 class="modal-header font-semi-bold"> Review-Freigabe </h1>
            </div>
            <div>
                <p class="modal-sub-title font-regular font-size-default"> Wählen Sie einen Benutzer aus, der Ihre Worklogs reviewen
                    soll</p>
            </div>
            <div class="modal-user-list font-regular font-size-large">
                <ul id="userList">
                    {#each filteredUsers as user(user.id)}
                        <li data-userid={user["@id"]}
                            class:selected={selectedUser === user.id}
                            on:mousedown={() => selectUser(user["@id"])}
                            class="reviewer-list"
                            style="background-color: {selectedUser === user ['@id'] ? 'var(--accent-colors-blue) !important': 'var(--primary-color-dark-2)'};"
                        >
                            <div>
                                <svg class="user-list-dot" style="fill: {findColors(user.color)}">
                                    <use xlink:href="/images/icons/color_dot.svg#Ebene_1"></use>
                                </svg>
                                {user.name}
                            </div>
                        </li>
                    {/each}
                </ul>
            </div>
            <div class="modal-information-text font-regular font-size-default">
                <i class="review-information rounded-border-1">
                    <svg class="review-information-icon mr-1">
                        <use xlink:href="/images/icons/info.svg#Ebene_1"></use>
                    </svg>
                    <div class="release-for-review-info">
                        Durch das Freigeben zur Inaugenscheinnahme können die Arbeitsprotokolle später unter Umständen nicht mehr gelöscht
                        werden.
                    </div>
                </i>
            </div>
            <div class="review-freigeben-modal font-medium font-size-medium">
                <button class="review-freigeben-modal-button rounded-border-1 button-effect-2" on:click={createReview}>
                    <svg class="review-freigeben-icon">
                        <use xlink:href="/images/icons/accept.svg#Ebene_1"></use>
                    </svg>
                    Zum Reviewen freigeben
                </button>
            </div>
        </div>
    </Modal>
{/if}
