<script>
    import {fetchUsers, getUserById, users} from "../stores/users";

    import {onMount} from "svelte";
    import {user} from "../stores/user";

    export let coWorkers = [];
    export let disabled = false;


    function removeCoWorker(coWorker) {
        coWorkers = coWorkers.filter(worker => worker.id !== coWorker);
    }


    $:{
        if (coWorkers.length !== 0) {
            coWorkers = coWorkers.map((coWorker) => {
                if (typeof coWorker !== 'string') return coWorker
                return getUserById(coWorker);
            })

        }
    }

    function addCoWorker(event) {
        let inputText = event.target.value.trim().toUpperCase();
        if (inputText !== '') {
            let coWorker = $users.find(user => user.code.toUpperCase() === inputText); // Convert user.code to uppercase for comparison
            let addWorker = true;

            if (coWorker && (coWorkers.find(worker => worker['@id'] === coWorker['@id']) || coWorker.id === $user.id)) {
                addWorker = false;
            }

            if (coWorker && addWorker) {
                coWorkers = [...coWorkers, coWorker];
            }

            event.target.value = '';
        }
    }



    onMount(async () => {

        await fetchUsers()

        document.getElementById('coWorkers').addEventListener('keydown', function (event) {
            if (event.code === 'Backspace' && coWorkers.length > 0 && !event.target.value) {
                const coWorker = coWorkers.pop();
                removeCoWorker(coWorker.id);
            }

            if (event.code === 'Space') {
                addCoWorker(event)
            }
        })
    })

</script>


<div class="worklog-space m-3" id="coWorkers">
    <label class="{disabled? 'worklog-modal-label disabled':'worklog-modal-label'} font-regular font-size-small" for="coWorkers">Mitarbeiter</label>
    <div class="worklog-modal-half coworkers">
        <ul class="tag-list">
            {#each coWorkers as coWorker }
                <li class="user-tag font-regular font-size-small" id="${coWorker.code}">{coWorker.name}</li>
                <i class="fa-solid fa-xmark" id="delete" on:mousedown={() => removeCoWorker(coWorker.id)}></i>
            {/each}
            <input class="{disabled? 'cowokers-input disabled':'cowokers-input'} font-regular font-size-default" type="text" id="coWorkers" on:blur={addCoWorker}  {disabled}  />
        </ul>
    </div>
</div>

<style>
    .cowokers-input {
        height: 2rem;
        width: 100%;
        max-width: 12.9rem;
        box-sizing: border-box;
        background-color: unset;
        border: 0;
        outline: none;
        flex: 1;
    }

    .worklog-modal-half.coworkers:focus-within {
        outline: auto #428df5;
    }

    .worklog-modal-half.coworkers {
        display: flex;
        height: fit-content;
    }

    .tag-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        align-items: center;
    }

    .worklog-modal-half.coworkers i {
        color: var(--primary-color-4);
        margin-left: -1rem;
        padding-right: 0.3rem;
    }

    .user-tag {
        list-style: none;
        background-color: var(--primary-color-3);
        border-radius: 1rem;
        pointer-events: none;
        height: 1.5rem;
        display: flex;
        align-items: center;
        margin: 0 0 0 0.2rem;
        padding-inline: 0.5rem 1.3rem;
        color: var(--main-text-color);
    }
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .worklog-modal-label{
        color: var(--main-text-color)
    }
</style>