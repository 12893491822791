<script>
    import Modal from "../util/Modal.svelte";
    import {createEventDispatcher} from "svelte";

    export let showModal = false;
    export let task;
    export let mode;
    export let status;
    let statusSuccess = false;

    let deleteSubmit = false;

    const dispatch = createEventDispatcher()

    $: {
        if (status === 'success') {
            statusSuccess = true;
        }
    }

    function handleClick(value) {
        deleteSubmit = value;
        showModal = false;
        dispatch('submit', {deleteSubmit});
    }

</script>

{#if showModal}
    <Modal class="delete-modal" bind:showModal>
        <div class="delete-modal-div">
            <div class="delete-modal-header font-bold mb-4">
                {mode} löschen
            </div>

            <div class="delete-modal-info font-regular font-size-medium" hidden={statusSuccess}>
                Gelöschte {mode}s können nicht wiederhergestellt werden.<br>
            </div>

            <div class="delete-warning status-success rounded-border-2 font-regular font-size-medium mt-4 mb-2" hidden={!statusSuccess}>
                <svg class="delete-info-icon status-success mr-2">
                    <use xlink:href="/images/icons/info.svg#Ebene_1"/>
                </svg>
                Das Löschen von bereits angenommenen <br>
                {mode}s ist nicht möglich.
            </div>

            {#if mode === 'Task'}
                {#if task.subtask.length}
                    <div class="delete-warning subtasks rounded-border-2 font-regular font-size-medium mt-4 mb-2" hidden={statusSuccess}>
                        <svg class="delete-info-icon mr-1">
                            <use xlink:href="/images/icons/info.svg#Ebene_1"/>
                        </svg>
                        Zugehörige Subtasks werden ebenfalls unwiderruflich gelöscht.
                    </div>
                {/if}
            {/if}

            <div class="delete-modal-footer mt-4 font-size-medium">
                <div class="delete-footer-text mr-4 font-regular" hidden={statusSuccess}>
                    Möchten Sie fortfahren?
                </div>
                <button class="delete-modal-button cancel font-regular mr-2 button-effect-1" on:click={() => handleClick(false)}>Abbrechen</button>
                <button class="delete-modal-button submit font-regular button-effect-3"
                        hidden={statusSuccess}
                        on:click={() => handleClick(true)}>Löschen</button>
            </div>
        </div>
    </Modal>
{/if}

<style>
    .delete-modal-div {
        justify-content: center;
        font-size: 14px;
    }

    .delete-modal-div, .delete-modal-button {
        color: var(--primary-color-5);
    }

    .delete-modal-header {
        text-align: center;
        font-size: 23px;
    }

    .delete-modal-info {
        text-align: center;
    }

    .delete-modal-button {
        border: none;
        background-color: var(--primary-color-2);
        height: 2.5rem;
        width: 6.5rem;
        border-radius: 0.5rem;
    }

    .delete-modal-button.submit {
        background-color: var(--signal-light-red-2);
    }

    .delete-warning {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--signal-light-red);
        height: 3rem;
    }

    .delete-warning.status-success {
        height: 4rem;
        text-align: center;
    }

    .delete-info-icon {
        height: 1.2rem;
        width: 1.2rem;
        fill: var(--signal-red);
    }

    .delete-info-icon.status-success {
        height: 1.5rem;
        width: 1.5rem;
    }

    .delete-modal-footer {
        display: flex;
        float: right;
        align-items: center;
    }
</style>