<script>
    import Modal from "../util/Modal.svelte";
    import {createEventDispatcher} from "svelte";
    export let showModal = false;
    let createSubmit = false;

    const dispatch = createEventDispatcher();

    function handleClick(value) {
        createSubmit = value;
        showModal = false;

        dispatch('click', {createSubmit})
    }
</script>

{#if showModal}
    <Modal class="create-modal" bind:showModal>
        <div class="create-modal-div">
            <div class="create-modal-header font-bold mb-4">
                Task erstellen
            </div>
            <div class="create-modal-info font-regular font-size-medium">
                An diesem Tag existieren bereits akzeptierte Worklogs.<br>
                Der Reviewstatus dieser Worklogs wird beim Erstellen zurückgesetzt.
            </div>
            <div class="create-modal-footer mt-4 font-size-medium">
                <div class="footer-text mr-3 font-regular">
                    Möchten Sie fortfahren?
                </div>
                <button class="create-modal-button cancel mr-2 font-regular button-effect-1" on:click={() => handleClick(false)}>Abbrechen</button>
                <button class="create-modal-button submit font-regular button-effect-4" on:click={() => handleClick(true)}>Task erstellen</button>
            </div>
        </div>
    </Modal>
{/if}

<style>
    .create-modal-div {
        justify-content: center;
    }

    .create-modal-header {
        text-align: center;
        font-size: 23px;
    }

    .create-modal-info {
        text-align: center;
    }


    .create-modal-footer {
        display: flex;
        float: right;
        align-items: center;
    }

    .footer-text {
        font-size: 0.8rem;
    }

    .create-modal-button {
        border: none;
        border-radius: 0.5rem;
        height: 2rem;
        background-color: var(--primary-color-2);
    }

    .create-modal-button.submit {
        background-color: var(--signal-light-green-2);
    }
</style>