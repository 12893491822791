<script>
    import {createEventDispatcher} from "svelte";

    const dispatch = createEventDispatcher()

    export let icon
    export let title
    export let shortcut

    function handleClick() {
        dispatch("click")
    }
</script>

<div class="option-menu item mb-3">
    <svg class="option-menu icon {title} mr-1">
        <use xlink:href="/images/icons/{icon}.svg#Ebene_1"></use>
    </svg>
    <p class="option-menu title {title} font-regular font-size-medium" on:mousedown={handleClick}>{title}</p>
    <slot name="shortcut">
        <p class="option-menu shortcut font-extra-light font-size-default">{shortcut}</p>
    </slot>
</div>

<style>
    .option-menu.item p {
        color: var(--primary-color-5);
        display: inline-block;
        vertical-align: text-top;
        margin: 0;
    }

    .shortcut {
        float: right;
        color: var(--primary-color-4) !important;
    }

    .icon.Löschen {
        fill: var(--signal-red) !important;
    }

    p.Löschen {
        color: var(--signal-red) !important;
    }

    .icon {
        height: 1.2rem;
        width: 1.2rem;
        fill: var(--primary-color-5);
    }
</style>